<template>
  <v-card class="px-2 pt-1 my-4" style="height: 300px">
    <chart :option="OpenOCR" autoresize />
  </v-card>
</template>

<script>
export default {
  props: ["dataChart"],
  created() {
    this.OpenOCR = {
      // color: ["#445069", "#ff5252", "#536dfe", "#FFD95A"],

      dataset: [
        {
          dimensions: [
            "name",
            this.$t("reportpublic.TotalSchools"),
            this.$t("reportpublic.Workers"),
            this.$t("reportpublic.MaleStudents"),
            this.$t("reportpublic.FemaleStudents"),
            this.$t("reportpublic.Students"),
            "",
          ],
          source: [
            [
              this.dataChart.school_type,
              this.dataChart.summer_count,
              this.dataChart.workers_count,
              this.dataChart.males_student_count,
              this.dataChart.females_student_count,
              this.dataChart.student_count,
            ],
          ],
        },
        {
          transform: {
            type: "sort",
            config: { dimension: "constant", order: "desc" },
          },
        },
      ],
      xAxis: {
        type: "category",
        axisLabel: {
          fontSize: 15,
          fontWeight: "bold",
        },
      },
      yAxis: {
        type: "value",
        position: this.$i18n.locale == "ar" ? "right" : "",
      },
      series: [
        {
          type: "bar",
          itemStyle: { borderRadius: [4, 4, 0, 0] },
          label: {
            show: true,
            position: "top",
            textStyle: {
              fontSize: 10,
            },
          },
        },
        {
          type: "bar",
          itemStyle: { borderRadius: [4, 4, 0, 0] },
          label: {
            show: true,
            position: "top",
            textStyle: {
              fontSize: 10,
            },
          },
        },
        {
          type: "bar",
          itemStyle: { borderRadius: [4, 4, 0, 0] },
          label: {
            show: true,
            position: "top",
            textStyle: {
              fontSize: 10,
            },
          },
        },
        {
          type: "bar",
          itemStyle: { borderRadius: [4, 4, 0, 0] },
          label: {
            show: true,
            position: "top",
            textStyle: {
              fontSize: 10,
            },
          },
        },
        {
          type: "bar",
          itemStyle: { borderRadius: [4, 4, 0, 0] },
          label: {
            show: true,
            position: "top",
            textStyle: {
              fontSize: 10,
            },
          },
        },
      ],
      legend: {
        bottom: "bottom",
        top: "80%",
        left: "center",
        itemHeight: 10,
        itemWidth: 13,
      },

      toolbox: {
        feature: {
          saveAsImage: {
            title: this.$t("dashboard.save-as-png"),
          },
        },
      },
      tooltip: {
        trigger: "item",
        axisPointer: {
          type: "shadow",
        },
        formatter: function (params) {
          return (
            "<h5>" +
            params.name +
            "</h5>" +
            params.value[params.componentIndex + 1] +
            "&nbsp;&nbsp;&nbsp;" +
            params.marker +
            " " +
            params.seriesName
          );
        },
      },
      grid: {
        bottom: "50%",
        height: "60%",
        top: "10%",
      },
    };
  },
};
</script>
