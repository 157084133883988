<template>
  <!-- filter data  -->
  <v-card v-if="!exist">
    <v-card-text>
      <h3 class="text-grey-darken-2">{{ $t("globals.select-criteria") }}</h3>
      <v-row class="my-2 mt-6">
        <v-col cols="12" md="4" sm="8">
          <v-select
            multiple
            color="indigo"
            v-model="filter_data.fk_level"
            density="compact"
            item-title="level_with_phase_name"
            item-value="id"
            prepend-inner-icon="mdi-stairs-box"
            :items="levels"
            :label="$t('summer.level.name_ar')"
            hide-details
            clearable
            chips
            closable-chips
            :error-messages="
              v$.filter_data.commitee.$errors.map((e) => e.$message)
            "
          >
            <template v-slot:chip="{ props, item }">
              <v-chip
                class="mx-1"
                style="font-size: 0.9rem"
                v-bind="props"
                :text="item.raw.name"
              >
              </v-chip>
            </template>
          </v-select>
        </v-col>
        <v-col cols="2" md="2" sm="2" class="mt-2">
          <v-btn
            density="comfortable"
            append-icon="mdi-presentation"
            class="w-100 text-white"
            color="indigo"
            :loading="loading"
            @click="checkFilterData"
          >
            <span>{{ $t("globals.show") }}</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
  <v-card flat class="justify-center" id="lectures-report" v-if="exist">
    <ReportHeader class="pb-0">
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          <div class="text-h5">
            {{ $t("report.students-statistic-by-level") }}
          </div>
        </div>
      </template>
    </ReportHeader>

    <v-card-text class="mt-4">
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <div class="d-flex justify-end" style="flex: 1">
          <v-btn
            density="compact"
            class="pa-0 mx-1"
            style="min-width: 25px"
            @click="printTable"
          >
            <v-icon color="icon-color">mdi-printer</v-icon>
            <v-tooltip class="tooltip" activator="parent" location="top">
              <small>{{ $t("globals.print-file") }}</small>
            </v-tooltip>
          </v-btn>

          <v-btn
            density="compact"
            @click="(exist = false), v$.filter_data.$reset()"
          >
            <span class="text-indigo">{{ $t("globals.back") }}</span>
          </v-btn>
        </div>
      </div>

      <v-row
        v-if="items.length > 0"
        class="mt-4 mb-5 text-grey-darken-2 borders pt-2 pb-2 mr-1 ml-1"
      >
        <v-col cols="3">
          <h3 class="fontsize text-grey-darken-2 mb-2">
            {{ $t("school.committee_name") }} :
            {{ getCommitName(this.filter_data.commitee) }}
          </h3>
        </v-col>
      </v-row>

      <div id="myTable">
        <v-card v-if="items.length < 1" class="pa-2">
          <h3 class="text-center text-grey-darken-2">
            {{ $t("globals.not-found-items") }}
          </h3>
        </v-card>
        <v-card class="pt-2" :loading="loading" rounded="0" flat>
          <table
            id="stable"
            class="table"
            style="width: 100%; border-collapse: collapse"
          >
            <thead class="table-light">
              <tr>
                <th
                  v-if="$i18n.locale === 'ar'"
                  rowspan="2"
                  class="bg-blue-grey-lighten-4 pa-2"
                >
                  البيان
                </th>
                <th
                  v-if="$i18n.locale === 'en'"
                  rowspan="2"
                  class="bg-blue-grey-lighten-4 pa-2"
                >
                  info
                </th>
                <th
                  v-for="header in items.levels"
                  :colspan="header.count"
                  :key="header.key"
                  class="bg-blue-grey-lighten-4 pa-2"
                >
                  <span v-if="$i18n.locale === 'ar'">
                    {{ header.level }}
                  </span>
                  <span v-if="$i18n.locale === 'en'">
                    {{ header.level_en }}
                  </span>
                </th>
              </tr>
              <tr>
                <th
                  class="border bg-blue-grey-lighten-4 pa-1"
                  v-for="gender in items.registered"
                  :key="gender"
                >
                  <span v-if="$i18n.locale === 'ar'">
                    {{ gender.gender }}
                  </span>
                  <span v-if="$i18n.locale === 'en'">
                    <span v-if="gender.gender == 'ذكر'"> male </span>
                    <span v-else> female </span>
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td v-if="$i18n.locale === 'ar'">المسجلين</td>
                <td v-if="$i18n.locale === 'en'">registered</td>
                <td
                  v-for="item in items.registered"
                  :key="item"
                  class="text-center border pp2"
                >
                  {{ item.count }}
                </td>
              </tr>

              <tr>
                <td v-if="$i18n.locale === 'ar'">المتسربين</td>
                <td v-if="$i18n.locale === 'en'">walkout</td>

                <td
                  v-for="droups in items.walkout"
                  :key="droups"
                  class="text-center border pp2"
                >
                  {{ droups.count }}
                </td>
              </tr>

              <tr>
                <td v-if="$i18n.locale === 'ar'">المستمرين</td>
                <td v-if="$i18n.locale === 'en'">continue</td>

                <td
                  v-for="droups in items.continue"
                  :key="droups"
                  class="text-center border pp2"
                >
                  {{ droups.count }}
                </td>
              </tr>
            </tbody>
          </table>
        </v-card>
      </div>
    </v-card-text>
  </v-card>
  <Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
  >
  </Alert>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import ReportHeader from "@/components/SummerGlobals/ReportHeader.vue";
import useValidate from "@vuelidate/core";

import { required, helpers } from "@vuelidate/validators";
export default {
  components: { ReportHeader },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.$store.dispatch(
        "updatePermission",
        localStorage.getItem("userinfo")
      );

      await this.getCommittee();
      await this.getLevels();
      await this.getweeks();
      await this.getYears();

      this.current_year = this.getYearName(
        localStorage.getItem("current_year")
      );
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },

  data() {
    return {
      rules: {
        requird_filed: [
          (value) => !!value || this.$t("globals.required_field"),
        ],
      },
      theaders: [],

      current_year: "",
      fees_types: [],
      sub_list: [],
      drivers: [],
      parents: [],
      sub_list_sub: [],
      process_alert: false,
      icon_color: undefined,
      alert_title: undefined,
      alert_message: undefined,
      v$: useValidate(),
      exist: false,
      items: [],
      loading: false,
      txt_search: "",
      selectedHead: ["id", "name", "adjective", "entity", "phone_number"],
      headers: [
        { title: "#", key: "id" },
        { title: this.$t("globals.name"), key: "name" },
        { title: this.$t("globals.adjective"), key: "adjective" },
        { title: this.$t("summer.employee.place-of-work"), key: "entity" },
        { title: this.$t("globals.phone_number"), key: "phone_number" },
      ],

      perPage: 25,
      length: 0,
      totalItems: 0,
      itemsPerPage: [
        { value: 5, text: "5" },
        { value: 10, text: "10" },
        { value: 15, text: "15" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 75, text: "75" },
        { value: 100, text: "100" },
        { value: "", text: this.$t("globals.all") },
      ],
      page: 1,
      pagination: {},
      filter_data: {},
    };
  },
  computed: {
    ...mapState({
      // classes: (state) => state.school.classes,
      // class_assign_hall: (state) => state.school.class_assign_hall,
      // divisions: (state) => state.school.divisions,
      // semesters: (state) => state.school.semesters,
      // subjects: (state) => state.school.subjects,
      user: (state) => state.User,
      committee: (state) => state.summer.committee,
      levels: (state) => state.summer.levels,
      weeks: (state) => state.summer.weeks,
    }),

    ...mapGetters({
      // getClassDivisions: "school/getClassDivisions",
    }),

    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
  },
  methods: {
    ...mapActions({
      getCommittee: "summer/getCommittee",
      getLevels: "summer/getLevels",
      getweeks: "summer/getweeks",
    }),
    getYears() {
      this.year_data = localStorage.getItem("current_year_name_h");
      this.filter_data.year = localStorage.getItem("current_year");
      return this.year_data;
    },

    getCommitName(driver_id) {
      if (driver_id) {
        const committees = this.committee.find(
          (committees) => committees.id === committees.id
        );
        return committees.name;
      }
      return "===========";
    },
    checkFilterData() {
      this.v$.filter_data.$validate();
      if (!this.v$.filter_data.$error) {
        this.getData();
      }
    },
    async printTable() {
      window.print();
    },
    async getData() {
      this.loading = true;
      await this.axios
        .get(`${this.base_url}api/summer/report/students-statistics`, {
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            search: this.txt_search,

            level: this.filter_data.fk_level,
          },
        })
        .then((response) => {
          this.exist = true;
          if (response.data) {
            this.items = response.data;

            this.loading = false;
          }
        })
        .catch((error) => {
          this.icon = "cancel";
          this.icon_color = "error";
          if (error.message.includes("Network Error")) {
            this.alert_title = this.$t("globals.title");
            this.alert_message = this.$t("globals.connect");
          } else {
            this.alert_title = this.$t("alert.not-found.title");
            this.alert_message = this.$t("alert.not-found.message");
          }
          this.process_alert = true;
          setTimeout(() => {
            this.process_alert = false;
          }, 2000);
        });
    },
  },
  validations() {
    return {
      filter_data: {
        commitee: {
          // required: helpers.withMessage(this.$t("errors.required"), required),
        },
      },
    };
  },
  watch: {
    perPage() {
      this.getData();
    },
    page() {
      this.getData(this.page);
    },
  },
};
</script>
<style scoped>
#stable {
  width: 100%;
}

.pp2 {
  padding: 2px !important;
}
#stable th {
  border: 1px solid #649add !important;
  background-color: #fff;
}

#stable td {
  border: 1px solid #649add !important;
  background-color: #fff;
  padding: 8px;
}

.search:focus {
  outline: unset;
}
.fontsize {
  font-size: 14px;
}
.borders {
  border: solid #c1c1c1 1px;
  border-radius: 8px;
}
@media print {
  #sidebar,
  #header,
  #pagination-bar *,
  #tableHead,
  .tooltip,
  #print-menu {
    display: none !important;
  }
  #lectures-report {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    /* height: 100%; */
  }
}
</style>
