<template>
  <VForm ref="form" 
    v-if="
      CheckGetPermission('summer_center.add_lecturelog',['sys_admin','summer_admin','user']) ||
      CheckGetPermission('summer_center.change_lecturelog',['sys_admin','summer_admin','user'])
    " >
    <v-expansion-panels  >
      <v-expansion-panel   >
        <v-expansion-panel-title class="px-4">
          <span>{{$t('globals.select-criteria')}}</span>
        </v-expansion-panel-title>
        <v-expansion-panel-text >
          <VRow>
            <v-col cols="5" md="3" >
              <v-autocomplete
                color="indigo"
                v-model="lessonRecord.level"
                density="compact"
                item-title="level_with_phase_name"
                item-value="id"
                prepend-inner-icon="mdi-stairs-box"
                clearable
                :items="levels"
                :label="$t('summer.level.name_ar')"
                @update:modelValue="(lessonRecord.subject = null), getSubjects()"
              />
            </v-col>
            <v-col cols="4" md="3" >
              <v-autocomplete
                color="indigo"
                v-model="lessonRecord.subject"
                density="compact"
                item-title="name"
                item-value="id"
                prepend-inner-icon="mdi-bookshelf"
                clearable
                :items="subjects"
                :label="$t('subject.select')"
              />
            </v-col>
            <VCol class="pt-2 px-4 px-lg-3" cols="3" md="2">
              <VCardItem class="pa-0">
                <v-btn
                  @click="getLessonRecord()"
                  :loading="DataLoading"
                  class="bg-indigo"
                  size="small"
                >
                  <span class="text-white">
                    {{ $t("globals.search") }}
                  </span>
                </v-btn>
              </VCardItem>
            </VCol>
          </VRow>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
    <VCard class="mt-2" v-if="!DataLoading">
    <!-- ######## Header Of Data Table  ####### -->
      <v-card-text class="pb-0">
        <VRow class="mb-2 mt-1">
          <v-col cols="12" md="4" class="mt-1 ">
            <v-text-field
              color="indigo"
              v-model="txt_search"
              hide-details
              prepend-inner-icon="mdi-magnify"
              single-line
              clearable
              :placeholder="$t('globals.search-here')"
              density="compact"
            >
            </v-text-field>
          </v-col>
          <v-col cols="6" md="4" sm="4" class="mt-1">
            <v-autocomplete
              color="indigo"
              v-model="lessonRecord.fk_week"
              density="compact"
              item-title="week_name"
              item-value="id"
              hide-details
              prepend-inner-icon="mdi-bookshelf"
              clearable
              :items="weeks"
              :label="$t('summer.calendar.week-name')"
              @update:model-value="lessonRecord.fk_week?(getDateList(),lessonRecord.date=undefined):null"
            />
          </v-col>
          <v-col cols="6" md="4" sm="4" class="mt-1">
            <v-autocomplete
              color="indigo"
              v-model="lessonRecord.date"
              density="compact"
              item-title="week_name"
              item-value="id"
              prepend-inner-icon="mdi-bookshelf"
              clearable
              hide-details
              :items="listWeeks"
              @update:modelValue="getLessonRecord"
              :label="$t('summer.calendar.hijri-date')"
            />
            <!-- lessonRecord.date?getLessonRecord():null -->
          </v-col>
        </VRow>
        <!-- ######## End Header Of Data Table Server ####### -->
        <v-data-table
          v-if="listLessons.length > 0"
          :loading="DataLoading"
          :headers="headers"
          :items="listLessons"
          item-key="id"
          id="vDataTable"
          fixed-header
          :search="txt_search"
          height="400"
          density="compact"
          class="elevation-1 rounded"
          items-per-page="0"
          
        >
          <template  v-slot:item.is_done="{ item }">
            <v-lazy>
            <v-checkbox
              v-model="item.value.is_done"
              color="indigo"
              density="compact"
              hide-details
            >
            </v-checkbox>
            </v-lazy>
          </template>
          <template v-slot:bottom>

          </template>
        </v-data-table>
        <v-card class="py-2 text-center my-2" v-if="listLessons.length == 0 ">
          <span class=" text-grey-darken-2 pb-3" >
            {{ $t("globals.not-found-items") }}
          </span>
        </v-card>
        
      </v-card-text>
      <VCardActions
            class="px-3"
            v-if="listLessons.length> 1 "
          >
            <VBtn
              class="bg-indigo"
              @click="saveLessonRecord()"
              size="small"
              :loading="saveDataLoading"
            >
              <span class="text-white">
                {{ $t("globals.save") }}
              </span>
              <VIcon icon="mdi-content-save" color="white" end></VIcon>
            </VBtn>
            <VBtn class="mx-3" size="small" @click="resetForm()">
              {{ $t("globals.clear") }}
              <VIcon icon="mdi-broom" color="golden" end></VIcon>
            </VBtn>
      </VCardActions>
    </VCard>
  </VForm>
  
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import Datetime from "vue3-datetime-js";
export default {
  data() {
    return {
      DataLoading: false,
      showRecord:0,
      saveDataLoading: false,
      is_found_data: false,
      subjects: [],
      checkAttendance: [],
      listLessons: [],
      listWeeks: [],
      lessonRecord: {},
      sortBy: [
        {
          key: "index",
          order: "asc",
        },
      ],
      rules: {
        fk_week: [(value) => !!value || this.$t("globals.required_field")],
        attendance_date: [(value) => !!value || this.$t("globals.required_field")],
      },
      dialogVisible: false,
      delete_id: undefined,
      selectedHead: ["index", "name", "is_done"],
      headers: [
        { title: this.$t("globals.number"), key: "index", sortable: false },
        { title: this.$t("summer.lesson-record-name"), key: "name" },
        {
          title: this.$t("summer.level.name_ar"),
          key: "level",
        },
        {
          title: this.$t("subject.name"),
          key: "subject_name",
        },
        {
          title: this.$t("summer.lesson-record-done"),
          key: "is_done",
          sortable: false,
        },
      ],
      txt_search: undefined,
    };
  },
  components: { Datetime },
  computed: {
    ...mapState({
      employees: (state) => state.summer.employees,
      weeks: (state) => state.summer.weeks,
      user: (state) => state.User,
      levels: (state) => state.summer.levels,
    }),
    ...mapGetters({}),
    selectedHeaders() {
      return this.headers.filter((header) => this.selectedHead.includes(header.key));
    },
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
    CheckGetPermission() {
      return (prem,role) => {
        return this.$store.getters.checkpermission(prem,role);
      };
    },
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.DataLoading=true
      await this.getWeeks();
      await this.getLevels();
      await this.getLessonRecord();
      await this.getDateList()
      this.DataLoading=false
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.DataLoading=false
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    ...mapActions({
      getWeeks: "summer/getWeeks",
      getLevels: "summer/getLevels",
    }),
    async getDateList() {
      if(this.lessonRecord.fk_week)
      await this.axios
        .get(this.base_url + "api/summer/get-work-days", {
          params: {
            week_id: this.lessonRecord.fk_week,
          },
        })
        .then((response) => {
          this.listWeeks = response.data.date;
        })
      else
        this.listWeeks=[]
        
    },
    async getSubjects() {
      if (this.lessonRecord.level) {
        let subjects = await this.axios.get(
          this.base_url + "api/summer/subjects-by-level",
          {
            params: {
              level: this.lessonRecord.level,
            },
          }
        );
        this.subjects = subjects.data;
      } else {
        this.subjects = "";
      }
    },
    updateIsDone(index) {
      this.listLessons[index].is_done = !this.listLessons[index].is_done;
    },
    resetForm() {
      this.lessonRecord.date=null
      this.lessonRecord.fk_week=null
      this.lessonRecord.subject=null
      this.lessonRecord.level=null
      this.getLessonRecord()
    },
    cancelDeleteItem() {
      this.delete_id = undefined;
      this.dialogVisible = false;
    },
    async saveLessonRecord() {
      this.saveDataLoading = true;
      let newListLessons = {
        date:this.lessonRecord.date,
        data:[]
      };
      newListLessons.data = this.listLessons.map((element) => ({
        data_entry: this.user,
        is_done: element.is_done,
        fk_lecture: element.fk_lecture,
      }));
      let result = await this.axios
        .post(this.base_url + "api/summer/lecture-logs", newListLessons)
        .then((response) => {
          this.$emit("successAlert", this.$t("globals.data_added"));
          this.saveDataLoading = false;
          this.getLessonRecord();
        })
        .catch((error) => {

          if(error.message.includes('501')){
            this.$emit("errorAlert", "calendar is not acceptable ");
          }else{
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          }

          this.saveDataLoading = false;
        });
    },
    async getLessonRecord() {
      this.listLessons=[]
      this.DataLoading = true;
      let result = await this.axios
        .get(this.base_url + "api/summer/lecture-logs", {
          params: {
            level: this.lessonRecord.level,
            subject: this.lessonRecord.subject,
            date:this.lessonRecord.date
          },
        })
        .then((response) => {
          this.listLessons = response.data?.data??[];
          this.lessonRecord.date=response.data?.date
          this.lessonRecord.fk_week=response.data?.week
          this.listLessons?.forEach((element,index) => {
            element.index=index+1
          });
          this.DataLoading = false;
        })
        .catch((error) => {
          this.DataLoading = false;
          if (error.response.data === "is_holiday") {
            this.$emit("warningAlert", this.$t("summer.is-holiday"));
          } else if (error.response.data === "out_of_range") {
            this.$emit("warningAlert", this.$t("summer.out-of-range"));
          } else {
            this.$emit("warningAlert", this.$t("summer.not-found-data"));
          }
        });
    },
  },
  
};
</script>

<style>
.v-input--density-compact {
    --v-input-control-height: 35px;
    --v-input-padding-top: 8px;
}

</style>
