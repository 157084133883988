<template>
  <VForm ref="form" v-if="CheckGetPermission('summer_center.add_employee',['sys_admin','summer_admin','user']) || CheckGetPermission('summer_center.change_employee',['sys_admin','summer_admin','user'])">
    <v-expansion-panels >
        <v-expansion-panel    >
            <v-expansion-panel-title class="px-4">
                <span>{{$t('globals.select-criteria')}}</span>
            </v-expansion-panel-title>
            <v-expansion-panel-text >
            <v-row  class="py-1" >
              <v-col cols="12" md="4" sm="6" class="pt-0 px-3 ">
                <v-text-field
                  color="indigo"
                  v-model="search_employee"
                  density="compact"
                  clearable
                  hide-details
                  prepend-inner-icon="mdi-magnify"
                  :label="$t('globals.employee-search')"
                />
              </v-col>
              <v-col cols="12" md="8" sm="6" >
                <VAutocomplete
                  :items="skills"
                  v-model="skills_selected"
                  prepend-inner-icon="mdi-stairs-box"
                  item-title="title"
                  return-object
                  :item-value="item=>item.id"
                  :label="$t('skill.select-multi')"
                  multiple
                  chips
                  closable-chips
                  hide-details
                  persistent-hint
                  density="compact"
                  clearable
                >
                  <template v-slot:chip="{props , item}">
                    <v-chip 
                      class="mx-1"
                      style="font-size:0.9rem"
                      v-bind="props" :text="item.raw.name">
                    </v-chip>
                  </template>
                </VAutocomplete>
              </v-col>
              
            </v-row>
            </v-expansion-panel-text>
        </v-expansion-panel>
    </v-expansion-panels>
    <v-progress-linear 
      color="indigo"
      v-if="loading"
      indeterminate
      height="2px"
      :reverse="$i18n.locale=='ar'"
      >
    </v-progress-linear>
    <VCard class="justify-center mt-2" v-if="employee_data.length>0">
      <v-card-text class="pb-0">
        <v-card class="mt-1 "   v-if="employees_filter.length>0">
          <v-table  
            class="pb-2"
            density="compact">
            <thead>
              <tr class="mb-2">
                <th style="max-width:50px; min-width:20px" class="text-center">{{$t('globals.number')}}</th>
                <th class="text-center">{{$t('summer.name-employees')}}</th>
                <th class="text-center" v-for="skill in filter_skills " :key="skill" >
                  {{skill.title}}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(employee,index) in displayItems" :key="employee" >
                <td style="max-width:20px; min-width:20px" class="text-center">{{index+1}}</td>
                <td >
                  <span>{{employee.name_ar}}</span>
                </td>
                <td class="text-center" v-for="skill in filter_skills" :key="skill" > 
                  <div class="d-inline-flex">
                    <v-checkbox 
                      v-model="employee.skill"
                      :value="skill.id"
                      hide-details
                      @change="addEmployeeToList(employee.id)"
                      density="compact" 
                      color="indigo" 
                      
                      >
                    </v-checkbox>
                  </div>
                </td>
              </tr>
            </tbody>
          </v-table>
          <div class="d-flex mt-1 "  id="pagination-bar" >
            <v-pagination  
                v-model="currentPage" 
                :length="totalPages"   
                density="compact"
                show-first-last-page
                >
            </v-pagination>
            <div class="d-flex ">
              <v-select 
                v-model="perPage" 
                class="pa-0" 
                :items="itemsPerPage"  
                item-value="value" 
                item-title="text" 
                density="compact" 
                hide-details
                variant="text"
                >
              </v-select>
              <span class="mt-2 px-2">{{$t('globals.per-page')}} {{$t('globals.from')}} [ {{employee_data.length}} ] {{$t('globals.item')}}</span>
            </div>
          </div>
        </v-card>
        <v-card v-else-if="!loading || (!loading && employees.length>0)"  class="text-center pa-2 mb-2" >
          <span >
            {{ $t("globals.not-found-items") }}
          </span>
        </v-card>
      </v-card-text>
      <VCardActions 
        v-if="employees_filter.length>0"
        class="mx-1"
        >
      <VBtn
          :loading="loadingSaveData"
          v-if="
            CheckGetPermission('summer_center.add_employee',['sys_admin','summer_admin','user']) ||
            CheckGetPermission('summer_center.change_employee',['sys_admin','summer_admin','user'])
          " 
          class="bg-indigo"
          @click="saveData"
          size="small"
        >
          <span class="text-white">
            {{ $t("globals.save") }}
          </span>
          <VIcon icon="mdi-content-save" color="white" end></VIcon>
        </VBtn>

        <VBtn class="mx-3" size="small" @click="resetForm">
          {{ $t("globals.clear") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
      </VCardActions>
    </VCard>
    <v-card class="text-center pa-2 mt-2" v-if="!loading&&employee_data.length<1">
      <span>
        {{ $t("globals.not-found-items") }}
      </span>
    </v-card>
  </VForm>
</template>
<script>


import { mapState, mapActions } from "vuex";
export default {
  props: {
    id: {
      type: Number,
    },
  },
  data() {
    return {
      loading:false,
      loadingSaveData:false,
      employee_data:[],
      changed_employeed:[],
      search_employee:'',
      skills_selected:[],
      employees:[],
      filter_skills:[],
      icon_color: undefined,
      icon: undefined,
      alert_title: undefined,
      alert_message: undefined,
      perPage:10,
      itemsPerPage: [
        {value:10,text:"10"},
        {value:25,text:"25"},
        {value:50,text:"50"},
        {value:100,text:"100"},
        {value:null,text:this.$t("globals.all")},
      ],
      currentPage:1,
    };
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.fk_year = localStorage.getItem("current_year");
      await this.getSkills();
      await this.getData()
      this.filter_skills=this.skills
      if(this.employee==undefined){
        this.employees=[]
      }else{
        this.employees=this.employee
      }
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    } 
  },
  computed: {
    
    ...mapState({
      skills: (state) => state.summer.skills,
      employee: (state) => state.summer.employees,
      user: (state) => state.User,
    }),
    totalPages(){
        return Math.ceil(this.employees_filter.length/this.perPage)
    },
    displayItems(){
        const start_index=(this.currentPage -1) * this.perPage
        const end_index= start_index+this.perPage
        return this.employees_filter.slice(start_index,end_index)
    },
    employees_filter(){
      if(this.search_employee=='' || this.search_employee==null){
        return this.employee_data
      }
      return this.employee_data.filter(employee=>{
          return employee.name_ar .includes(this.search_employee)
      })
    },
    CheckGetPermission() {
      return (prem,role) => {
        return this.$store.getters.checkpermission(prem,role);
      };
    },
  },
  methods: {
    ...mapActions({
      getSkills: "summer/getSkills",
    }),
    addEmployeeToList(id){
      if(!this.changed_employeed.includes(id)){
        this.changed_employeed.push(id)
      }
    },
    resetForm() {
      this.$refs.form.resetValidation();
      this.getData()
      this.skills_selected=[]
      this.search_employee=''
    },
    async getData(){
      this.loading=true;
      await this.axios
        .get(
          this.base_url +
            `api/summer/add-skills-to-employee`)
        .then((response) => {
          this.loading=false;
          this.employee_data=response.data
          this.itemsPerPage[4]['value']=this.employee_data.length
        })
        this.loading=false
    },

    async saveData() {
      this.loadingSaveData=true
      let result = await this.axios
        .post( this.base_url +`api/summer/add-skills-to-employee`,this.employee_data)
        .then((response) => {
          this.loadingSaveData=false
          this.$emit("successAlert", this.$t("globals.data_added"));
        })
        .catch((error) => {
          this.loadingSaveData=false
          this.$emit(
            "errorAlert",
            this.$t("globals.error_in_repeted_data")
          );
        });
      this.resetForm();
    },
    
  },
  watch:{
    skills_selected(){
      if(this.skills_selected.length>0){
        this.filter_skills=this.skills_selected
      }else{
        this.filter_skills=this.skills
      }
    }
  }
};
</script>
<style scoped>
.select-container {
  height: 280px;
  box-shadow: rgb(0, 0, 0) -5px 5px 3px -5px inset;
  overflow-y: auto;
}
td {
  padding: 0 !important;
}
</style>
