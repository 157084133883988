<template>
  <v-card flat id="lectures-report">
    <ReportHeader class="pb-0">
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          <div class="text-h5">
            {{ $t("permission.schoolsummer") }}
          </div>
        </div>
      </template>
    </ReportHeader>
        <v-card-text class="mt-4">
        <div class="d-flex justify-space-between mb-2" id="tableHead">
            <div class="d-flex justify-start" style="flex: 1"></div>
            <div class="d-flex justify-end" style="flex: 1">
            <v-btn
                density="compact"
                class="pa-0 mx-1"
                style="min-width: 25px"
                @click="printTable"
            >
                <v-icon color="icon-color">mdi-printer</v-icon>
                <v-tooltip class="tooltip" activator="parent" location="top">
                    <small>{{ $t("globals.print-file") }}</small>
                </v-tooltip>
            </v-btn>
            </div>
        </div>

        <div id="myTable">
            <v-card v-if="!items" class="pa-2">
                <h3 class="text-center text-grey-darken-2">
                    {{ $t("globals.not-found-items") }}
                </h3>
            </v-card>
            <v-card
                class="pt-2"
                :loading="loading"
                v-if="items"
                rounded="0"
                flat
            >
                <table
                    id="stable"
                    class="table mb-6"
                    style="width: 100%; border-collapse: collapse"
                >
                    <thead class="table-light">
                        <tr>
                            <th colspan="2" class="bg-blue-grey-lighten-4 pa-2pa-2 p-5">
                                {{ $t("permission.schoolsummer") }}
                            </th>
                        </tr>
                        <tr>
                            <th class="bg-blue-grey-lighten-4 pa-2pa-1">
                                {{ $t("report.study-start-time") }}
                            </th>
                            <th class="bg-blue-grey-lighten-4 pa-2pa-1">
                                {{ $t("report.study-end-time") }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="text-center">
                                {{ items.start_date }}
                            </td>
                            <td class="text-center">
                                {{ items.end_date }}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table
                    id="stable"
                    class="table mb-6"
                    style="width: 100%; border-collapse: collapse"
                >
                    <thead class="table-light">
                        <tr class="text-center">
                            <td rowspan="2" class="bg-blue-grey-lighten-4">
                                {{ $t("summer.school_summer_name") }}
                            </td>
                            <td rowspan="2" class="bg-blue-grey-lighten-4">
                                {{ $t("globals.governorate") }}
                            </td>
                            <td rowspan="2" class="bg-blue-grey-lighten-4">
                                {{ $t("globals.directorate") }}
                            </td>
                            <td rowspan="2" class="bg-blue-grey-lighten-4">
                                {{ $t("globals.address") }}
                            </td>
                            <td colspan="2" class="bg-blue-grey-lighten-4">
                                {{ $t("summer.employee_count") }}
                            </td>
                            <td colspan="2" class="bg-blue-grey-lighten-4">
                                {{ $t("summer.student_count") }}
                            </td>
                            <td colspan="3" class="bg-blue-grey-lighten-4">
                                {{ $t("summer.school_summer_type") }}
                            </td>
                            <td colspan="3" class="bg-blue-grey-lighten-4">
                                {{ $t("summer.work_time") }}
                            </td>
                        </tr>
                        <tr class="text-center">
                            <td class="bg-blue-grey-lighten-4">
                                {{ $t("globals.males") }}
                            </td>
                            <td class="bg-blue-grey-lighten-4">
                                {{ $t("globals.fmales") }}
                            </td>
                            <td class="bg-blue-grey-lighten-4">
                                {{ $t("globals.males") }}
                            </td>
                            <td class="bg-blue-grey-lighten-4">
                                {{ $t("globals.fmales") }}
                            </td>
                            <td class="bg-blue-grey-lighten-4">
                                {{ $t("globals.males") }}
                            </td>
                            <td class="bg-blue-grey-lighten-4">
                                {{ $t("globals.fmales") }}
                            </td>
                            <td class="bg-blue-grey-lighten-4">
                                {{ $t("globals.mix") }}
                            </td>
                            <td class="bg-blue-grey-lighten-4">
                                {{ $t("globals.morning$afternoon") }}
                            </td>
                            <td class="bg-blue-grey-lighten-4">
                                {{ $t("globals.morning") }}
                            </td>
                            <td class="bg-blue-grey-lighten-4">
                                {{ $t("globals.afternoon") }}
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="text-center">
                            <td class="text-center border">
                                {{
                                    $i18n.locale == "ar"
                                        ? items.name_ar
                                        : items.name_en
                                }}
                            </td>
                            <td class="text-center border">
                                {{ items.governorate }}
                            </td>
                            <td class="text-center border">
                                {{ items.fk_directorate }}
                            </td>
                            <td class="text-center border">
                                {{ items.address }}
                            </td>
                            <td class="text-center border">
                                {{ items.employees_male }}
                            </td>
                            <td class="text-center border">
                                {{ items.employees_female }}
                            </td>
                            <td class="text-center border">
                                {{ items.students_male }}
                            </td>
                            <td class="text-center border">
                                {{ items.students_female }}
                            </td>
                            <td class="text-center border">
                                {{
                                    items.students_type == "بنين"
                                        ? $i18n.locale == "ar"
                                            ? "نعم"
                                            : "yes"
                                        : $i18n.locale == "ar"
                                        ? "لا"
                                        : "no"
                                }}
                            </td>
                            <td class="text-center border">
                                {{
                                    items.students_type == "بنات"
                                        ? $i18n.locale == "ar"
                                            ? "نعم"
                                            : "yes"
                                        : $i18n.locale == "ar"
                                        ? "لا"
                                        : "no"
                                }}
                            </td>
                            <td class="text-center border">
                                {{
                                    items.students_type == "عام"
                                        ? $i18n.locale == "ar"
                                            ? "نعم"
                                            : "yes"
                                        : $i18n.locale == "ar"
                                        ? "لا"
                                        : "no"
                                }}
                            </td>
                            <td class="text-center border">
                                {{
                                    items.shift_time == "صباحي ومسائي"
                                        ? $i18n.locale == "ar"
                                            ? "نعم"
                                            : "yes"
                                        : $i18n.locale == "ar"
                                        ? "لا"
                                        : "no"
                                }}
                            </td>
                            <td class="text-center border">
                                {{
                                    items.shift_time == "صباحي"
                                        ? $i18n.locale == "ar"
                                            ? "نعم"
                                            : "yes"
                                        : $i18n.locale == "ar"
                                        ? "لا"
                                        : "no"
                                }}
                            </td>
                            <td class="text-center border">
                                {{
                                    items.shift_time == "مسائي"
                                        ? $i18n.locale == "ar"
                                            ? "نعم"
                                            : "yes"
                                        : $i18n.locale == "ar"
                                        ? "لا"
                                        : "no"
                                }}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <h2 style="color: cornflowerblue" class="mb-3">
                    <span style="font-size:50px">.</span>{{ $t("summer.activity.place") }}:
                </h2>
                <table
                    id="stable"
                    class="table mb-6"
                    style="width: 100%; border-collapse: collapse"
                >
                    <thead class="table-light">
                        <tr>
                            <th class="bg-blue-grey-lighten-4 pa-2pa-1">
                                {{ $t("summer.place-type") }}
                            </th>
                            <th class="bg-blue-grey-lighten-4 pa-2pa-1">
                                {{ $t("summer.class-count-study") }}
                            </th>
                            <th class="bg-blue-grey-lighten-4 pa-2pa-1">
                                {{
                                    $t(
                                        "summer.overall-assessment-for-place"
                                    )
                                }}
                            </th>
                            <th class="bg-blue-grey-lighten-4 pa-2pa-1">
                                {{ $t("summer.obtain-permit") }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="text-center">
                                {{ items.place_type }}
                            </td>
                            <td class="text-center">
                                {{ items.class_count }}
                            </td>
                            <td class="text-center">
                                {{ items.evaluation }}
                            </td>
                            <td class="text-center">
                                {{
                                    items.have_license == true
                                        ? $i18n.locale == "ar"
                                            ? "نعم"
                                            : "yes"
                                        : $i18n.locale == "ar"
                                        ? "لا"
                                        : "no"
                                }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </v-card>
        </div>
        </v-card-text>
    </v-card>
    <Alert
        v-model="process_alert"
        :iconColor="icon_color"
        :icon="icon"
        :title="alert_title"
        :message="alert_message"
    >
    </Alert>
</template>
<script>
import ReportHeader from "@/components/SummerGlobals/ReportHeader.vue";
import useValidate from "@vuelidate/core";
export default {
  components: { ReportHeader },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.$store.dispatch("updatePermission", localStorage.getItem("userinfo"));
      await this.getSchoolData();
 
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },

  data() {
    return {
      school: [],
      process_alert: false,
      icon_color: undefined,
      alert_title: undefined,
      alert_message: undefined,
      v$: useValidate(),
      items: undefined,
      loading: false,
    };
  },

  methods: {
    async getSchoolData() {
      this.loading = true;
      await this.axios
        .get(`${this.base_url}api/summer/report/school-data`)
        .then((response) => {
          if (response.data) {
            this.items = response.data;
            this.loading = false;
          }
        })
        .catch((error) => {
          this.icon = "cancel";
          this.icon_color = "error";
          if(error.code=="ECONNABORTED"){
            this.alert_title = this.$t("alert.failure.title");
            this.alert_message = this.$t("request.timeout");
          }
          else if(error.message.includes("Network Error")) {
            this.alert_title = this.$t("alert.failure.title");
            this.alert_message = this.$t("alert.failure.connect");
          } else {
            this.alert_title = this.$t("alert.not-found.title");
            this.alert_message = this.$t("alert.not-found.message");
          }
          this.process_alert = true;
          setTimeout(() => {
            this.process_alert = false;
          }, 2000);
        });
    },
    async printTable() {
      window.print();
    },
  },
  watch: {
    perPage() {
      this.getData();
    },
    page() {
      this.getData(this.page);
    },
  },
};
</script>
<style scoped>
#stable {
  width: 100%;
}
.pp2 {
  padding: 2px !important;
}
#stable th { 
  padding: 10px;
  border: 1px solid #649add !important;
  background-color: #fff;
  font-size: 12px;
}
#stable td {
  border: 1px solid #649add !important;
  background-color: #fff;
  padding: 8px;
}
.fontsize {
  font-size: 12px;
}
/* .borders {
  border: solid #c1c1c1 1px;
  border-radius: 8px;
} */
@media print {
  #sidebar,
  #header,
  #pagination-bar *,
  #tableHead,
  .tooltip,
  #print-menu {
    display: none !important;
  }
  #lectures-report {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    /* height: 100%; */
  }
}
</style>
