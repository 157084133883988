<template>
  <!-- filter data  -->
  <v-card class="pa-2" v-if="!exist">
    <h3 class="text-grey-darken-2 px-2">{{ $t("globals.select-criteria") }}</h3>
    <v-form ref="form">
      <v-row class="my-2 mt-6">
        <v-col cols="12" md="3" class="mb-3">
          <VAutocomplete
            :items="weeks"
            v-model="filter_data.fk_week"
            item-title="week_name"
            item-value="id"
            :label="$t('summer.calendar.select-week')"
            persistent-hint
            prepend-inner-icon="mdi-calendar-week"
            density="compact"
            clearable
            :rules="rules.week"
          ></VAutocomplete>
        </v-col>
        <VCol class="pt-0 px-3" cols="12" md="3">
          <VCardItem class="pa-0">
            <VAutocomplete
              :items="levels"
              v-model="filter_data.level"
              item-title="level_with_phase_name"
              item-value="id"
              :label="$t('summer.level.level')"
              persistent-hint
              prepend-inner-icon="mdi-stairs-box"
              density="compact"
              clearable
            ></VAutocomplete>
          </VCardItem>
        </VCol>

        <v-col cols="6" sm="2" class="d-flex mt-2">
          <v-btn
            density="comfortable"
            class="w-100"
            color="indigo"
            :loading="loading"
            @click="checkFilterData"
          >
          <span class="text-white">{{ $t("globals.show") }}</span>
          <VIcon icon="mdi-presentation" color="white" end></VIcon>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
  <v-card flat class="justify-center" id="lectures-report" v-if="exist">
    <ReportHeader class="pb-0">
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          <div class="text-h5">{{ $t("summer.report.schedule-statistic") }}( {{getWeekName(filter_data.fk_week)}} )</div>
        </div>
      </template>
    </ReportHeader>

    <v-card-text class="mb-4">
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <div class="d-flex justify-start" style="flex: 1"></div>
        <!-- search box  -->

        <div class="d-flex justify-end" style="flex: 1">
          <v-btn
            v-bind="props"
            density="compact"
            class="pa-0 mx-1"
            style="min-width: 25px"
            @click="printTable((all = false))"
          >
            <v-icon color="icon-color">mdi-printer</v-icon>
            <v-tooltip class="tooltip" activator="parent" location="top">
              <small>{{ $t("globals.print-file") }}</small>
            </v-tooltip>
          </v-btn>
          <v-btn density="compact" class="mx-1" @click="exist = false">
            <span class="text-indigo">{{ $t("globals.back") }}</span>
          </v-btn>
        </div>
      </div>

      <div id="myTable">
        <v-card v-if="items.length < 1" class="pa-2">
          <h3 class="text-center text-grey-darken-2">
            {{ $t("globals.not-found-items") }}
          </h3>
        </v-card>
        <v-card class="pt-2" :loading="loading" v-if="items.length > 0" rounded="0" flat>
          <table id="stable" class="table" style="width: 100%; border-collapse: collapse">
            <tbody>
              <tr>
                <th class="bg-blue-grey-lighten-4 pa-2">{{ $t("financial.statement") }}</th>
                <th v-for="level in items" :key="level" class="bg-blue-grey-lighten-4 pa-2">
                  {{ level.level }}
                </th>
                <th rowspan="2" class="bg-blue-grey-lighten-4">
                  <span style="writing-mode: vertical-rl; transform: rotate(180deg)">
                    {{ $t("report.final-collector") }}
                  </span>
                </th>
              </tr>
              <tr>
                <td class="text-center border bg-blue-grey-lighten-4">
                  {{ $t("subject.subject") }}
                </td>
                <td
                  class="text-center border"
                  style="padding: 0 !important"
                  v-for="(item, key) in items"
                  :key="item"
                >
                  <table style="width: 100%; border-collapse: collapse">
                    <tr>
                      <td
                        v-for="subject in item.subjects"
                        :key="subject"
                         :style="{
                          height: filter_data.level ? '' : '200px',
                          padding: '0 !important',
                          width:'20%'
                        }"
                      >
                        <span
                          style="writing-mode: vertical-rl; transform: rotate(180deg)"
                        >
                          {{ subject.subject }}
                        </span>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td class="text-center border bg-blue-grey-lighten-4">
                  {{ $t("dashboard.lectures-count") }}
                </td>
                <td
                  class="text-center border"
                  style="padding: 0 !important"
                  v-for="item in items"
                  :key="item"
                >
                  <table style="width: 100%; border-collapse: collapse">
                    <tr>
                      <td
                        v-for="subject in item.subjects"
                        :key="subject"
                        style="height: 30px; padding: 0 !important"
                      >
                        <span
                          style="writing-mode: vertical-rl; transform: rotate(-90deg)"
                        >
                          {{ subject.count }}
                        </span>
                      </td>
                    </tr>
                  </table>
                </td>
                <td class="text-center border">
                  {{ getTotalCount(items) }}
                </td>
              </tr>
            </tbody>
          </table>
        </v-card>
      </div>
    </v-card-text>
  </v-card>

  <Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
  >
  </Alert>
</template>

<script>
import ReportHeader from "../../../components/SummerGlobals/ReportHeader";
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  components: { ReportHeader },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.$store.dispatch("updatePermission", localStorage.getItem("userinfo"));
      await this.getLevels();
      await this.getWeeks();
      await this.getYears();
      this.current_year = this.getYearName(localStorage.getItem("current_year"));
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },

  data() {
    return {
      rules: {
        week: [(value) => !!value || this.$t("globals.required_field")],
      },
      divisions: [],
      current_year: "",
      process_alert: false,
      icon_color: undefined,
      alert_title: undefined,
      alert_message: undefined,
      exist: false,
      items: [],
      loading: false,
      txt_search: "",
      selectedHead: ["id", "name", "write_yes_or_no"],
      headers: [
        { title: "#", key: "id" },
        { title: this.$t("summer.report.task-name"), key: "name" },
        { title: this.$t("summer.report.write-yes-or-no"), key: "write_yes_or_no" },
      ],

      perPage: 25,
      length: 0,
      totalItems: 0,
      itemsPerPage: [
        { value: 5, text: "5" },
        { value: 10, text: "10" },
        { value: 15, text: "15" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 75, text: "75" },
        { value: 100, text: "100" },
        { value: "", text: this.$t("globals.all") },
      ],
      page: 1,
      pagination: {},
      filter_data: {},
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.User,
      weeks: (state) => state.summer.weeks,
      levels: (state) => state.summer.levels,
    }),

    ...mapGetters({}),

    selectedHeaders() {
      return this.headers.filter((header) => this.selectedHead.includes(header.key));
    },
  },
  methods: {
    ...mapActions({
      getLevels: "summer/getLevels",
      getWeeks: "summer/getWeeks",
    }),
    getWeekName(id){
      let week=this.weeks.find(item=>item.id==id)
      if(week != undefined)
        return week.week_name
      else
        return ''
    },
    getTotalCount(items) {
      let count = 0;
      items.forEach((element) => {
        element.subjects.forEach((subject) => {
          count += subject.count;
        });
      });
      return count;
    },
    async getDivisionByLevel() {
      let levelDivisions = await this.axios.get(this.base_url + `api/summer/get-level-division/${this.filter_data.level}`);
      this.divisions = levelDivisions.data.division;
    },
    getYears() {
      this.year_data = localStorage.getItem("current_year_name_h");
      this.filter_data.year = localStorage.getItem("current_year");
      return this.year_data;
    },
    async checkFilterData() {
      const { valid } = await this.$refs.form.validate();
      if (valid) this.getData();
    },
    async printTable(all) {
      if (all) {
        this.txt_search = null;
        this.perPage = this.totalItems;
        await this.getData();
      }
      window.print();
    },
    async getData() {
      this.loading = true;
      await this.axios
        .get(`${this.base_url}api/summer/report/schedule-statistic`, {
          params: {
            search: this.txt_search,
            fk_week: this.filter_data.fk_week,
            fk_level: this.filter_data.level,
          },
        })
        .then((response) => {
          ``;
          this.exist = true;
          if (response.data) {
            this.items = response.data;
            this.loading = false;
          }
        })
        .catch((error) => {
          this.icon = "cancel";
          this.icon_color = "error";
          if (error.message.includes("Network Error")) {
            this.alert_title = this.$t("alert.failure.title");
            this.alert_message = this.$t("alert.failure.connect");
          } else {
            this.alert_title = this.$t("alert.not-found.title");
            this.alert_message = this.$t("alert.not-found.message");
          }
          this.process_alert = true;
          setTimeout(() => {
            this.process_alert = false;
          }, 2000);
        });
    },
  },

  watch: {
    perPage() {
      this.getData();
    },
    page() {
      this.getData(this.page);
    },
  },
};
</script>
<style scoped>
#stable {
  width: 100%;
}

#stable th {
  border: 1px solid #649add;
  background-color: #fff;
}

 

#stable td {
  border: 1px solid #649add !important;
  background-color: #fff;
}
.search:focus {
  outline: unset;
}
.fontsize {
  font-size: 14px;
}
.borders {
  border: solid #c1c1c1 1px;
  border-radius: 8px;
}

 
@media print {
  #sidebar,
  #header,
  #pagination-bar *,
  #tableHead,
  .tooltip,
  #print-menu {
    display: none !important;
  }
  #lectures-report {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    /* height: 100%; */
  }
}
</style>
