<template>
  <!-- filter data  -->
  <v-card  v-if="!exist">
    <v-card-text>
      <v-form ref="form">
        <h3 class="text-grey-darken-2 ">{{ $t("globals.select-criteria") }}</h3>
        <v-row class=" mt-4">
          <v-col cols="12" md="4" sm="6" >
            <VAutocomplete
              :items="weeks"
              v-model="filter_data.fk_week"
              item-title="week_name"
              item-value="id"
              :label="$t('summer.calendar.select-week')"
              persistent-hint
              prepend-inner-icon="mdi-calendar-week"
              density="compact"
              clearable
              :rules="week"
            ></VAutocomplete>
          </v-col>
          <v-col cols="6" sm="2" class=" mt-2">
            <v-btn
              density="comfortable"
              append-icon="mdi-presentation"
              class="w-100 text-white"
              color="indigo"
              :loading="loading"
              @click="checkFilterData"
            >
              <span>{{ $t("globals.show") }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
  <v-card flat class="justify-center" id="lectures-report" v-if="exist">
    <ReportHeader>
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          <div class="text-h5">
            {{ $t("summer-report.employee-attendance") }} (
            {{ getWeekName(this.filter_data.fk_week) }} )
          </div>
        </div>
      </template>
    </ReportHeader>

    <v-card-text class="mt-4">
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <div class="d-flex justify-end" style="flex: 1">
          <v-btn
            density="compact"
            class="pa-0 mx-1"
            style="min-width: 25px"
            @click="printTable"
          >
            <v-icon color="icon-color">mdi-printer</v-icon>
            <v-tooltip class="tooltip" activator="parent" location="top">
              <small>{{ $t("globals.print-file") }}</small>
            </v-tooltip>
          </v-btn>

          <v-btn density="compact"  @click="exist = false">
            <span class="text-indigo">{{ $t("globals.back") }}</span>
          </v-btn>
        </div>
      </div>

      <div id="myTable">
        <v-card v-if="items.length < 1" class="pa-2">
          <h3 class="text-center text-grey-darken-2">
            {{ $t("globals.not-found-items") }}
          </h3>
        </v-card>
        <v-card class="pt-2" :loading="loading" v-if="items.length > 0" rounded="0" flat>
          <table id="stable" class="table" style="width: 100%; border-collapse: collapse">
            <thead class="table-light">
              <th
                v-for="header in selectedHeaders"
                :key="header.key"
                 class="text-center  bg-blue-grey-lighten-4"
              >
                {{ header.title }}
              </th>
              <th
                v-for="week in items[0].weeks"
                :key="week"
                class="bg-blue-grey-lighten-4 pt-2"
               >
                {{ week.week_name }}
                <table class="mt-1" style="width: 100%; border-collapse: collapse">
                  <thead>
                    <tr class="bg-blue-grey-lighten-4" style="border-width: 0">
                      <th
class="inside-th-cells bg-blue-grey-lighten-4"                        style="max-width: 35px; width: 35px; padding: 5px 1px"
                        
                      >
                        السبت
                      </th>
                      <th
                        class="inside-th-cells bg-blue-grey-lighten-4"
                        style="max-width: 35px; width: 35px; padding: 5px 1px"
                      >
                        الاحد
                      </th>
                      <th
                        class="inside-th-cells bg-blue-grey-lighten-4"
                        style="max-width: 35px; width: 35px; padding: 5px 1px"
                      >
                        الاثنين
                      </th>
                      <th
                        class="inside-th-cells bg-blue-grey-lighten-4"
                        style="max-width: 35px; width: 35px; padding: 5px 1px"
                      >
                        الثلاثاء
                      </th>
                      <th
                       class="inside-th-cells bg-blue-grey-lighten-4"
                        style="max-width: 35px; width: 35px; padding: 5px 1px"
                      >
                        الاربعاء
                      </th>
                      <th
                    class="inside-th-cells bg-blue-grey-lighten-4"
                        style="max-width: 35px; width: 35px; padding: 5px 1px"
                      >
                        الخميس
                      </th>
                      <th
                       class="inside-th-cells bg-blue-grey-lighten-4"
                        style="max-width: 35px; width: 35px; padding: 5px 1px"
                      >
                        الجمعة
                      </th>
                    </tr>
                  </thead>
                </table>
              </th>
              <th class="text-center  bg-blue-grey-lighten-4">
                <span>
                  {{ $t("summer.report.total-attendance-days") }}
                </span>
              </th>
              <th class="text-center  bg-blue-grey-lighten-4">
                <span>
                {{ $t("summer.report.total-absence-days") }}
                </span>

              </th>
            </thead>
            <tbody>
              <tr v-for="(item, key) in items" :key="item">
                <td
                  class="text-center border bg-blue-grey-lighten-4"
                  v-if="selectedHead.includes('id')"
                >
                  {{ key + 1 }}
                </td>
                <td class="text-center border" v-if="selectedHead.includes('name')">
                  {{ item.employee }}
                </td>
                <td
                  class="text-center border"
                  v-for="week in item.weeks"
                  :key="week"
                  style="padding: 0"
                >
                  <table style="width: 100%; border-collapse: collapse">
                    <thead>
                      <tr style="border-width: 0">
                        <td style="max-width: 35px; width: 35px; padding: 5px 1px">
                          <span
                            style="
                              border-top-width: 0px !important;
                              border-bottom-width: 0px !important;
                            "
                          >
                            <span v-if="getDayData(week.days, 1).day">
                              <v-icon
                                v-if="getDayData(week.days, 1).is_attendance"
                                color="success"
                                >mdi-check-circle</v-icon
                              >
                              <v-icon
                                v-if="getDayData(week.days, 1).is_permission"
                                color="blue"
                                >mdi-minus-circle</v-icon
                              >
                              <v-icon
                                v-if="
                                  getDayData(week.days, 1).is_attendance == false &&
                                  getDayData(week.days, 1).is_permission == false
                                "
                                color="error"
                                >mdi-close-circle</v-icon
                              >
                            </span>
                          </span>
                        </td>
                        <td style="max-width: 35px; width: 35px; padding: 5px 1px">
                          <span
                            style="
                              border-top-width: 0px !important;
                              border-bottom-width: 0px !important;
                            "
                          >
                            <span v-if="getDayData(week.days, 2).day">
                              <v-icon
                                v-if="getDayData(week.days, 2).is_attendance"
                                color="success"
                                >mdi-check-circle</v-icon
                              >
                              <v-icon
                                v-if="getDayData(week.days, 2).is_permission"
                                color="blue"
                                >mdi-minus-circle</v-icon
                              >
                              <v-icon
                                v-if="
                                  getDayData(week.days, 2).is_attendance == false &&
                                  getDayData(week.days, 2).is_permission == false
                                "
                                color="error"
                                >mdi-close-circle</v-icon
                              >
                            </span>
                          </span>
                        </td>
                        <td style="max-width: 35px; width: 35px; padding: 5px 1px">
                          <span
                            style="
                              border-top-width: 0px !important;
                              border-bottom-width: 0px !important;
                            "
                          >
                            <span v-if="getDayData(week.days, 3).day">
                              <v-icon
                                v-if="getDayData(week.days, 3).is_attendance"
                                color="success"
                                >mdi-check-circle</v-icon
                              >
                              <v-icon
                                v-if="getDayData(week.days, 3).is_permission"
                                color="blue"
                                >mdi-minus-circle</v-icon
                              >
                              <v-icon
                                v-if="
                                  getDayData(week.days, 3).is_attendance == false &&
                                  getDayData(week.days, 3).is_permission == false
                                "
                                color="error"
                                >mdi-close-circle</v-icon
                              >
                            </span>
                          </span>
                        </td>
                        <td style="max-width: 35px; width: 35px; padding: 5px 1px">
                          <span
                            style="
                              border-top-width: 0px !important;
                              border-bottom-width: 0px !important;
                            "
                          >
                            <span v-if="getDayData(week.days, 4).day">
                              <v-icon
                                v-if="getDayData(week.days, 4).is_attendance"
                                color="success"
                                >mdi-check-circle</v-icon
                              >
                              <v-icon
                                v-if="getDayData(week.days, 4).is_permission"
                                color="blue"
                                >mdi-minus-circle</v-icon
                              >
                              <v-icon
                                v-if="
                                  getDayData(week.days, 4).is_attendance == false &&
                                  getDayData(week.days, 4).is_permission == false
                                "
                                color="error"
                                >mdi-close-circle</v-icon
                              >
                            </span>
                          </span>
                        </td>
                        <td style="max-width: 35px; width: 35px; padding: 5px 1px">
                          <span
                            style="
                              border-top-width: 0px !important;
                              border-bottom-width: 0px !important;
                            "
                          >
                            <span v-if="getDayData(week.days, 5).day">
                              <v-icon
                                v-if="getDayData(week.days, 5).is_attendance"
                                color="success"
                                >mdi-check-circle</v-icon
                              >
                              <v-icon
                                v-if="getDayData(week.days, 5).is_permission"
                                color="blue"
                                >mdi-minus-circle</v-icon
                              >
                              <v-icon
                                v-if="
                                  getDayData(week.days, 5).is_attendance == false &&
                                  getDayData(week.days, 5).is_permission == false
                                "
                                color="error"
                                >mdi-close-circle</v-icon
                              >
                            </span>
                          </span>
                        </td>
                        <td style="max-width: 35px; width: 35px; padding: 5px 1px">
                          <span
                            style="
                              border-top-width: 0px !important;
                              border-bottom-width: 0px !important;
                            "
                          >
                            <span v-if="getDayData(week.days, 6).day">
                              <v-icon
                                v-if="getDayData(week.days, 6).is_attendance"
                                color="success"
                                >mdi-check-circle</v-icon
                              >
                              <v-icon
                                v-if="getDayData(week.days, 6).is_permission"
                                color="blue"
                                >mdi-minus-circle</v-icon
                              >
                              <v-icon
                                v-if="
                                  getDayData(week.days, 6).is_attendance == false &&
                                  getDayData(week.days, 6).is_permission == false
                                "
                                color="error"
                                >mdi-close-circle</v-icon
                              >
                            </span>
                          </span>
                        </td>
                        <td style="max-width: 35px; width: 35px; padding: 5px 1px">
                          <span
                            style="
                              border-top-width: 0px !important;
                              border-bottom-width: 0px !important;
                            "
                          >
                            <span v-if="getDayData(week.days, 7).day">
                              <v-icon
                                v-if="getDayData(week.days, 7).is_attendance"
                                color="success"
                                >mdi-check-circle</v-icon
                              >
                              <v-icon
                                v-if="getDayData(week.days, 7).is_permission"
                                color="blue"
                                >mdi-minus-circle</v-icon
                              >
                              <v-icon
                                v-if="
                                  getDayData(week.days, 7).is_attendance == false &&
                                  getDayData(week.days, 7).is_permission == false
                                "
                                color="error"
                                >mdi-close-circle</v-icon
                              >
                            </span>
                          </span>
                        </td>
                      </tr>
                    </thead>
                  </table>
                </td>
                <td class="text-center bg-blue-grey-lighten-4" style="width: 80px">
                  {{ item.attendance_count }}
                </td>
                <td class="text-center bg-blue-grey-lighten-4" style="width: 80px">
                  {{ item.absence_count }}
                </td>
              </tr>
            </tbody>
          </table>
        </v-card>
      </div>
    </v-card-text>
  </v-card>
  <Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
  >
  </Alert>
</template>

<script>
import ReportHeader from "../../../components/SummerGlobals/ReportHeader";
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  components: { ReportHeader },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.$store.dispatch("updatePermission", localStorage.getItem("userinfo"));

      await this.getWeeks();
      await this.getYears();

      this.current_year = this.getYearName(localStorage.getItem("current_year"));
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  
  data() {
    return {
      week: [(value) => !!value || this.$t("globals.required_field")],
      current_year: "",
      process_alert: false,
      icon_color: undefined,
      alert_title: undefined,
      alert_message: undefined,
      exist: false,
      items: [],
      loading: false,
      txt_search: "",
      selectedHead: ["id", "name"],
      headers: [
        { title: this.$t("globals.#"), key: "id" },
        { title: this.$t("summer.report.fullname-with-surname"), key: "name" },
      ],

      perPage: 25,
      length: 0,
      totalItems: 0,
      itemsPerPage: [
        { value: 5, text: "5" },
        { value: 10, text: "10" },
        { value: 15, text: "15" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 75, text: "75" },
        { value: 100, text: "100" },
        { value: "", text: this.$t("globals.all") },
      ],
      page: 1,
      pagination: {},
      filter_data: {},
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.User,
      weeks: (state) => state.summer.weeks,
    }),

    ...mapGetters({}),

    selectedHeaders() {
      return this.headers.filter((header) => this.selectedHead.includes(header.key));
    },
  },
  methods: {
    ...mapActions({
      getWeeks: "summer/getWeeks",
    }),
    getDayData(listWeeks, dayNumber) {
      const week = listWeeks.find((element) => element.day == dayNumber);
      return week ? week : "";
    },
    getYears() {
      this.year_data = localStorage.getItem("current_year_name_h");
      this.filter_data.year = localStorage.getItem("current_year");
      return this.year_data;
    },
    checkFilterData() {
      this.getData();
    },
    async printTable() {
      window.print();
    },
    getWeekName(driver_id) {
      if (driver_id) {
        const committees = this.weeks.find((committees) => committees.id === driver_id);
        return committees.week_name;
      }
      return "===========";
    },
    async getData() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.loading = true;
        await this.axios
          .get(`${this.base_url}api/summer/report/employee-attendance-report`, {
            params: {
              search: this.txt_search,
              week: this.filter_data.fk_week,
            },
          })
          .then((response) => {
            this.exist = true;
            this.loading = false;
            if (response.data) {
              this.items = response.data;
            }
          })
          .catch((error) => {
            this.loading = false;
            this.icon = "cancel";
            this.icon_color = "error";
            if (error.message.includes("Network Error")) {
              this.alert_title = this.$t("alert.failure.title");
              this.alert_message = this.$t("alert.failure.connect");
            } else {
              this.alert_title = this.$t("alert.not-found.title");
              this.alert_message = this.$t("alert.not-found.message");
            }
            this.process_alert = true;
            setTimeout(() => {
              this.process_alert = false;
            }, 2000);
          });
      }
    },
  },

  watch: {
    perPage() {
      this.getData();
    },
    page() {
      this.getData(this.page);
    },
  },
};
</script>
<style scoped>
#stable {
  width: 100%;
}

#stable th {
  border: 1px solid #649add;
  background-color: #fff;
}

#stable td {
  border: 1px solid #649add !important;
  background-color: #fff;
}
.search:focus {
  outline: unset;
}
.fontsize {
  font-size: 14px;
}
.borders {
  border: solid #c1c1c1 1px;
  border-radius: 8px;
}

.inside-th-cells {
  border-left-width: 1px !important;
  border-right-width: 1px !important;
  border-bottom-width: 0px !important;
}
@media print {
  #sidebar,
  #header,
  #pagination-bar *,
  #tableHead,
  .tooltip,
  #print-menu {
    display: none !important;
  }
  #lectures-report {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    /* height: 100%; */
    page-break-after: always;
    page-break-inside: auto;
    page-break-before: always;
  }
}
</style>
