<template>
  <VCard class="justify-center">
    <v-card-text>
      <VForm
        ref="form"
        v-if="
          CheckGetPermission('summer_center.change_studentsummer') ||
          CheckGetPermission('summer_center.add_studentsummer')
        "
      >
        <v-locale-provider  :locale="$i18n.locale">
          <VTabs 
            v-model="tab" 
            slider-color="indigo" 
            center-active
            show-arrows
            density="compact" 
            color="blue"
          >
            <VTab v-for="(value, index) in tabs" :key="index" :value="index">
              {{ value }}
            </VTab>
          </VTabs>
        </v-locale-provider>

        <v-window v-model="tab" class="my-6 py-6">
          <v-window-item :value="0">
            <VRow >
              <VCol cols="12" xs="12" sm="12" md="9" lg="9">
                <VRow >
                  <VCol  cols="12" xs="12" sm="12" md="6" lg="6">
                      <VTextField
                        v-model="student.name"
                        prepend-inner-icon="mdi-abjad-arabic"
                        :label="$t('school.student_ar_name')"
                        :placeholder="$t('school.student_ar_placehoder')"
                        density="compact"
                        autofocus
                        ref="studentName"
                        :error-messages="
                          v$.student.name.$errors.map((e) => e.$message)
                        "
                      >
                      </VTextField>
                  </VCol>
                  <VCol  cols="12" xs="12" sm="12" md="6" lg="6">
                    <DatetimePicker  
                      v-model="student.birthdate"
                      :errorMessages="
                        v$.student.birthdate.$errors.map((e) => e.$message)
                      "
                      @input="v$.student.birthdate.$touch"
                      :calendar="'gregorg'"
                      :placeholder="$t('globals.year-month-day')"
                      :label="$t('school.student_birthdate')"
                    ></DatetimePicker>
                  </VCol>
                </VRow>
                <VRow >
                  <VCol  cols="12" xs="12" sm="12" md="6" >
                      <VTextField
                        v-model="student.address"
                        prepend-inner-icon="mdi-map-marker-outline"
                        :label="$t('school.student_address')"
                        density="compact"
                        :error-messages="
                          v$.student.address.$errors.map((e) => e.$message)
                        "
                      >
                      </VTextField>
                  </VCol>
                  
                  <VCol  cols="12" xs="12" sm="12" md="6" >
                      <VRadioGroup
                        inline
                        density="compact"
                        :label="$t('school.student_gender')"
                        v-model="student.gender"
                        :error-messages="
                          v$.student.gender.$errors.map((e) => e.$message)
                        "
                      >
                        <VRadio
                          v-for="item in gender"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id"
                          color="blue"
                        >
                        </VRadio>
                      </VRadioGroup>
                  </VCol>
                </VRow>

                <VRow >
                  <VCol  cols="12" xs="12" sm="12" md="6" lg="6">
                      <VTextField
                        v-model="student.id_card"
                        prepend-inner-icon="mdi-numeric"
                        :label="$t('school.card_id')"
                        density="compact"
                        :error-messages="
                          v$.student.id_card.$errors.map((e) => e.$message)
                        "
                        @input="v$.student.id_card.$touch"
                      >
                      </VTextField>
                  </VCol>
                  

                  <VCol  cols="12" xs="12" sm="12" md="6" lg="6">
                      <VTextField
                        v-model="student.phone_number"
                        prepend-inner-icon="mdi-cellphone-text"
                        :label="$t('globals.phone_number')"
                        density="compact"
                        :error-messages="
                          v$.student.phone_number.$errors.map((e) => e.$message)
                        "
                        @input="v$.student.phone_number.$touch"
                      >
                      </VTextField>
                  </VCol>
                  <VCol  cols="12" xs="12" sm="12" md="6" lg="6">
                      <VTextField
                        v-model="student.home_number"
                        prepend-inner-icon="mdi-phone-classic"
                        :label="$t('globals.home_number')"
                        density="compact"
                        :error-messages="
                          v$.student.home_number.$errors.map((e) => e.$message)
                        "
                        @input="v$.student.home_number.$touch"
                      >
                      </VTextField>
                  </VCol>
                </VRow>
              </VCol>

              <VCol cols="12" xs="12" sm="12" md="3" lg="3">
                <ImageInput
                  :reset="image_reset_student_image"
                  :image="student.student_image"
                  :text="$t('school.student_image')"
                  @ImageSelected="saveStudentImage"
                  @resetRequest="imageResetStudentImage"
                ></ImageInput>
              </VCol>
            </VRow>
          </v-window-item>

          <v-window-item :value="1">
              <VRow >
                <VCol  cols="12" sm="12" md="5" lg="5">
                    <VAutocomplete
                      v-model="student.fk_country"
                      item-value="id"
                      item-title="name_ar"
                      prepend-inner-icon="mdi-flag"
                      :items="countries"
                      :label="$t('globals.country')"
                      persistent-hint
                      density="compact"
                      clearable
                      :error-messages="
                        v$.student.fk_country.$errors.map((e) => e.$message)
                      "
                      @update:modelValue="student.fk_governorate = undefined"
                    ></VAutocomplete>
                </VCol>
                <VCol  cols="12" sm="12"  md="5" lg="5">
                    <VAutocomplete
                      v-model="student.fk_governorate"
                      item-value="id"
                      item-title="name_ar"
                      prepend-inner-icon="mdi-city"
                      :items="getGovernoratesByCountry(student.fk_country)"
                      :label="$t('globals.governorate')"
                      persistent-hint
                      density="compact"
                      clearable
                      :error-messages="
                        v$.student.fk_governorate.$errors.map((e) => e.$message)
                      "
                    ></VAutocomplete>
                </VCol>
                <VCol  cols="12" sm="12"  md="5" lg="5">
                    <VAutocomplete
                      v-model="student.fk_directorate"
                      item-value="id"
                      item-title="name_ar"
                      counter="100"
                      prepend-inner-icon="mdi-map-outline"
                      :items="
                        getDirectorateByGovernorates(student.fk_governorate)
                      "
                      :label="$t('globals.directorate')"
                      persistent-hint
                      density="compact"
                      clearable
                      :error-messages="
                        v$.student.fk_directorate.$errors.map((e) => e.$message)
                      "
                    ></VAutocomplete>
                </VCol>
                <VCol  cols="12" sm="12"  md="5" lg="5">
                    <VTextField
                      v-model="student.birth_place"
                      prepend-inner-icon="mdi-map-marker"
                      :label="$t('summer.solitude')"
                      density="compact"
                      :error-messages="v$.student.birth_place.$errors.map((e) => e.$message)"
                      @input="v$.student.birth_place.$touch"
                  
                    >
                    </VTextField>
                </VCol>
              </VRow>
          </v-window-item>

          <v-window-item :value="2">
            <VRow >
              <VCol  cols="12" xs="12" sm="12" md="9" lg="9">
                <VRow >
                  <VCol  cols="12" xs="12" sm="12" md="6" lg="6">
                      <VAutocomplete
                        :items="levels"
                        v-model="student.class.fk_level"
                        item-title="level_with_phase_name"
                        item-value="id"
                        :label="$t('summer.level.name_ar')"
                        prepend-inner-icon="mdi-stairs-box"
                        persistent-hint
                        density="compact"
                        @update:modelValue="student.class.fk_division = undefined"
                        :error-messages="
                          v$.student.class.fk_level.$errors.map((e) => e.$message)
                        "
                      >
                      </VAutocomplete>
                  </VCol>
                  <VCol  cols="12" xs="12" sm="12" md="6" lg="6">
                      <VAutocomplete
                        :items="getClassDivisions(student.class.fk_level)"
                        v-model="student.class.fk_division"
                        item-title="name"
                        item-value="id"
                        :label="$t('division.name')"
                        prepend-inner-icon="mdi-shape-outline"
                        persistent-hint
                        density="compact"
                        :error-messages="
                          v$.student.class.fk_division.$errors.map(
                            (e) => e.$message
                          )
                        "
                      >
                        <!-- @update:modelValue="checkStudentCountInHall()" -->
                      </VAutocomplete>
                  </VCol>
                </VRow>

                <VRow >
                  <VCol  cols="6" sm="6" md="4" >
                      <VAutocomplete
                        :items="school_student_type"
                        v-model="student.school_type"
                        item-title="name"
                        item-value="id"
                        :label="$t('school.school_type')"
                        prepend-inner-icon="mdi mdi-school"
                        persistent-hint
                        density="compact"
                        :error-messages="
                          v$.student.school_type.$errors.map((e) => e.$message)
                        "
                      >
                      </VAutocomplete>
                  </VCol>
                  <VCol  cols="6" sm="6" md="4" >
                      <VAutocomplete
                        v-model="student.student_type"
                        item-value="id"
                        item-title="name"
                        prepend-inner-icon="mdi mdi-account"
                        :items="student_type"
                        :label="$t('school.students_type')"
                        persistent-hint
                        density="compact"
                        clearable
                        :error-messages="
                          v$.student.student_type.$errors.map((e) => e.$message)
                        "
                      ></VAutocomplete>
                  </VCol>
                  <VCol  cols="12" sm="12" md="4" >
                    <VAutocomplete
                      v-model="student.accommodation_type"
                      item-value="id"
                      item-title="name"
                      prepend-inner-icon="mdi-home"
                      :items="accommodation_types"
                      :label="$t('summer.accommodation-type')"
                      persistent-hint
                      density="compact"
                      clearable
                      :error-messages="v$.student.accommodation_type.$errors.map((e) => e.$message)"
                    ></VAutocomplete>
                  </VCol>
                  <VCol>
                    <VTextField
                        v-model="student.school_name"
                        prepend-inner-icon="mdi-school"
                        :label="$t('globals.school-name')"
                        :placeholder="$t('globals.school-name')"
                        density="compact"
                        :error-messages="
                          v$.student.school_name.$errors.map((e) => e.$message)
                        "
                        :rules="address"
                        counter="150"
                      >
                      </VTextField>
                  </VCol>
                  <VCol  cols="12" sm="12" md="4" >
                    <VAutocomplete
                      v-model="student.schoollevel"
                      item-value="id"
                      item-title="name"
                      prepend-inner-icon="mdi-ladder"
                      :items="school_phases"
                      :label="$t('globals.school-class')"
                      persistent-hint
                      density="compact"
                      clearable
                    ></VAutocomplete>
                  </VCol>
                </VRow>

                <VRow >
                  <VCol
                    cols="12"
                    xs="12"
                    sm="12"
                    md="12"
                    lg="12"
                  >
                      <VTextarea
                        v-model="student.note"
                        clearable
                        :label="$t('globals.note')"
                        prepend-inner-icon="mdi-note"
                        counter="250"
                        no-resize
                        density="compact"
                        rows="2"
                        :error-messages="
                          v$.student.note.$errors.map((e) => e.$message)
                        "
                      ></VTextarea>
                  </VCol>
                </VRow>
              </VCol>
              <VCol  cols="12" xs="12" sm="12" md="3" lg="3" >
                <ImageInput
                  :reset="image_reset_registration_form"
                  :image="student.registration_form"
                  :text="$t('school.student_registration_form')"
                  @ImageSelected="saveRegistrationForm"
                  @resetRequest="imageResetRegistrationForm"
                ></ImageInput>
              </VCol>
            </VRow>
          </v-window-item>
          <v-window-item :value="3">
            <VRow >
              <VCol cols="12" xs="12" sm="12" md="9" lg="9">
                <VRow >
                  <VCol  cols="12" xs="12" sm="12" md="6" lg="6">
                      <VRow class="pa-0 ma-0" >
                        <VAutocomplete
                          v-model="fk_parent"
                          prepend-inner-icon="mdi-human-male-boy"
                          item-value="id"
                          item-title="name"
                          :items="parent_list"
                          :label="$t('school.select_parent_name')"
                          density="compact"
                          :disabled="!selected"
                          @update:modelValue="showSelectedParent(fk_parent)"
                        >
                        </VAutocomplete>
                        <VBtn
                          v-if="selected"
                          density="compact"
                          size="small"
                          icon
                          class="my-2 mx-3 "
                          @click.prevent="newParent()"
                        >
                          <VIcon icon="mdi-plus-thick" color="success"></VIcon>
                        </VBtn>
                        <VBtn
                          v-if="!selected"
                          density="compact"
                          size="small"
                          icon
                          class="my-2 mx-3 "
                          @click.prevent="oldParent()"
                        >
                          <VIcon icon="mdi-minus-thick" color="error"></VIcon>
                        </VBtn>
                      </VRow>
                  </VCol>
                  <VCol  cols="12" xs="12" sm="12" md="6" lg="6">
                      <VTextField
                        v-model="student.parent_relation"
                        prepend-inner-icon="mdi-human-male-boy"
                        :label="$t('school.parent_relation')"
                        :placeholder="$t('school.parent_relation_placeholder')"
                        density="compact"
                        :error-messages="
                          v$.student.parent_relation.$errors.map(
                            (e) => e.$message
                          )
                        "
                      >
                      </VTextField>
                  </VCol>
                  <VCol cols="12" xs="12" sm="12" md="6" lg="6">
                      <VTextField
                        v-model="student.parent.name"
                        prepend-inner-icon="mdi-abjad-arabic"
                        :label="$t('school.student_parent_name_ar')"
                        :placeholder="
                          $t('school.student_parent_name_placeholder')
                        "
                        density="compact"
                        clearable
                        :disabled="selected"
                        :rules="!selected?parent_rule:''"
                        :error-messages="
                          v$.student.parent.name.$errors.map((e) => e.$message)
                        "
                      >
                      </VTextField>
                  </VCol>
                </VRow>
                <VRow class="pt-0 pb-0">
                  <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="4" lg="4">
                    <VCardItem class="pa-0">
                      <VAutocomplete
                        v-model="student.parent.identity_type"
                        prepend-inner-icon="mdi-card-account-details"
                        item-value="id"
                        item-title="name"
                        :items="identify_type"
                        :label="$t('school.parent_id')"
                        persistent-hint
                        density="compact"
                        clearable
                        :disabled="selected"
                        :error-messages="
                          v$.student.parent.identity_type.$errors.map(
                            (e) => e.$message
                          )
                        "
                      ></VAutocomplete>
                    </VCardItem>
                  </VCol>
                  <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="4" lg="4">
                    <VCardItem class="pa-0">
                      <VTextField
                        v-model="student.parent.identity_number"
                        prepend-inner-icon="mdi-numeric"
                        :label="$t('school.parent_id_no')"
                        :placeholder="$t('school.parent_id_no_placeholder')"
                        persistent-hint
                        density="compact"
                        clearable
                        type="number"
                        :disabled="selected"
                        :error-messages="
                          v$.student.parent.identity_number.$errors.map(
                            (e) => e.$message
                          )
                        "
                      ></VTextField>
                    </VCardItem>
                  </VCol>
                  <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="4" lg="4">
                    <VCardItem class="pa-0">
                      <VTextField
                        v-model="student.parent.job"
                        prepend-inner-icon="mdi-account-hard-hat"
                        :label="$t('school.parent_job')"
                        :placeholder="$t('school.parent_job_placeholder')"
                        density="compact"
                        clearable
                        :disabled="selected"
                        :error-messages="
                          v$.student.parent.job.$errors.map((e) => e.$message)
                        "
                      ></VTextField>
                    </VCardItem>
                  </VCol>
                </VRow>
                <VRow class="pt-0 pb-0">
                  <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="4" lg="4">
                    <VCardItem class="pa-0">
                      <VTextField
                        v-model="student.parent.phone_number"
                        prepend-inner-icon="mdi-cellphone-text"
                        :label="$t('school.parent_phone')"
                        :placeholder="$t('school.parent_phone_placeholder')"
                        density="compact"
                        clearable
                        :disabled="selected"
                        :error-messages="
                          v$.student.parent.phone_number.$errors.map(
                            (e) => e.$message
                          )
                        "
                      ></VTextField>
                    </VCardItem>
                  </VCol>
                  <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="4" lg="4">
                    <VCardItem class="pa-0">
                      <VTextField
                        v-model="student.parent.home_number"
                        prepend-inner-icon="mdi-phone-classic"
                        :label="$t('school.parent_landline')"
                        :placeholder="$t('school.parent_landline_placeholder')"
                        density="compact"
                        clearable
                        :disabled="selected"
                        type="number"
                        :error-messages="
                          v$.student.parent.home_number.$errors.map(
                            (e) => e.$message
                          )
                        "
                      ></VTextField>
                    </VCardItem>
                  </VCol>
                  <VCol class="pt-0 px-3" cols="12" xs="12" sm="6" md="4" lg="4">
                    <VCardItem class="pa-0">
                      <VTextField
                        v-model="student.parent.address"
                        prepend-inner-icon="mdi-map-marker"
                        :label="$t('school.parent_address')"
                        :placeholder="$t('school.parent_address_placeholder')"
                        density="compact"
                        clearable
                        :disabled="selected"
                        :error-messages="
                          v$.student.parent.address.$errors.map((e) => e.$message)
                        "
                      ></VTextField>
                    </VCardItem>
                  </VCol>
                </VRow>
                <VRow class="pt-0 pb-0">
                  <VCol
                    cols="12"
                    class="pa-0 ma-0"
                    xs="12"
                    sm="12"
                    md="12"
                    lg="12"
                  >
                    <VCardItem class="px-3">
                      <VTextarea
                        v-model="student.parent.note"
                        prepend-inner-icon="mdi-note"
                        clearable
                        :label="$t('school.notes')"
                        :placeholder="$t('school.notes_placeholder')"
                        :rules="notes_rule"
                        counter="250"
                        no-resize
                        :disabled="selected"
                        density="compact"
                        rows="2"
                        :error-messages="
                          v$.student.parent.note.$errors.map((e) => e.$message)
                        "
                      ></VTextarea>
                    </VCardItem>
                  </VCol>
                </VRow>
              </VCol>
              <VCol cols="12" xs="12" sm="12" md="3" lg="3">
                <v-card :disabled="selected">
                  <ImageInput
                    :reset="image_reset_identity_image"
                    :image="student.parent.identity_image"
                    :text="$t('school.parent_id_image')"
                    @ImageSelected="saveIdImage"
                    @resetRequest="imageResetIdentityImage"
                  ></ImageInput>
                </v-card>
              </VCol>
            </VRow>
          </v-window-item>
        </v-window>
      </VForm>
    </v-card-text>
    <VCardActions
      class="px-3"
      v-if="
        CheckGetPermission('summer_center.change_studentsummer') ||
        CheckGetPermission('summer_center.add_studentsummer')
      "
    >
      <VBtn
        :loading="in_progress"
        v-if="
          !is_update && CheckGetPermission('summer_center.add_studentsummer')
        "
        class="bg-indigo"
        @click="saveStudent"
        size="small"
      >
        <span class="text-white">
          {{ $t("globals.add") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
      <VBtn
        :loading="in_progress"
        v-if="
          is_update && CheckGetPermission('summer_center.change_studentsummer')
        "
        class="bg-success"
        @click="updateStudent"
        size="small"
      >
        <span class="text-white">
          {{ $t("globals.edit") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
      <VBtn class="mx-3" size="small" @click="resetForm">
        {{ $t("globals.clear") }}
        <VIcon icon="mdi-broom" color="golden" end></VIcon>
      </VBtn>
    </VCardActions>
  </VCard>
  <v-dialog v-model="dialog" transition="dialog-top-transition" width="auto">
    <v-card>
      <v-card-title>
        <h3 class="text-warning">{{ $t("globals.warning") }}</h3>
      </v-card-title>
      <v-card-text>
        {{ $t("globals.student_count_exceeded") }}
      </v-card-text>
      <v-card-actions>
        <v-btn color="info" @click="dialog = false">{{
          $t("globals.ok")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
  >
  </Alert>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import DatetimePicker from '@/components/Globals/DatetimePicker.vue'
import ImageInput from "@/components/Globals/ImageInput.vue";
import useValidate from "@vuelidate/core";
import {
  email,
  required,
  helpers,
  maxLength,
  url,
  alpha,
  alphaNum,
  numeric,
  maxValue,
  minLength,
} from "@vuelidate/validators";
const englishOnly = helpers.regex(/^[a-zA-Z\-'\s]+$/);
const mobile_reg = helpers.regex(/^[0-9+]+$/);
const phone_reg = helpers.regex(/^[0-9]+$/);
const arabicOnly = helpers.regex(
  /^[\u0600-\u06ff\u0750-\u077f-\u080a-\u08ff\ufb50-\ufdff\ufe70-\ufeff\s]*$/
);
const nameRegular = helpers.regex(/^[\u0621-\u064A\u0660-\u0669a-zA-Z\s]*$/);

export default {
  props: {
    id: {
      type: Number,
    },
  },
  data() {
    return {
      v$: useValidate(),
      parent_list: [],
      school_phases:[],
      max_date: undefined,
      date_now: undefined,
      selected: true,
      in_progress: false,
      dialog: false,
      required_docs: [],
      documents: [],
      image_reset_identity_image: 0,
      image_reset_student_image: 0,
      image_reset_registration_form: 0,
      fk_parent: undefined,
      student: {
        school_type: undefined,
        student_type: undefined,
        accommodation_type: undefined,
        fk_parent: 0,
        fk_user: 0,
        fk_country: undefined,
        fk_governorate: undefined,
        fk_directorate: undefined,
        school_name:undefined,
        schoollevel:undefined,

        name: undefined,
        gender: undefined,
        nationality: undefined,
        birthdate: undefined,
        birth_place: undefined,
        parent_relation: undefined,
        address: undefined,
        phone_number: undefined,
        id_card: undefined,
        home_number: undefined,
        student_image: undefined,
        registration_form: undefined,
        note: undefined,
        files: undefined,
        parent: {
          fk_user: 0,
          name: "",
          identity_type: undefined,
          identity_number: undefined,
          identity_image: undefined,
          job: undefined,
          address: undefined,
          phone_number: undefined,
          id_card: undefined,
          home_number: undefined,
          note: undefined,
        },
        class: {
          fk_student: 0,
          fk_year: undefined,
          fk_level: undefined,
          fk_division: undefined,
          note: undefined,
        },
        documents: [
          {
            files: undefined,
            document: undefined,
            released_date: undefined,
            issuer: undefined,
          },
        ],
      },
      tab: undefined,
      process_alert: false,
      icon_color: undefined,
      icon: undefined,
      alert_title: undefined,
      alert_message: undefined,
      is_update: false,
     
      // tabs:[
      //     this.$t('school.parent_info'),
      //     this.$t('school.student_info'),
      //     this.$t('school.student_academic_info')
      //     ],
      parent_rule:[
        (value)=>!!value || this.$t('errors.required')
      ]
    };
  },
  components: {
    ImageInput,
    DatetimePicker,
  },
  validations() {
    return {
      student: {
        // fk_parent:{required:helpers.withMessage(this.$t('globals.required_field'),required)},
        school_name:{
          maxLength: helpers.withMessage(
            this.$t("globals.max_characters"),
            maxLength(150)
          ),
        },
        name: {
          required: helpers.withMessage(
            this.$t("globals.required_field"),
            required
          ),
          arabicOnly: helpers.withMessage(
            this.$t("globals.must_be_letters"),
            arabicOnly
          ),
          maxLength: helpers.withMessage(
            this.$t("globals.max_characters"),
            maxLength(100)
          ),
        },
        gender: {
          required: helpers.withMessage(
            this.$t("globals.required_field"),
            required
          ),
        },
        birthdate: {
          required: helpers.withMessage(
            this.$t("globals.required_field"),
            required
          ),
          date: helpers.withMessage(
            this.$t("globals.max_date") + this.max_date,
            this.validators.moreDate(this.max_date)
          ),
        },
        accommodation_type: {
          required: helpers.withMessage(
            this.$t("globals.required_field"),
            required
          ),
        },
        fk_country: {
          required: helpers.withMessage(
            this.$t("globals.required_field"),
            required
          ),
        },
        fk_governorate: {
          required: helpers.withMessage(
            this.$t("globals.required_field"),
            required
          ),
        },
        fk_directorate: {
          required: helpers.withMessage(
            this.$t("globals.required_field"),
            required
          ),
        },
        birth_place: {
          required: helpers.withMessage(
            this.$t("globals.required_field"),
            required
          ),
          maxLength: helpers.withMessage(
            `${this.$t("globals.max_characters")} 100`,
            maxLength(100)
          ),
        },
        student_type: {
          required: helpers.withMessage(
            this.$t("globals.required_field"),
            required
          ),
        },
        school_type: {
          required: helpers.withMessage(
            this.$t("globals.required_field"),
            required
          ),
        },
        parent_relation: {
          // required: helpers.withMessage(
          //   this.$t("globals.required_field"),
          //   required
          // ),
          maxLength: helpers.withMessage(
            this.$t("globals.max_characters"),
            maxLength(100)
          ),
        },
        address: {
          required: helpers.withMessage(
            this.$t("globals.required_field"),
            required
          ),
          maxLength: helpers.withMessage(
            this.$t("globals.max_characters"),
            maxLength(150)
          ),
        },
        phone_number: {
          // required:helpers.withMessage(this.$t('globals.required_field'),required),
          mobile_reg: helpers.withMessage(
            this.$t("errors.numeric"),
            mobile_reg
          ),
          numeric: helpers.withMessage(
            this.$t("globals.must_be_numbers"),
            numeric
          ),
          maxLength: helpers.withMessage(
            this.$t("globals.max_numbers")+" 14",
            maxLength(14)
          ),
          minLength: helpers.withMessage(
            this.$t("globals.lowest_number_of_character") + "9",
            minLength(9)
          ),
        },
        id_card: {
          // required:helpers.withMessage(this.$t('globals.required_field'),required),
          mobile_reg: helpers.withMessage(
            this.$t("errors.numeric"),
            mobile_reg
          ),
          numeric: helpers.withMessage(
            this.$t("globals.must_be_numbers"),
            numeric
          ),
          maxLength: helpers.withMessage(
            this.$t("globals.max_numbers") + "14",
            maxLength(14)
          ),
          minLength: helpers.withMessage(
            this.$t("globals.min_numbers") + "4",
            minLength(4)
          ),
        },
        home_number: {
          numeric: helpers.withMessage(
            this.$t("globals.must_be_numbers"),
            numeric
          ),
          maxLength: helpers.withMessage(
            this.$t("globals.max_numbers") + "8",
            maxLength(8)
          ),
          minLength: helpers.withMessage(
            this.$t("globals.min_numbers") + "6",
            minLength(6)
          ),
        },
        note: {
          maxLength: helpers.withMessage(
            this.$t("globals.max_characters"),
            maxLength(250)
          ),
        },
        parent: {
          name: {
            maxLength: helpers.withMessage(
              this.$t("globals.max_characters"),
              maxLength(100)
            ),
          },
          identity_type: {
            // required: helpers.withMessage(
            //   this.$t("globals.required_field"),
            //   required
            // ),
          },
          identity_number: {
            // required: helpers.withMessage(
            //   this.$t("globals.required_field"),
            //   required
            // ),
            numeric: helpers.withMessage(
              this.$t("globals.must_be_numbers"),
              numeric
            ),
            maxLength: helpers.withMessage(
              this.$t("globals.max_numbers") + " 14 ",
              maxLength(14)
            ),            
            minLength: helpers.withMessage(
              this.$t("globals.lowest_number_of_character") + "4",
              minLength(4)  
            ),
          },
          job: {
            // required: helpers.withMessage(
            //   this.$t("globals.required_field"),
            //   required
            // ),
            maxLength: helpers.withMessage(
              this.$t("globals.max_characters"),
              maxLength(100)
            ),
          },
          address: {
            // required: helpers.withMessage(
            //   this.$t("globals.required_field"),
            //   required
            // ),
            maxLength: helpers.withMessage(
              this.$t("globals.max_characters"),
              maxLength(150)
            ),
          },
          phone_number: {
            // required: helpers.withMessage(
            //   this.$t("globals.required_field"),
            //   required
            // ),
            mobile_reg: helpers.withMessage(
              this.$t("errors.numeric"),
              mobile_reg
            ),
            numeric: helpers.withMessage(
              this.$t("globals.must_be_numbers"),
              numeric
            ),
            maxLength: helpers.withMessage(
              this.$t("globals.max_numbers") + "14",
              maxLength(14)
            ),
            minLength: helpers.withMessage(
              this.$t("globals.min_numbers") + "9",
              minLength(9)
            ),
          },
          id_card: {
            mobile_reg: helpers.withMessage(
              this.$t("errors.numeric"),
              mobile_reg
            ),
            numeric: helpers.withMessage(
              this.$t("globals.must_be_numbers"),
              numeric
            ),
            maxLength: helpers.withMessage(
              this.$t("globals.max_numbers") + "14",
              maxLength(14)
            ),
            minLength: helpers.withMessage(
              this.$t("globals.min_numbers") + "4",
              minLength(4)
            ),
          },
          home_number: {
            numeric: helpers.withMessage(
              this.$t("globals.must_be_numbers"),
              numeric
            ),
            maxLength: helpers.withMessage(
              this.$t("globals.max_numbers") + "8",
              maxLength(8)
            ),
            minLength: helpers.withMessage(
              this.$t("globals.min_numbers") + "6",
              minLength(6)
            ),
          },
          note: {
            maxLength: helpers.withMessage(
              this.$t("globals.max_characters"),
              maxLength(250)
            ),
          },
        },
        class: {
          fk_level: {
            required: helpers.withMessage(
              this.$t("globals.required_field"),
              required
            ),
          },
          fk_division: {
            required: helpers.withMessage(
              this.$t("globals.required_field"),
              required
            ),
          },
          note: {
            maxLength: helpers.withMessage(
              this.$t("globals.max_characters"),
              maxLength(250)
            ),
          },
        },
      },
    };
  },
  async created() {
    try {
      this.$store.commit("updateLoadingValue", true);
      let date = new Date();
      this.max_date = new Date(
        date.getFullYear() - 7,
        date.getMonth(),
        date.getDay()
      ).toISOString().split("T")[0];
      this.date_now = date.toISOString().split("T")[0];
      if (this.divisions.length == 0) this.getDivisions();
      if (this.class_assign_hall.length == 0) this.getClassAssignHall();
      await this.getSchoolStudentType();
      await this.getStudentTypeName();
      await this.getGender();
      await this.getIdentifyType();
      await this.getCountries();
      await this.getGovernorates();
      await this.getDirectorate();
      await this.getLevels();
      await this.getSummerParents()
      await this.getAccommodationType()
      await this.getSchoolPhases()
     
      if (this.id != undefined) {
        this.is_update = true;
        let student = [];
        let is_obj = false;
        await this.axios
          .get(`${this.base_url}api/summer/student/${this.id}/`)
          .then((response) => {
            student = response.data;
            for (const key in this.student) {
              for (const key2 in this.student[key]) {
                this.student[key][key2] = student[key2];
                is_obj = true;
              }
              if (is_obj == true) is_obj = false;
              else this.student[key] = student[key];
            }

            // this.student.documents = student.documents;
            this.student.student_image =
              this.student.student_image?.search(this.base_url) != -1
                ? this.student.student_image
                : this.base_url + this.student.student_image;
            this.student.registration_form =
              this.student.registration_form?.search(this.base_url) != -1
                ? this.student.registration_form
                : this.base_url + this.student.registration_form;
            this.showSelectedParent(student.fk_parent);
            this.getClassDivisions(this.student.class.fk_level);
            this.fk_parent = student.fk_parent ? student.fk_parent : undefined;
            if(this.student.fk_parent == 0){
              this.student.parent.name=undefined
              this.student.parent.address=undefined
              this.student.parent.phone_number=undefined
              this.student.parent.home_number=undefined
            }
          });
      }
      this.student.fk_country = this.countries[0].id
      // this.student.fk_governorate = parseInt(localStorage.getItem('governorate'))
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  computed: {
    ...mapState({
      gender: (state) => state.summer_globals.gender,
      identify_type: (state) => state.summer_globals.identify_type,
      levels: (state) => state.summer.levels,
      divisions: (state) => state.summer.divisions,
      class_assign_hall: (state) => state.summer.class_assign_hall,
      countries: (state) => state.school.countries,
      user: (state) => state.User,
      student_type: (state) => state.summer_globals.study_type,
      school_student_type: (state) => state.summer_globals.school_student_type,
      accommodation_types: (state) => state.summer_globals.accommodation_types,
    }),
    ...mapGetters({
      getGovernoratesByCountry: "school/getGovernoratesByCountry",
      getDirectorateByGovernorates: "school/getDirectorateByGovernorates",
      getClassDivisions: "summer/getClassDivisions",
    }),
    CheckGetPermission() {
      return (prem, role) => {
        return this.$store.getters.checkpermission(prem, role);
      };
    },
    tabs() {
      return [
        this.$t("school.student_info"),
        this.$t("globals.place"),
        this.$t("school.student_academic_info"),
        this.$t("school.parent_info"),
      ];
    },
  },
  methods: {
    async getSchoolPhases(){
      await this.axios(this.base_url + "api/summer/choices/school-phases").then(response=>{
        this.school_phases=response.data
      })
    },
    async getSummerParents(){
      await this.axios(`${this.base_url}api/summer/parent/`, {
        params: {
          page_size: 1000,
        },
      }).then((response) => {
        this.parent_list = response.data.results;
      });
    },
    ...mapActions({
      getSchoolStudentType: "summer_globals/getSchoolStudentType",
      getStudentTypeName: "summer_globals/getStudyType",
      getGender: "summer_globals/getGender",
      getIdentifyType: "summer_globals/getIdentifyType",
      getLevels: "summer/getLevels",
      getDivisions: "summer/getDivisions",
      getClassAssignHall: "summer/getClassAssignHall",
      getCountries: "school/getCountries",
      getGovernorates: "school/getGovernorates",
      getDirectorate: "school/getDirectorate",
      getAccommodationType: "summer_globals/getAccommodationType",
    }),
    addDocument() {
      this.student.documents.push({
        files: undefined,
        document: undefined,
        released_date: undefined,
        issuer: undefined,
      });
    },
    removeDocument() {
      this.student.documents.pop();
    },
    saveIdImage(event) {
      // if(this.selected)
      this.student.parent.identity_image = event;
    },
    imageResetIdentityImage(event) {
      this.image_reset_identity_image = event;
    },
    imageResetStudentImage(event) {
      this.image_reset_student_image = event;
    },
    imageResetRegistrationForm(event) {
      this.image_reset_registration_form = event;
    },
    newParent() {
      this.selected = false;
      this.student.parent = {};
      this.fk_parent = undefined;
      this.student.fk_parent = 0;
    },
    oldParent() {
      this.selected = true;
      this.student.parent = {};
      this.fk_parent = undefined;
    },
    showSelectedParent(id) {
      let parent = this.parent_list.find((item) => item.id == id);
      if (parent != undefined) this.setParent(parent);
      // this.student.parent=parent
    },
    setParent(parent) {
      for (const key in parent) {
        this.student.parent[key] = parent[key];
      }
    },
    resetForm() {
      this.tab=0
      this.v$.student.$reset();
      this.$refs.form.reset();
      this.image_reset++;
      this.image_reset_identity_image++,
      this.image_reset_student_image++,
      this.image_reset_registration_form++,
      this.student.registration_form = undefined;
      this.student.student_image = undefined;
      this.student = {
        parent: {
        },
        class: {
        },
        documents: [
          {
           
          },
        ],
      };
      this.student.parent.identity_image = undefined;
      this.selected = true;
      this.in_progress = false;
      if (this.is_update) this.$router.push({ name: "summer-student-list" });
      this.is_update = false;
      this.$refs.studentName.focus()
    },
    saveRegistrationForm(event) {
      this.student.registration_form = event;
    },
    saveStudentImage(event) {
      this.student.student_image = event;
    },
    async saveStudent() {
      this.v$.$validate();
      const { valid } = await this.$refs.form.validate();
      if (!this.v$.student.$error) {
        this.in_progress = true;
        this.student.class.fk_year = localStorage.getItem("current_year");
        this.student.data_entry = this.user;
        if (this.selected) this.student.fk_parent = this.fk_parent;
        let result = await this.axios
          .post(this.base_url + "/api/summer/student/", this.student, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((responce) => {
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.getSummerParents()
            this.resetForm();
          })
          .catch((error) => {
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
      }
      this.in_progress = false;
    },
    async updateStudent() {
      this.v$.$validate();
      if (!this.v$.student.$error && this.id != undefined) {
        if (this.selected) this.student.fk_parent = this.fk_parent;
        if (typeof this.student.identity_image == "string")
          this.student.identity_image = undefined;
        if (typeof this.student.student_image == "string")
          this.student.student_image = undefined;
        if (typeof this.student.registration_form == "string")
          this.student.registration_form = undefined;
        this.student.class.fk_year = localStorage.getItem("current_year");
        this.student.updated_by = this.user;
        if (this.image_reset_identity_image)
          this.student.identityImageDelete = true;
        else {
          if (this.student.identityImageDelete)
            delete this.student.identityImageDelete;
        }
        if (this.image_reset_student_image)
          this.student.studentImageDelete = true;
        else {
          if (this.student.studentImageDelete)
            delete this.student.studentImageDelete;
        }
        if (this.image_reset_registration_form)
          this.student.registrationFormImageDelete = true;
        else {
          if (this.student.registrationFormImageDelete)
            delete this.student.registrationFormImageDelete;
        }
        this.in_progress=true
        let result = await this.axios
          .put(
            `${this.base_url}/api/summer/student/${this.id}/`,
            this.student,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((responce) => {
            this.$emit("successAlert", this.$t("globals.data_updated"));
            setTimeout(() => {
              this.$router.push({ name: "summer-student-list" });
            }, 2000);
          })
          .catch((error) => {
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
          this.in_progress=false
      }
    },
  },
  watch: {
    id() {
      this.resetForm();
    },
  },
};
</script>
