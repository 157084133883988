<template>
  <v-card class="mb-3 sort-hiden" v-if="!isexit">
    <v-form @submit.prevent>
      <v-expansion-panels model-value="1">
        <v-expansion-panel  value="1">
          <v-expansion-panel-title class="px-4">
            <v-icon icon="mdi-sort-calendar-ascending"></v-icon>
            <span class="mx-2">{{ $t("globals.select-others-criteria") }}</span>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <VRow style="align-items: center">
              <v-col cols="6" md="3" sm="4">
                <v-autocomplete
                  color="indigo"
                  v-model="lessonRecord.fk_week"
                  density="compact"
                  item-title="week_name"
                  item-value="id"
                  prepend-inner-icon="mdi-calendar-week"
                  clearable
                  :items="weeks"
                  :label="$t('summer.calendar.week-name')"
                  @update:model-value="getDateList(), resetForm(1)"
                  :rules="rules"
                />
              </v-col>
              <v-col cols="6" md="3" sm="4">
                <v-autocomplete
                  color="indigo"
                  v-model="lessonRecord.date"
                  density="compact"
                  item-title="week_name"
                  item-value="id"
                  prepend-inner-icon="mdi-calendar-today"
                  clearable
                  :items="listWeeks"
                  :label="$t('summer.calendar.hijri-date')"
                  :rules="rules"
                  @update:model-value="resetForm(0)"
                />
              </v-col>
              <v-col cols="6" md="3" sm="4">
                <v-autocomplete
                  color="indigo"
                  v-model="summer_type_id"
                  density="compact"
                  item-title="type"
                  item-value="id"
                  prepend-inner-icon="mdi-calendar-week"
                  :items="typeList"
                  :label="$t('summer.summer_type')"
                />
              </v-col>
              <VCardActions class="px-3">
                <VBtn
                  ty
                  class="bg-indigo"
                  type="submit"
                  @click="checkRule()"
                  size="small"
                >
                  <span class="text-white">
                    {{ $t("globals.show") }}
                  </span>
                  <VIcon icon="mdi-presentation" color="white" end></VIcon>
                </VBtn>
                <VBtn class="mx-3" size="small" @click="clearweek()">
                  {{ $t("globals.clear") }}
                  <VIcon icon="mdi-broom" color="golden" end></VIcon>
                </VBtn>
              </VCardActions>
            </VRow>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-progress-linear
        color="indigo"
        v-if="loading"
        indeterminate
        height="2px"
        :reverse="$i18n.locale == 'ar'"
      >
      </v-progress-linear>
    </v-form>
  </v-card>
  <v-card id="report" v-if="isexit">
    <ReportHeader class="pb-0">
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          <div class="text-h5">
            {{ $t("reportpublic.TotalStudentsByLevel") }}
          </div>
        </div>
      </template>
    </ReportHeader>

    <v-card-title>
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <div class="d-flex justify-end" style="flex: 1">
           <v-btn
                v-bind="props"
                density="compact"
                class="pa-0 mx-1"
                style="min-width: 25px"
                @click="printTable((all = false))"
              >
                <v-icon color="icon-color">mdi-printer</v-icon>
                <v-tooltip class="tooltip" activator="parent" location="top">
                  <small>{{ $t("globals.print-file") }}</small>
                </v-tooltip>
              </v-btn>
        </div>
        <v-btn density="compact" class="mx-1" @click="isexit = !isexit">
          <span class="text-indigo">{{ $t("globals.back") }}</span>
        </v-btn>
      </div>
      <v-container>
        <v-row align="center">
          <v-col cols="auto" class="px-0">
            <v-sheet class="chart-label-sheet" elevation="0" rounded="0">
              {{ $t("reportpublic.TotalStudentsByLevel") }}
              {{ $t("reportpublic.InDirectorate") }}
              {{
                this.$i18n.locale == "ar"
                  ? results.directorate_name_ar
                  : results.directorate_name_en
              }}
                ( {{typeList[summer_type_id].type}} )
            </v-sheet>
          </v-col>
          <v-col class="pa-0" cols="auto">
            <v-sheet
              :class="`border chart-label-arrow-${this.$i18n.locale} text-center`"
            >
               <h4>{{ " هـ " }} {{ lessonRecord.date }}</h4>
            </v-sheet>
          </v-col>
          <v-col cols="max" align-self="center" class="pa-0">
            <v-divider
              :thickness="1"
              class="border-opacity-100"
              color="black"
            ></v-divider>
          </v-col>
          <v-sheet
            color="black"
            height="6"
            width="6"
            style="border-radius: 50%"
          >
          </v-sheet>
        </v-row>
      </v-container>
    </v-card-title>


    <v-card-item v-if="this.data.length > 0">
      <v-container class="overflow-x-hidden">
        <v-row>
          <table class="report-table text-center">
            <thead>
              <tr>
                <th rowspan="2">{{ $t("reportpublic.CenterName") }}</th>
                <th :colspan="totals.length+1">
                  {{ $t("reportpublic.LevelsName") }}
                </th>
              </tr>
              <tr>
                <th v-for="(val, key) in totals" :key="key">
                  {{
                    this.$i18n.locale == "ar"
                      ? val.level_name_ar
                      : val.level_name_en
                  }}
                </th>
                 <th class="right-footer rounded-be">
                  {{ $t("reportpublic.GrandTotal") }}
                </th>
              </tr>
            </thead>
            <tbody v-for="(val, key) in data" :key="key">
              <td>
                {{
                  this.$i18n.locale == "ar"
                    ? val.school_summer_name_ar
                    : val.school_summer_name_en
                }}
              </td>
              <td v-for="(v, key) in val.dataLevel" :key="key">
                {{ v.count }}
              </td>
              <td>
                {{val.total_for_summer}}
              </td>
            </tbody>
            <tfoot>
              <tr>
                <th class="right-footer rounded-be">
                  {{ $t("reportpublic.GrandTotal") }}
                </th>
                <th class="rounded-bs" v-for="(val, key) in totals" :key="key">
                  {{ val.count }}
                </th>
                <th>
                  {{results.results.all_total}}
                </th>
              </tr>
            </tfoot>
          </table>
        </v-row>
      </v-container>
      <v-row>
        <v-col class="mt-4 mb-4">
          <v-card style="background: #bad7e9; height: 120%">
            <v-container
              style="gap: 0.5em"
              class="d-flex justify-space-evenly flex-wrap flex-shrink-0"
            >
              <div v-for="(val, key) in totals" :key="key">
                <v-card-title
                  class="text-white text-center py-2 pt-3"
                  style="
                    background: linear-gradient(30deg, #c9485b, #4c0027);
                    clip-path: polygon(
                      100% 50%,
                      75% 93.3%,
                      25% 93.3%,
                      0 50%,
                      25% 6.7%,
                      75% 6.7%
                    );
                    width: 120px;
                    height: 50px;
                    aspect-ratio: 1;
                  "
                >
                  {{
                    this.$i18n.locale == "ar"
                      ? val.level_name_ar
                      : val.level_name_en
                  }}</v-card-title
                >
                <v-card-title
                  class="rounded-pill text-center text-white"
                  style="
                    background: linear-gradient(30deg, #c9485b, #4c0027);
                    width: 120px;
                  "
                  >{{ val.count }}
                </v-card-title>
              </div>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-card-item>
      <v-row v-else justify="center" align-content="center" class="pa-4">
          لا يوجد بيانات
        </v-row>
  </v-card>
</template>

<script>
import ReportHeader from "@/components/SummerGlobals/ReportHeader.vue";
;
import { mapState, mapActions } from "vuex";

export default {
  async mounted() {
     try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getWeeks();
      await this.fetchWeek();
      await this.getDateList()

      await this.$store.commit("updateLoadingValue", false);
    } catch (erroe) {
      await this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    ...mapActions({
      getWeeks: "summer/getWeeks",
    }),
    async getDateList() {
      if (this.lessonRecord.fk_week) {
        await this.axios
          .get(this.base_url + "api/summer/get-work-days", {
            params: {
              week_id: this.lessonRecord.fk_week,
            },
          })
          .then((response) => {
            this.listWeeks = response.data.date;
          })
          .catch((error) => {
              
          });
      } else {
        this.resetForm();
      }
    },
    async fetchWeek() {
      await  this.axios
        .get(
             `${this.base_url}statistics/get-current-date`)
        .then((response) => {
          this.lessonRecord.fk_week = response.data.week_id;
          this.lessonRecord.date =  response.data.current_date;
          this.currnet_week_id = response.data.week_id;
          this.currnet_date_id = response.data.current_date;
        })
        .catch((error) => {
            
        });
    },
    async fetchData() {
      await  this.axios
        .get(
           `${this.base_url}statistics/directorate/student-by-levels-directorate`,
          {
            params:{
              day:this.lessonRecord.date,
              type:this.summer_type_id

            }
          }
        )
        .then((response) => {
          this.results = response.data;
          this.data = response.data.results.school_summer;
          this.totals = response.data.results.totals;
          this.isexit = true;
        })
        .catch((error) => {
            
        });
    },
    async printTable(all) {
      if (all) {
        this.txt_search = null;
        this.perPage = this.totalItems;
        await this.fetchData();
      }
      window.print();
    },
    async checkRule() {
      if (this.lessonRecord.date && this.lessonRecord.fk_week) {
        this.fetchData();
      }
    },
    resetForm(resetAll) {
      if (resetAll) {
        this.lessonRecord.date = null;
        this.listWeeks = [];
        this.data = [];
        this.totals = [];
        this.results = [];
        this.size = null;
      } else {
        this.data = [];
        this.totals = [];
        this.results = [];
        this.size = null;
        
      }
    },
     async gettoday() {
      this.fetchData();
    },
    async clearweek(){
      this.listWeeks = [];
      this.data_charts = [];
      this.results = [];
      this.results2 = [];
      this.lessonRecord.date = this.currnet_date_id;
      this.getDateList()
      this.lessonRecord.fk_week = this.currnet_week_id;

      this.summer_type_id=0
    }
  },
  data() {
    return {
      currnet_date_id:null,
      currnet_week_id:null,
      summer_type_id: 0,
      typeList: [
        { id: 0, type: this.$t("globals.all") },
        { id: 1, type: this.$t("reportpublic.Open") },
        // { id: 2, type: this.$t("reportpublic.Close") },
        { id: 2, type: this.$t("reportpublic.Typical") },
      ],
      isexit: false,
      results: [],
      size: null,
      data: [],
      totals: [],
      lessonRecord: {},
      listWeeks: [],
      rules: [(value) => (value ? true : this.$t("globals.required_field"))],
    };
  },
  computed: {
    ...mapState({
      weeks: (state) => state.summer.weeks,
    }),
  },
  components: {
    ReportHeader,
  },
};
</script>
