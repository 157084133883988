<template>

  <VCard  >
    <VForm ref="form" class="" v-if="CheckGetPermission('summer_center.add_activitysummer',['sys_admin','gen_admin'])"
  >
      <VCol class="mt-4 pa-0 " cols="12" xs="12" sm="12" md="10" lg="10">
        <VCardText class="pb-0">
          <VRow >
            <VCol  cols="12" xs="12" sm="12" md="4" lg="4">
              <VCardItem class="pa-0">
                <VTextField
                  v-model="summerActivity.name"
                  prepend-inner-icon="mdi-abjad-arabic"
                  :label="$t('summer.activity.name')"
                  :placeholder="$t('summer.activity.name')"
                  :rules="namelRule"
                  density="compact"
                >
                </VTextField>
              </VCardItem>
            </VCol>
            <VCol  cols="12" xs="12" sm="12" md="4" lg="4">
              <VCardItem class="pa-0">
                <VAutocomplete
                  :items="activityType"
                  v-model="summerActivity.fk_activity_type"
                  item-title="name"
                  item-value="id"
                  :label="$t('summer.activity.type')"
                  persistent-hint
                  prepend-inner-icon="mdi-soccer"
                  density="compact"
                  :rules="activityTypeRule"
                ></VAutocomplete>
              </VCardItem>
            </VCol>
            <VCol  cols="12" xs="12" sm="12" md="4" lg="4">
              <VCardItem class="pa-0">
                <VAutocomplete
                  :items="activity_iterations"
                  v-model="summerActivity.iteration"
                  item-title="name"
                  item-value="id"
                  :label="$t('activity.iteration')"
                  persistent-hint
                  prepend-inner-icon="mdi-refresh"
                  density="compact"
                  :rules="iteration_rule"
                >
                </VAutocomplete>
              </VCardItem>
            </VCol>
          </VRow>
          <VRow>
            <VCol cols="12" xs="12" sm="12" md="6" lg="6">
              <VCardItem class="pa-0">
                <VTextField
                  v-model="summerActivity.goal"
                  prepend-inner-icon="mdi-bullseye-arrow"
                  :label="$t('activity.goal')"
                  :placeholder="$t('activity.goal')"
                  :rules="iteration_rule"
                  density="compact"
                >
                </VTextField>
              </VCardItem>
            </VCol>
            <VCol cols="12" xs="12" sm="12" md="6" lg="6">
              <VCardItem class="pa-0">
                <VTextField
                  v-model="summerActivity.execution"
                  prepend-inner-icon="mdi-clipboard-clock"
                  :label="$t('activity.execution')"
                  :placeholder="$t('activity.execution')"
                  :rules="iteration_rule"
                  density="compact"
                >
                </VTextField>
              </VCardItem>
            </VCol>
          </VRow>
          <VRow>
            <VCol  cols="12" xs="12" sm="12" md="6" lg="6">
              <VCardItem class="pa-0">
                <VTextField
                  v-model="summerActivity.student_number"
                  prepend-inner-icon="mdi-numeric"
                  :label="$t('summer.activity.student_count')"
                  :placeholder="$t('summer.activity.evaluation_placeholder')"
                  density="compact"
                  type="number"
                  clearable
                  :rules="students_count_Rule"
                >
                </VTextField>
              </VCardItem>
            </VCol>
            <VCol  cols="12" xs="12" sm="12" md="6" lg="6">
              <VCardItem class="pa-0">
                <VTextField
                  v-model="summerActivity.evalution"
                  prepend-inner-icon="mdi-star-outline"
                  :label="$t('summer.activity.evaluation_act')"
                  :placeholder="$t('summer.activity.evaluation_placeholder')"
                  density="compact"
                >
                </VTextField>
              </VCardItem>
            </VCol>
          </VRow>
          <VRow>
            <VCol v-if="summerActivity.iteration == 4" cols="12" xs="12" sm="12" md="6" lg="6">
              <VCardItem class="pa-0">
                <VTextField
                  v-model="summerActivity.participating_group"
                  prepend-inner-icon="mdi-account-group"
                  :label="$t('activity.participating-group')"
                  :placeholder="$t('activity.participating-group')"
                  :rules="participating_group"
                  density="compact"
                >
                </VTextField>
              </VCardItem>
            </VCol>
            <VCol v-if="summerActivity.iteration == 4" cols="12" xs="12" sm="12" md="6" lg="6">
              <VCardItem class="pa-0">
                <VTextField
                  v-model="summerActivity.entity_to_coordinate"
                  prepend-inner-icon="mdi-office-building"
                  :label="$t('activity.entity-to-coordinate')"
                  :placeholder="$t('activity.entity-to-coordinate')"
                  density="compact"
                  :rules="entity_to_coordinate"
                >
                </VTextField>
              </VCardItem>
            </VCol>
          </VRow>
          <!-- <VRow >
            <VCol  cols="12" xs="12" sm="12" md="4" lg="4">
              <VCardItem class="pa-0">
                <VAutocomplete
                  :items="activityplans"
                  v-model="summerActivity.fk_plan"
                  item-title="name"
                  prepend-inner-icon="mdi-abjad-arabic"
                  item-value="id"
                  :label="$t('summer.plan_name')"
                  persistent-hint
                  density="compact"
                  clearable
                  @click:clear="clearValue"
                  @update:model-value="getnumers(summerActivity.fk_plan)"
                ></VAutocomplete>
              </VCardItem>
            </VCol>
            <VCol  cols="12" xs="12" sm="12" md="4" lg="4">
              <VCardItem class="pa-0 pb-5 pb-md-0">
                <v-text-field
                  v-model="summerActivity.start_date"
                  density="compact"
                  class="cursor-pointer"
                  :placeholder="$t('globals.from')"
                  hide-details="auto"
                  :rules="startDateRule"
                  prepend-inner-icon="mdi-calendar-clock-outline"
                  id="activity_start_date"
                >
                </v-text-field>
                <Datetime
                  style="cursor-pointer"
                  type="datetime"
                  element="activity_start_date"
                  density="compact"
                  :color="'#0747a6'"
                  @input="(e) => getPermissionTime(e, 1)"
                  :locale="$i18n.locale == 'ar' ? 'ar-sa' : 'en-us'"
                  :placeholder="$t('student-attendance.permission-time')"
                  :calendar="'hijri'"
                >
                </Datetime>
              </VCardItem>
            </VCol>
            <VCol  cols="12" xs="12" sm="12" md="4" lg="4">
              <VCardItem class="pa-0 pb-5 pb-md-0">
                <v-text-field
                  v-model="summerActivity.end_date"
                  density="compact"
                  class="cursor-pointer"
                  :placeholder="$t('globals.to')"
                  hide-details="auto"
                  :rules="endDateRule"
                  prepend-inner-icon="mdi-calendar-clock-outline"
                  id="activity_end_date"
                >
                </v-text-field>
                <Datetime
                  style="cursor-pointer"
                  type="datetime"
                  element="activity_end_date"
                  density="compact"
                  :color="'#0747a6'"
                  @input="(e) => getPermissionTime(e, 2)"
                  :locale="$i18n.locale == 'ar' ? 'ar-sa' : 'en-us'"
                  :placeholder="$t('student-attendance.permission-time')"
                  :calendar="'hijri'"
                >
                </Datetime>
              </VCardItem>
            </VCol>
          </VRow>
          <VRow >
            <VCol  cols="12" xs="12" sm="12" md="4" lg="4">
              <VCardItem class="pa-0">
                <VTextField
                  v-model="summerActivity.location"
                  prepend-inner-icon="mdi-map-marker-outline"
                  :label="$t('summer.activity.location')"
                  :placeholder="$t('summer.activity.location_placeholder')"
                  :rules="locationRule"
                  density="compact"
                >
                </VTextField>
              </VCardItem>
            </VCol>
            <VCol  cols="12" xs="12" sm="12" md="4" lg="4">
              <VCardItem class="pa-0">
                <VAutocomplete
                  :items="employeesList"
                  v-model="summerActivity.fk_employee"
                  item-title="name"
                  prepend-inner-icon="mdi-abjad-arabic"
                  item-value="id"
                  :label="$t('summer.activity.employee_name')"
                  persistent-hint
                  :rules="fkEmployeeRule"
                  density="compact"
                ></VAutocomplete>
              </VCardItem>
            </VCol>
            <VCol  cols="12" xs="12" sm="12" md="4" lg="4">
              <VCardItem class="pa-0">
                <VAutocomplete
                  :items="gender"
                  v-model="summerActivity.gender"
                  item-title="name"
                  item-value="id"
                  :label="$t('summer.activity.gendar')"
                  :rules="gendarRule"
                  persistent-hint
                  prepend-inner-icon="mdi-gender-male-female"
                  density="compact"
                ></VAutocomplete>
              </VCardItem>
            </VCol>
          </VRow> -->
          <VRow >
            <VCol cols="6">
              <VCardItem class="pa-0">
                <VTextarea
                  v-model="summerActivity.school_recommendation"
                  prepend-inner-icon="mdi-thought-bubble"
                  :label="$t('activity.school-recommendation')"
                  :placeholder="$t('activity.school-recommendation')"
                  :rules="text_rule"
                  density="compact"
                  rows="2"
                  counter="1000"
                >
                </VTextarea>
              </VCardItem>
            </VCol>
            <VCol v-if="summerActivity.iteration == 4" cols="6">
              <VCardItem class="pa-0">
                <VTextarea
                  v-model="summerActivity.media_recommendation"
                  prepend-inner-icon="mdi-thought-bubble"
                  :label="$t('activity.media-recommendation')"
                  :placeholder="$t('activity.media-recommendation')"
                  :rules="optional_text_rule"
                  density="compact"
                  rows="2"
                  counter="1000"
                >
                </VTextarea>
              </VCardItem>
            </VCol>
          </VRow>
          <VRow >
            <VCol  cols="12" xs="12" sm="12" md="12" lg="12">
              <VCardItem class="pa-0">
                <VTextarea
                  v-model="summerActivity.note"
                  clearable
                  prepend-inner-icon="mdi-note-outline"
                  :label="$t('globals.note')"
                  counter="250"
                  no-resize
                  :rules="note_rule"
                  density="compact"
                  rows="2"
                ></VTextarea>
              </VCardItem>
            </VCol>
          </VRow>
        </VCardText>
        <!-- <VCardActions class="mx-16"> -->
        <VCardActions class="px-3">
          <VBtn
            v-if="!is_update && CheckGetPermission('summer_center.add_activitysummer',['sys_admin','gen_admin'])"
            class="bg-indigo"
            @click.prevent="saveActivity()"
            size="small"
            :loading="DataLoading"
          >
            <span class="text-white">
              {{ $t("globals.add") }}
            </span>
            <VIcon icon="mdi-content-save" color="white" end></VIcon>
          </VBtn>
          <VBtn
            v-if="is_update && CheckGetPermission('summer_center.change_activitysummer',['sys_admin','gen_admin'])"
            class="bg-success"
            @click.prevent="updateActivity()"
            size="small"
            :loading="DataLoading"
          >
            <span class="text-white">
              {{ $t("globals.edit") }}
            </span>
            <VIcon icon="mdi-pencil-outline" color="white" end></VIcon>
          </VBtn>
          <VBtn class="mx-3" size="small" @click.prevent="resetForm()"
            v-if="CheckGetPermission('summer_center.change_activitysummer',['sys_admin','gen_admin'])"
          >
            {{ $t("globals.clear") }}
            <VIcon icon="mdi-broom" color="golden" end></VIcon>
          </VBtn>
        </VCardActions>
      </VCol>
    </VForm>
  </VCard>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import Datetime from "vue3-datetime-js";
import ImageInput from "@/components/Globals/ImageInput.vue";
export default {
  components: { Datetime },
  props: {
    id: {
      type: Number,
    },
  },
  data() {
    return {
      checkclear:false,
      numers: 0,
      DataLoading: false,
      summerActivity: {},
      is_update: false,
      disable_add: false,
      iteration_rule: [(value) => !!value || this.$t("globals.required_field")],
      activityTypeRule: [
        (value) => !!value || this.$t("globals.required_field"),
      ],
      namelRule: [
        (value) => !!value || this.$t("globals.required_field"),
        (value) =>
          /^[\u0621-\u064A /:()]+$/.test(value) ||
          this.$t("globals.must_be_letters"),
        (value) =>
          (value && value.length <= 100) ||
          `${this.$t("globals.biggest_number_of_character")} 100`,
        (value) =>
          (value && value.length >= 3) ||
          `${this.$t("globals.lowest_number_of_character")} 3`,
      ],
      participating_group:[
        (value) =>
          !value || value.length <= 1000 || `${this.$t("globals.biggest_number_of_character")} 1000`,
      ],
      entity_to_coordinate:[
        (value) =>
          !value || value.length <= 1000 || `${this.$t("globals.biggest_number_of_character")} 1000`,
      ],
      note_rule: [
        (value) =>
          value==undefined || value == null || (value && value.length <= 250) || value.length == 0 ||
          `${this.$t("globals.max_characters")} 250`,
      ],
      text_rule: [
        (value) => !!value || this.$t("globals.required_field"),
        (value) => (value && value.length <= 1000) || `${this.$t("globals.max_characters")} 1000`,
      ],
      optional_text_rule: [
        (value) =>  value==undefined || value == null || value.length == 0 || (value && value.length <= 1000) || `${this.$t("globals.max_characters")} 1000`,
      ],
      fkEmployeeRule: [(value) => !!value || this.$t("globals.required_field")],
      startDateRule: [
        (value) => !!value || this.$t("globals.required_field"),
        (value) =>
          this.summerActivity.end_date == undefined ||
          value <= this.summerActivity.end_date ||
          this.$t("errors.end-date"),
        (value) =>
          this.summerActivity.end_date == undefined ||
          value != this.summerActivity.end_date ||
          this.$t("errors.start-equal-end-date"),
      ],
      endDateRule: [
        (value) => !!value || this.$t("globals.required_field"),
        (value) =>
          this.summerActivity.start_date == undefined ||
          value >= this.summerActivity.start_date ||
          this.$t("errors.start-date"),
        (value) =>
          this.summerActivity.start_date == undefined ||
          value != this.summerActivity.start_date ||
          this.$t("errors.start-equal-end-date"),
      ],
      gendarRule: [(value) => !!value || this.$t("globals.required_field")],
      locationRule: [
        (value) => !!value || this.$t("globals.required_field"),
        (value) =>
          (value && value.length <= 100) ||
          `${this.$t("globals.biggest_number_of_character")} 100`,
        (value) =>
          (value && value.length >= 3) ||
          `${this.$t("globals.lowest_number_of_character")} 3`,
      ],
      evalutionRule: [
        (value) => !!value || this.$t("globals.required_field"),
        (value) =>
          /^[\u0621-\u064A ]+$/.test(value) ||
          this.$t("globals.must_be_letters"),
        (value) =>
          (value && value.length <= 20) ||
          `${this.$t("globals.biggest_number_of_character")} 20`,
        (value) =>
          (value && value.length >= 3) ||
          `${this.$t("globals.lowest_number_of_character")} 3`,
      ],
      students_count_Rule: [
        (value) =>
          value == undefined || value == null || value.length == 0 ||
          parseInt(value) <= 99 ||
          `${this.$t("globals.biggest_number_of_character")} 99`,
        (value) =>
          value == undefined || value == null || value.length == 0 ||
          parseInt(value) >= 1 ||
          `${this.$t("globals.lowest_number_of_character")} 1`,
      ],
    };
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getActivityType();
      await this.getActivityIterations();
      if (this.id) {
        await this.axios
          .get(`${this.base_url}api/summer/activity/${this.id}`)
          .then((response) => {
            this.summerActivity = response.data.results;
            this.is_update = true;
          });
      }
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },

  computed: {
    ...mapState({
      user: (state) => state.User,
      activityType: (state) => state.summer.activityType,
      activity_iterations: (state) => state.summer_globals.activity_iterations,
    }),
    CheckGetPermission() {
      return (prem,role) => {
        return this.$store.getters.checkpermission(prem,role);
      };
    },
    employeesList(){
      let list=[]
      this.employees.forEach(element => {
        list.push({
          id:element.id,
          name:element.name_ar +" / "+element.role_name
        })
      });
      return list
    }
  },
  methods: {
    clearValue() {
      // this.summerActivity.fk_plan = "";
      this.checkclear=true;
  
    },
    // getnumers(num) {
    //   if (this.checkclear) {
    //     this.numers = "";
    //     this.summerActivity.start_date = "";
    //     this.summerActivity.end_date = "";
    //     this.checkclear=false;
    //   } else {

    //     try {
    //     for (let index = 0; index < this.activityplans.length; index++) {
    //       if (num == this.activityplans[index].id) {
    //         this.numers = index;
    //       }

    //       (this.summerActivity.start_date =
    //         this.activityplans[this.numers].start_date),
    //         (this.summerActivity.end_date =
    //           this.activityplans[this.numers].end_date);
    //     }    
    //     } catch (error) {
    //       this.checkclear=false;
    //     }
      
    //   }
    // },
    ...mapActions({
      getActivityType: "summer/getActivityType",
      getActivityIterations: "summer_globals/getActivityIterations",
    }),
    async saveActivity() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.DataLoading = true;
        let result = await this.axios
          .post(this.base_url + "api/summer/activity", this.summerActivity)
          .then((response) => {
            this.DataLoading=false
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.resetForm();
          })
          .catch((error) => {
            this.DataLoading=false
            if (error.response.data.name)
              this.$emit("errorAlert", this.$t("summer.already-visitor"));
            else this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
      }
    },
    async updateActivity() {
      const { valid } = await this.$refs.form.validate();
      if (valid && this.id) {
        this.DataLoading = true;
        let result = await this.axios
          .put(
            this.base_url + `api/summer/activity/${this.id}`)
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_updated"));
            this.$router.push({ name: "activity_list" });
          })
          .catch((error) => {
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
      }
      setTimeout(() => {
        this.DataLoading = false;
      }, 2000);
    },

    getPermissionTime(e, id) {
      let date = e;
      if (this.$i18n.locale == "ar") {
        date = this.toEnglishString(e);
      }
      if (id === 1) {
        this.summerActivity.start_date = date;
      } else {
        this.summerActivity.end_date = date;
      }
    },

    resetForm() {
      this.$refs.form.reset();
      this.summerActivity = {};
      this.is_update=false
    },
  },
};
</script>
<style scoped>

img {
  max-width: 270px;
}
</style>

