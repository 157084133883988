<template>
  <VListGroup
    :value="$t('report.directorate')"
    v-if="checkRole(['dir_admin'])"
    :fluid="false"
    class="border-bottom"
  >
    <template v-slot:activator="{ props }">
      <VListItem v-bind="props" min-height="40">
        <template v-slot:title>
          <span class="v-select-title">{{ $t("report.directorate") }}</span>
        </template>

        <template v-slot:prepend>
          <v-tooltip :text="$t('report.directorate')">
            <template v-slot:activator="{ props }">
              <v-icon v-show="expand == true" v-bind="props">
                mdi-chart-pie
              </v-icon>
              <v-icon v-show="expand == false"> mdi-chart-pie </v-icon>
            </template>
          </v-tooltip>
        </template>
      </VListItem>
    </template>
    <VListItem
      :to="{ name: 'school-report-dir' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.SchoolReport')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.SchoolReport")
        }}</span>
      </template>
    </VListItem>
    <VListItem
      :to="{ name: 'users-report-dir' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.UsersReport')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.UsersReport")
        }}</span>
      </template>
    </VListItem>
      <VListItem
      :to="{ name: 'summer-center-number-student-employees' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.the_total_number_student_employees')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.the_total_number_student_employees")
        }}</span>
      </template>
    </VListItem>
    <VListItem
      :to="{ name: 'summer-center-student-data-report' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.summer_center_student_data')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.summer_center_student_data")
        }}</span>
      </template>
    </VListItem>
    <VListItem
      :to="{ name: 'talented_students' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('summer-sidebar.talented-students')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("summer-sidebar.talented-students")
        }}</span>
      </template>
    </VListItem>
    <VListItem
      :to="{ name: 'dropout_students' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('summer-sidebar.dropout-students')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("summer-sidebar.dropout-students")
        }}</span>
      </template>
    </VListItem>
     
     <VListItem
      :to="{ name: 'summer-center-employees-data-report' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.summer_center_employee_data')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.summer_center_employee_data")
        }}</span>
      </template>
    </VListItem>
    
    <VListItem
      :to="{ name: 'courses-for-each-type' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.number-positions-each-TOC-typical')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.number-positions-each-TOC-typical")
        }}</span>
      </template>
    </VListItem>
    <VListItem
      :to="{ name: 'activities-directorates' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.ActivitiesInTheDirectorate')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.ActivitiesInTheDirectorate")
        }}</span>
      </template>
    </VListItem>
    <VListItem
      :to="{ name: 'statistics-by-schools-type-student-type' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.statistics-types-tenters-students-type')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.statistics-types-tenters-students-type")
        }}</span>
      </template>
    </VListItem>
    <VListItem
      :to="{ name: 'overall-summary-for-opened-courses' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.SummaryTotalOpenPositionMF')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle"
          >{{ this.$t("reportpublic.SummaryTotalOpenPositionMF") }}
        </span>
      </template>
    </VListItem>
    <!-- <VListItem
      :to="{ name: 'overall-summary-for-closed-courses' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.SummaryTotalClosePositionMF')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.SummaryTotalClosePositionMF")
        }}</span>
      </template>
    </VListItem> -->
    <VListItem
      :to="{ name: 'overall-summary-for-typical-courses' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.SummaryTotalTypicalPositionMF')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.SummaryTotalTypicalPositionMF")
        }}</span>
      </template>
    </VListItem>
    <VListItem
      :to="{ name: 'student-by-levels' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.TotalStudentsByLevelForCenters')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.TotalStudentsByLevelForCenters")
        }}</span>
      </template>
    </VListItem>
    <!-- <VListItem
      :to="{ name: 'summer-center-statistics' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.StatisticsOfAllCenters')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.StatisticsOfAllCenters")
        }}</span>
      </template>
    </VListItem> -->
    <VListItem
      :to="{ name: 'workers-summer' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.WorkerStatisticsForEachCenter')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.WorkerStatisticsForEachCenter")
        }}</span>
      </template>
    </VListItem>
    <VListItem
      :to="{ name: 'students-summer' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.StudentStatisticsForAllCenters')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.StudentStatisticsForAllCenters")
        }}</span>
      </template>
    </VListItem>
    <VListItem
      :to="{ name: 'total-number-of-activities-carried-out-during-day' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="
        this.$t('reportpublic.TotalNumberOfActivitiesCarriedOut')
      "
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.TotalNumberOfActivitiesCarriedOut")
        }}</span>
      </template>
    </VListItem>
    <VListItem
      :to="{ name: 'visitsCarriedOutDuringDayDirectorate' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.VisitsCarriedOutDuringTheDay')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.VisitsCarriedOutDuringTheDay")
        }}</span>
      </template>
    </VListItem>
  </VListGroup>
  <VListGroup
    :value="$t('report.daily')"
    v-if="checkRole(['dir_admin'])"
    :fluid="false"
  >
    <template v-slot:activator="{ props }">
      <VListItem v-bind="props" min-height="40">
        <template v-slot:title>
          <span class="v-select-title">{{ $t("report.daily") }}</span>
        </template>

        <template v-slot:prepend>
          <v-tooltip :text="$t('globals.general-initialize')">
            <template v-slot:activator="{ props }">
              <v-icon v-show="expand == true" v-bind="props">
                mdi-chart-pie
              </v-icon>
              <v-icon v-show="expand == false"> mdi-chart-pie </v-icon>
            </template>
          </v-tooltip>
        </template>
      </VListItem>
    </template>
      <!-- <VListItem
      :to="{ name: 'dialy-summer-operations-report' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.daily_summer_operations_report')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.daily_summer_operations_report")
        }}</span>
      </template>
    </VListItem> -->
      <VListItem
        :to="{ name: 'school_summer_attendance' }"
        :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
        :value="this.$t('summer.report.school-summer-attendance')"
      >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("summer.report.school-summer-attendance")
        }}</span>
      </template>
    </VListItem>
    <VListItem
        :to="{ name: 'student-employee-present-actual' }"
        :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
        :value="this.$t('reportpublic.data-student-employee-present-actual')"
      >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.data-student-employee-present-actual")
        }}</span>
      </template>
    </VListItem>
    
    <!-- <VListItem
      :to="{ name: 'CoursesForEachTypePublicStatistics' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.number-positions-each-TOC-typical') + 'asdas d'"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.number-positions-each-TOC-typical")
        }}</span>
      </template>
    </VListItem> -->
    <!-- <VListItem
      :to="{ name: 'ActivitiesStatistics' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.ActivitiesInTheDirectorate2')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.ActivitiesInTheDirectorate2")
        }}</span>
      </template>
    </VListItem>  -->
    <!-- <VListItem
      :to="{ name: 'StudentByLevelsStatistics' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.TotalStudentsByLevelForCenters2')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.TotalStudentsByLevelForCenters2")
        }}</span>
      </template>
    </VListItem>  -->
    <!-- <VListItem
      :to="{ name: 'TotalNumberOfActivitiesCarriedOutStatistics' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="
        this.$t('reportpublic.SummerTotalActivitiesCarriedOutDuringDayHeader2')
      "
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t(
            "reportpublic.SummerTotalActivitiesCarriedOutDuringDayHeader2"
          )
        }}</span>
      </template>
    </VListItem>   -->
    <VListItem
      :to="{ name: 'VisitorsDirectoratesStatistics' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.VisitsCarriedOutDuringTheDay2')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.VisitsCarriedOutDuringTheDay2")
        }}</span>
      </template>
    </VListItem> 
 
  </VListGroup>
</template>

<script>
export default {
  props: {
    show: Boolean,
    expand: Boolean,
    direction: String,
    CheckGetPermission: {
      type: Function,
      required: true,
    },
  },
  computed: {
    checkRole() {
      return (role) => {
        if (localStorage.getItem("userinfo"))
          return this.$store.getters.checkrole(role);
      };
    },
  },
};
</script>
<style scoped>
.prtl-5 {
  padding-right: 5px !important;
}
.prtl-10 {
  padding-right: 10px !important;
}
.prtl-15 {
  padding-right: 15px !important;
}
.prtl-20 {
  padding-right: 20px !important;
}
.prtl-25 {
  padding-right: 25px !important;
}
.prtl-30 {
  padding-right: 30px !important;
}
.prtl-40 {
  padding-right: 40px !important;
}
.prtl-45 {
  padding-right: 45px !important;
}
.prtl-50 {
  padding-right: 52px !important;
}
.prtl-60 {
  padding-right: 62px !important;
}
.pltr-5 {
  padding-left: 5px !important;
}
.pltr-10 {
  padding-left: 10px !important;
}
.pltr-15 {
  padding-left: 15px !important;
}
.pltr-20 {
  padding-left: 20px !important;
}
.pltr-25 {
  padding-left: 25px !important;
}
.pltr-30 {
  padding-left: 30px !important;
}
.pltr-40 {
  padding-left: 40px !important;
}
.pltr-45 {
  padding-left: 45px !important;
}
.pltr-50 {
  padding-left: 52px !important;
}
.pltr-60 {
  padding-left: 62px !important;
}
</style>
