<template>
  <VListGroup
    :value="$t('report.other-statistics')"
    v-if="checkRole(['gen_admin'])"
    :fluid="false"
    class="border-bottom"
  >
    <template v-slot:activator="{ props }">
      <VListItem v-bind="props" min-height="40">
        <template v-slot:title>
          <span class="v-select-title">{{ $t("report.other-statistics") }}</span>
        </template>

        <template v-slot:prepend>
          <v-tooltip :text="$t('report.other-statistics')">
            <template v-slot:activator="{ props }">
              <v-icon v-show="expand == true" v-bind="props">
                mdi-chart-pie
              </v-icon>
              <v-icon v-show="expand == false"> mdi-chart-pie </v-icon>
            </template>
          </v-tooltip>
        </template>
      </VListItem>
    </template>
    
    <VListItem
      :to="{ name: 'workingStaff' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.WorkingStaff')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.WorkingStaff")
        }}</span>
      </template>
    </VListItem>
    
    <VListItem
      :to="{ name: 'statisticsTypeMaleFemaleTeacher' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.StatisticsTypeMaleFemaleSchools')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.StatisticsTypeMaleFemaleSchools")
        }}</span>
      </template>
    </VListItem>
    <VListItem
      :to="{ name: 'numberStudentsAccordingLevels' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.NumberOfStudentsByLevels')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.NumberOfStudentsByLevels")
        }}</span>
      </template>
    </VListItem>
    <!-- <VListItem
      :to="{ name: 'visitsCarriedOutDuringDay' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.VisitsCarriedOutDuringTheDay')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.VisitsCarriedOutDuringTheDay")
        }}</span>
      </template>
    </VListItem>
    <VListItem
      :to="{ name: 'summer-total-activities-carried-out' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.SummerTotalActivitiesCarriedOutHeader')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.SummerTotalActivitiesCarriedOutHeader")
        }}</span>
      </template>
    </VListItem> -->
    <VListItem
      :to="{ name: 'summer-work-progress' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.SummerWorkProgress')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.SummerWorkProgress")
        }}</span>
      </template>
    </VListItem>
    <VListItem
      :to="{ name: 'governorate-statistic-by-school-type' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.SummerStatisticsBySchoolType')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.SummerStatisticsBySchoolType")
        }}</span>
      </template>
    </VListItem>
    <VListItem
      :to="{ name: 'visits_carried_out' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.visits-carried-out')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.visits-carried-out")
        }}</span>
      </template>
    </VListItem>
  </VListGroup>
</template>

<script>
export default {
  props: {
    show: Boolean,
    expand: Boolean,
    direction: String,
    CheckGetPermission: {
      type: Function,
      required: true,
    },
  },
  computed: {
    checkRole() {
      return (role) => {
        if (localStorage.getItem("userinfo"))
          return this.$store.getters.checkrole(role);
      };
    },
  },
};
</script>
<style scoped>
.prtl-5 {
  padding-right: 5px !important;
}
.prtl-10 {
  padding-right: 10px !important;
}
.prtl-15 {
  padding-right: 15px !important;
}
.prtl-20 {
  padding-right: 20px !important;
}
.prtl-25 {
  padding-right: 25px !important;
}
.prtl-30 {
  padding-right: 30px !important;
}
.prtl-40 {
  padding-right: 40px !important;
}
.prtl-45 {
  padding-right: 45px !important;
}
.prtl-50 {
  padding-right: 52px !important;
}
.prtl-60 {
  padding-right: 62px !important;
}
.pltr-5 {
  padding-left: 5px !important;
}
.pltr-10 {
  padding-left: 10px !important;
}
.pltr-15 {
  padding-left: 15px !important;
}
.pltr-20 {
  padding-left: 20px !important;
}
.pltr-25 {
  padding-left: 25px !important;
}
.pltr-30 {
  padding-left: 30px !important;
}
.pltr-40 {
  padding-left: 40px !important;
}
.pltr-45 {
  padding-left: 45px !important;
}
.pltr-50 {
  padding-left: 52px !important;
}
.pltr-60 {
  padding-left: 62px !important;
}
</style>
