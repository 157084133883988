<template>
  <VCard class="justify-center">
    <VForm
      ref="form"
      v-if="
        CheckGetPermission('summer_center.change_approach',['sys_admin','summer_admin','user']) ||
        CheckGetPermission('summer_center.add_approach',['sys_admin','summer_admin','user'])
      "
    >
      <VCard-title class="my-4">
        <!-- <VIcon icon="mdi-chart-line" />
        <h3 class="d-inline text-grey" style="padding: 0 4px">
          {{ $t("summer.add-curriculum-statistics") }}
        </h3>
        <v-divider style="border-width: 1px; opacity: 0.5" class="my-2" /> -->
      </VCard-title>
      <VRow class="pt-0 px-lg-4 pb-0">
        <VCol class="pt-0 px-3" xs="12" sm="12" md="5" lg="5">
          <VCardItem class="pa-0">
            <VAutocomplete
              :items="levels"
              v-model="subjects.fk_level"
              prepend-inner-icon="mdi-stairs-box"
              item-title="level_with_phase_name"
              item-value="id"
              :label="$t('summer.level.name_ar')"
              persistent-hint
              density="compact"
              :rules="rules.required"
            ></VAutocomplete>
          </VCardItem>
        </VCol>
        <VCol class="pt-0 px-lg-16 px-3" cols="12" xs="12" sm="12" md="5" lg="5">
          <VCardItem class="pa-0">
            <VTextField
            v-model="subjects.received_books"
            prepend-inner-icon="mdi-numeric"
            :label="$t('summer.recived-books')"
            :placeholder="$t('summer.recived-books')"
            persistent-hint
            density="compact"
            clearable
            type="number"
            no-resize
            counter
            :rules="rules.received_books"          
          ></VTextField>
          </VCardItem>
        </VCol>
      </VRow>
      <VRow class="pt-0 px-lg-4 pb-0">
        <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="5" lg="5">
          <VCardItem class="pa-0">
            <VAutocomplete
              :items="weeks"
              v-model="subjects.fk_week"
              prepend-inner-icon="mdi-calendar-week"
              item-title="week_name"
              item-value="id"
              :label="$t('summer.calendar.week-name')"
              persistent-hint
              density="compact"
              :rules="rules.required"
            ></VAutocomplete>
          </VCardItem>
        </VCol>
      </VRow>
      <VRow class="pt-0 px-lg-4 pb-0">
        <VCol cols="12" class="pa-0 ma-0" xs="12" sm="12" md="10" lg="10">
          <VCardItem class="px-3">
            <VTextarea
              v-model="subjects.note"
              clearable
              :label="$t('globals.note')"
              counter
              no-resize
              density="compact"
              rows="2"
              prepend-inner-icon="mdi-note-outline"
              :rules="rules.note"
            >
            </VTextarea>
          </VCardItem>
        </VCol>
      </VRow>
    </VForm>
    <VCardActions
      v-if="
        CheckGetPermission('summer_center.change_approach',['sys_admin','summer_admin','user']) ||
        CheckGetPermission('summer_center.add_approach',['sys_admin','summer_admin','user'])
      "
      class="mt-4 mx-2 d-flex flex-wrap"
      elevation="4"
    >
      <VBtn
        v-if="!is_update && CheckGetPermission('summer_center.add_approach',['sys_admin','summer_admin','user'])"
        class="bg-indigo"
        @click="saveEstimation"
        size="small"
        :loading="btn_loading"
      >
        <span class="text-white">
          {{ $t("globals.add") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
      <VBtn
        v-if="is_update && CheckGetPermission('summer_center.change_approach',['sys_admin','summer_admin','user'])"
        class="bg-success"
        @click="updateEstimation"
        size="small"
        :loading="loading"
      >
        <span class="text-white">
          {{ $t("globals.edit") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
      <VBtn class="mx-3" size="small" @click="resetForm">
        {{ $t("globals.clear") }}
        <VIcon icon="mdi-broom" color="golden" end></VIcon>
      </VBtn>
    </VCardActions>
    <!-- ##########    data Table  ########### -->
    <v-divider style="border-width: -1px; opacity: 0.25" class="my-10" />
    <!-- ######## Header Of Data Table  ####### -->
    <v-card class="pa-2">
      <div class="d-flex justify-space-between pa-2">
        <div class="d-flex flex-fill">
          <v-menu :close-on-content-click="false">
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                variant="outlined"
                color="#ccc"
                size="small"
              >
                <v-icon style="color: #5e7e96" class="me-2"
                  >mdi-eye-outline</v-icon
                >
                <v-icon style="color: #5e7e96">mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-text>
                <v-checkbox
                  v-for="header in headers"
                  :key="header.key"
                  :label="header.title"
                  :value="header.key"
                  color="indigo"
                  v-model="selectedHead"
                  density="compact"
                  hide-details
                >
                </v-checkbox>
              </v-card-text>
            </v-card>
          </v-menu>

          <div style="width: 100%">
            <v-text-field
              color="indigo"
              v-model="txt_search"
              style="
                border-radius: 30px;
                border: 1px solid #ccc;
                color: black;
                height: 30px;
              "
              class="search"
              hide-details
              prepend-inner-icon="mdi-magnify"
              single-line
              :placeholder="$t('globals.search-here')"
              density="compact"
              variant="text"
              @input="getapproach((page = 1))"
            >
            </v-text-field>
          </div>
        </div>
      </div>
      <!-- ######## End Header Of Data Table Server ####### -->
      <v-data-table-server
        :headers="selectedHeaders"
        :items="tableList"
        fixed-footer
        :search="txt_search"
        density="compact"
        class="elevation-1"
        v-model:sort-by="sortBy"
    
      >
        <template v-slot:column.checkbox="{ column }">
          <v-checkbox hide-details density="compact"> </v-checkbox>
        </template>
        <template v-slot:item="{ item }">
          <tr class="row-hover">
            <td v-for="(val, key) in item.columns" :key="key">
              <v-checkbox
                v-if="key == 'checkbox'"
                hide-details
                density="compact"
              >
              </v-checkbox>
              <span> {{ val }}</span>
              <span v-if="key === 'actions'">
                <v-btn
                  variant="text"
                  class="me-1 v-btn-icon"
                  size="small"
                  v-if="CheckGetPermission('summer_center.change_approach',['sys_admin','summer_admin','user'])"
                  @click="editEstimation(item.raw)">
                  <v-icon color="warning">mdi-pencil-outline</v-icon>
                </v-btn>

                <v-btn
                  variant="text"
                  class="me-1 v-btn-icon"
                  size="small"
                  v-if="CheckGetPermission('summer_center.delete_approach',['sys_admin','summer_admin','user'])"
                  @click="(estimate_del_id = item.raw.id), (del_dialog = true)">
                  <v-icon color="error">mdi-trash-can-outline</v-icon>
                </v-btn>
              </span>
            </td>
          </tr>
        </template>
        <template v-slot:bottom>
          <div class="d-flex" id="pagination-bar">
            <v-pagination
              v-model="page"
              :length="pagination.num_pages"
              density="compact"
              show-first-last-page
            >
            </v-pagination>
            <div class="d-flex">
              <v-select
                v-model="perPage"
                class="pa-0"
                :items="itemsPerPage"
                item-value="value"
                item-title="text"
                density="compact"
                hide-details
                variant="text"
              >
              </v-select>
              <span class="mt-2 px-2"
                >{{ $t("globals.per-page") }} {{ $t("globals.from") }} [
                {{ pagination.count }} ] {{ $t("globals.item") }}</span
              >
            </div>
          </div>
        </template>  
  
      </v-data-table-server>
    </v-card>
    <!-- ############### Dialog Delete  ################### -->
    <delete-dialog
      v-model="del_dialog"
      :loading="del_loading"
      @confirm-delete="deleteEstimation()"
    />
  </VCard>
  
</template>
<script>
import { mapState, mapActions } from "vuex";
import useValidate from "@vuelidate/core";
import {
  email,
  required,
  helpers,
  maxLength,
  url,
  alpha,
  alphaNum,
  numeric,
  integer,
  maxValue,
  minLength,
} from "@vuelidate/validators";
export default {
  data() {
    return {
      del_loading:false,
      loading:false,
      btn_loading:false,
      rules: {
        required: [(value) => !!value || this.$t("globals.required_field")],
        received_books: [
          (value) =>
            value == null || 
            parseInt(value) <= 1000 ||
            `${this.$t("globals.biggest_number_of_character")} 1000`,
            (value) => !!value || this.$t("globals.required_field"),

        ],
        note: [
          (value) =>
            value == null ||
            value.length <= 250 ||
            `${this.$t("globals.biggest_number_of_character")} 250`,
        ],
      },
      sortBy: [{ key: "id", order: "asc" }],
      approachs: [],
      v$: useValidate(),
      subjects: {
        name_ar: undefined,
        name_en: undefined,
        degree_max: undefined,
        degree_min: undefined,

        note: undefined,
        data_entry: undefined,
        updated_by: undefined,
        id: undefined,
      },
      levelsType: [
        {
          name: "اجتماعي",
          id: 1,
        },
        {
          name: "ثقافي",
          id: 2,
        },
      ],
      is_update: false,
      del_dialog: false,
      estimate_del_id: undefined,
      
      icon_color: undefined,
      icon: undefined,
      length:0,
      alert_title: undefined,
      alert_message: undefined,
      headers: [
        { title: this.$t("summer.level.name_ar"), key: "level_name" },
        { title: this.$t("summer.calendar.week-name"), key: "week_data_name" },
        { title: this.$t("summer.recived-books"), key: "received_books" },
        { title: this.$t("globals.note"), key: "note" , sortable: false },
        { title: this.$t("globals.actions"), key: "actions", sortable: false },
      ],
      selectedHead: [
        "level_name",
        "week_data_name",
        "received_books",
        "note",
        "actions",
      ],
      txt_search: undefined,      
      perPage: 10,
      length: 0,
      totalItems: 0,
      itemsPerPage: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: "", text: this.$t("globals.all") },
      ],
      page: 1,
      totalItems: null,
      pagination: {},
      sortBy: [
        {
          key: "id",
          
          order: "asc",
        },
      ],
      name_ar_rule: [(value) => !!value || this.$t("globals.required_field")],
      name_en_rule: [(value) => !!value || this.$t("globals.required_field")],
    };
  },
  validations() {
    return {
      subjects: {
        name_ar: {
          required: helpers.withMessage(
            this.$t("globals.required_field"),
            required
          ),
        },
        name_en: {
          required: helpers.withMessage(
            this.$t("globals.required_field"),
            required
          ),
        },
        note: {
          maxLength: helpers.withMessage(
            this.$t("globals.max_characters"),
            maxLength(250)
          ),
        },
      },
    };
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getLevels();
      await this.getweeks();
      await this.getapproach()
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  computed: {
     order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
    ...mapState({
      user: (state) => state.User,
      levels: (state) => state.summer.levels,
      weeks: (state) => state.summer.weeks,
    }),

    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },

    tableList() {
      if (this.approachs) {
        return JSON.parse(JSON.stringify(this.approachs));
      }
    },
    CheckGetPermission() {
      return (prem,role) => {
        return this.$store.getters.checkpermission(prem,role);
      };
    },
  },
  methods: {
    ...mapActions({
      getLevels: "summer/getLevels",
      getweeks: "summer/getweeks",
    }),
    resetForm() {
      this.$refs.form.reset();
      this.v$.subjects.$reset();
      this.is_update = false;
      this.subjects.id = undefined;
      this.updated_id = undefined;
    },
    editEstimation(data) {
      let estimates = this.approachs.find((item) => item.id == data.id);

      if (estimates != undefined) {
        for (const key in estimates) this.subjects[key] = estimates[key];
        this.subjects.data_entry = undefined;
        this.subjects.updated_by = undefined;
        this.is_update = true;
      }
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    async saveEstimation() {
      const { valid } = await this.$refs.form.validate();
         
      if (valid) {
        this.btn_loading=true;
        this.subjects.data_entry = this.user;

        let result = await this.axios
          .post(this.base_url + "api/summer/approach-view", this.subjects)
          .then((response) => {
            this.btn_loading=false;
            this.$emit('successAlert',this.$t('globals.data_added'))
            this.resetForm();
            
          })
          .catch((error) => {
             this.btn_loading=false;
             this.$emit('errorAlert',this.$t('globals.error_in_data'))
          });
       
        this.getLevels();
        this.getweeks();
        this.getapproach();
        setTimeout(() => {
          
        }, 1500);
      }
    },
    async getapproach(page=1) {
      this.loading = true;
      await this.axios(`${this.base_url}/api/summer/approach-view`, {
        
        params: {
          search: this.txt_search,
          page: page,
          page_size: this.perPage,
          sort_by: this.order_data,
        },
      }).then((response) => {
        this.approachs = response.data.results;
        

          this.pagination = response.data.pagination;
          this.length = response.data.pagination.num_pages;
          this.totalItems = response.data.pagination.count;
     
        this.loading = false;
      });
    },

    async updateEstimation() {
      this.v$.$validate();
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.loading=true;
        this.subjects.updated_by = this.user;
        let result = await this.axios
          .put(`${this.base_url}/api/summer/approach-view/${this.subjects.id}`,this.subjects)
          .then((responce) => {
            this.loading=false;
            this.$emit('successAlert',this.$t('globals.data_updated'))
           
          })
          .catch((error) => {
            this.loading=false;
            this.$emit('errorAlert',this.$t('globals.error_in_data'))
          });
       
        this.resetForm();
        this.getLevels();
        this.getweeks();
        this.getapproach();
        setTimeout(() => {
          
        }, 1500);
      }
    },
    async deleteEstimation() {
      this.del_loading=true;
      if (this.estimate_del_id != undefined) {
        let result = await this.axios
          .delete(
            `${this.base_url}/api/summer/approach-view/${this.estimate_del_id}`)
          .then((responce) => {
            this.del_loading=false;
            this.$emit('successAlert',this.$t('globals.data_deleted'))

         
          })
          .catch((error) => {
            this.del_loading=false;
            this.$emit('errorAlert',this.$t('globals.error_in_data'))
          });
        this.del_dialog = false;
        this.estimate_del_id = undefined;
      
        this.getLevels();
        this.getweeks();
        this.getapproach();
        setTimeout(() => {
    
        }, 1500);
      }
    },
  },
   watch: {
      perPage() {
        this.getapproach();
      },
      page() {
        this.getapproach(this.page);
      },
      order_data(){
        this.getapproach(this.page);
      }
    },
};
</script>

