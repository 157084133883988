  <template>
  <VForm ref="form">
    <v-expansion-panels class="mb-2" >
      <v-expansion-panel>
        <v-expansion-panel-title class="px-4">
          <span>{{ $t("globals.select-criteria") }}</span>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <VRow class="py-2">
            <VCol cols="12" md="4">
              <VAutocomplete
                :items="levels"
                v-model="studenttalent.level"
                prepend-inner-icon="mdi-stairs-box"
                item-title="level_with_phase_name"
                item-value="id"
                :label="$t('summer.level.name_ar')"
                persistent-hint
                density="compact"
                clearable
                hide-details
                @update:modelValue="
                  getDivisionByLevel(),
                    getStudentsbylevel(),
                    (this.studenttalent.division = null),
                    (this.student_levels = [])
                "
              ></VAutocomplete>
            </VCol>
            <VCol cols="12" md="4">
              <VAutocomplete
                :items="divisions"
                v-model="studenttalent.division"
                prepend-inner-icon="mdi-shape-outline"
                item-title="name"
                item-value="fk_division"
                :label="$t('division.name')"
                clearable
                persistent-hint
                hide-details
                density="compact"
                @update:modelValue="getStudentsbylevel"
              ></VAutocomplete>
            </VCol>
          </VRow>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
    <DataTable
      :items="student_levels"
      :headers="custom_headers"
      :method="getStudentsbylevel"
      :pagination="pagination"
    >
      <template v-slot:item="{ item }">
        <tr>
          <template v-for="(val, key) in item.columns" :key="key">
            <td  v-if="key=='name'">
              {{val}}
            </td>
            <td v-for="talent in talents.filter(talent=>talent.title==key)" :key="talent" >
            <v-lazy>
              <v-checkbox
                v-model="item.raw.talent"
                :value="talent.id"
                hide-details
                density="compact"
                @change="
                  item.raw.changed !== true ? (item.raw.changed = true) : false
                "
                color="indigo"
              >
              </v-checkbox>
            </v-lazy>
          </td>
          </template>
          
        </tr>
      </template>
      <template v-slot:card-actions>
        <VCardActions style="min-height:40px" class="mx-1" v-if="student_levels.length > 0">
          <VBtn
            :loading="loadingSave"
            v-if="
              CheckGetPermission('summer_center.add_studentsummer', [
                'sys_admin',
                'summer_admin',
                'user',
              ]) ||
              CheckGetPermission('summer_center.add_studentsummer', [
                'sys_admin',
                'summer_admin',
                'user',
              ])
            "
            class="bg-indigo"
            @click="saveData"
            size="small"
          >
            <span class="text-white">
              {{ $t("globals.save") }}
            </span>
            <VIcon icon="mdi-content-save" color="white" end></VIcon>
          </VBtn>
          <VBtn class="mx-3" size="small" @click="resetForm">
            {{ $t("globals.clear") }}
            <VIcon icon="mdi-broom" color="golden" end></VIcon>
          </VBtn>
        </VCardActions>
      </template>
    </DataTable>
  </VForm>
</template>
<script>
import { mapState, mapActions } from "vuex";
import DataTable from "@/components/Globals/DataTable.vue";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      students: [],
      change_student_talen: [],
      pagination: {},
      student_levels: [],
      loadingSave: false,
      divisions: [],
      studenttalent: {
        division: undefined,
        level: undefined,
      },
    };
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.fk_year = localStorage.getItem("current_year");
      await this.getLevels();
      await this.gettalents();
      await this.getStudentsbylevel();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  computed: {
    ...mapState({
      levels: (state) => state.summer.levels,
      talents: (state) => state.summer.talents,
      students: (state) => state.summer.students,
      user: (state) => state.User,
    }),
    custom_headers() {
      return [
        { title: this.$t("school.student-name"), key: "name" },
        ...this.talents.map((talent) => ({
          title: talent.title,
          key: talent.title,
        })),
      ];
    },
    CheckGetPermission() {
      return (prem, role) => {
        return this.$store.getters.checkpermission(prem, role);
      };
    },
  },
  methods: {
    ...mapActions({
      getLevels: "summer/getLevels",
      getStudents: "summer/getStudents",
      gettalents: "summer/gettalents",
    }),
    
    addStudentsToList(id) {
      if (!this.change_student_talen.includes(id)) {
        this.change_student_talen.push(id);
      }
    },
    async getStudentsbylevel(
      page = 1,
      per_page = 10,
      ordering = "id",
      search = null
    ) {
      this.loading = true;
      await this.axios
        .get(this.base_url + "api/summer/add-talent-to-student", {
          params: {
            level: this.studenttalent.level,
            division: this.studenttalent.division,
            search: search,
            page: page,
            page_size: per_page,
          }
        })
        .then((response) => {
          this.loading = false;
          this.student_levels = response?.data?.results ?? [];
          this.pagination = response.data.pagination;
          this.student_levels.forEach(student=>{
            this.talents.forEach(talent=>{
              student[talent.title]=null
            })
          })
        });
    },
    async getDivisionByLevel() {
      if (this.studenttalent.level) {
        let levelDivisions = await this.axios.get(this.base_url +`api/summer/get-level-division/${this.studenttalent.level}`);
        this.divisions = levelDivisions.data.division;
      }
    },
    resetForm() {
      this.studenttalent.division = null;
      this.studenttalent.level = null;
      // this.talent_selected=[]
      this.studenttalent = {};
      this.change_student_talen = [];
      this.getStudentsbylevel();
    },
    async saveData() {
      this.loadingSave = true;
      let result = await this.axios.post(this.base_url + `api/summer/add-talent-to-student`,this.student_levels.filter((student) => student.changed == true))
        .then((response) => {
          this.loadingSave = false;
          this.$emit("successAlert", this.$t("globals.data_added"));
          // this.resetForm();
        })
        .catch((error) => {
          this.loadingSave = false;
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
  },
  watch: {
  },
};
</script>
<style scoped>
.select-container {
  height: 280px;
  box-shadow: rgb(0, 0, 0) -5px 5px 3px -5px inset;
  overflow-y: auto;
}
.v-label {
  overflow: visible !important;
}
.v-input--density-compact {
  --v-input-control-height: 35px;
  --v-input-padding-top: 8px;
}
</style>
