// import Dashboard from '../views/Examples/Dashboard'

import SummerUserView from '../views/Usermanage/SummerUserView.vue'
import SummerUserList from '../views/Usermanage/SummerUserList.vue'
import RoleStatus from '../views/Usermanage/RoleStatus.vue'
import SummerGroupView from '../views/Usermanage/SummerGroupView.vue'
import UserChangePassword from '../views/Usermanage/UserChangePassword.vue'
import UserProfile from '../views/Usermanage/UserProfile.vue'
import LogEntries from '../views/Usermanage/LogEntries.vue'
import store from '@/store/index'


// const hasPermission= (prem)=>{
//     store.dispatch('updatePermission',localStorage.getItem('userinfo'))
//     return  store.getters.checkpermission(prem);

// };
// const requirePermission=(perm)=>{
//   const summer_center=localStorage.getItem('summer_center')
//     return (to,from,next)=>{
//         if(hasPermission(perm)&&summer_center=='true'){
//             next()
//         }else{
//             next({
//                 path:"/"    ,
//                 query:{redirect:from.fullPath}
//             })
//         }
//     }
// }
const hasPermission = (prem, role) => {
  return store.getters.checkpermission(prem, role);
};
const requirePermission = (perm, role = []) => {
  return (to, from, next) => {
    if (hasPermission(perm, role)) {
      next();
    } else {
      next({
        path: "/",
        query: { redirect: from.fullPath },
      });
    }
  };
};
const requireRole = (role) => {
  return (to, from, next) => {
    if (store.getters.checkrole(role)) {
      next();
    } else {
      next({
        path: "/",
        query: { redirect: from.fullPath },
      });
    }
  };
};
export default [

    {
        path: 'account/add', 
        name:'summer_add_user',
        component: SummerUserView,
        beforeEnter:requirePermission("usermanage.add_user",['sys_admin','gen_admin','gov_admin','dir_admin','summer_admin','gen_financial','gov_financial','user']),
    },
    {
        path: 'account/list', 
        name:'summer_user_list',
        component: SummerUserList,
        beforeEnter:requirePermission("usermanage.view_user",['sys_admin','gen_admin','gov_admin','dir_admin','summer_admin','gen_financial','gov_financial','user']),
    },
    {
        path: 'roles-status', 
        name:'roles_status',
        component: RoleStatus,
        beforeEnter:requirePermission("usermanage.view_rolesstatus",['sys_admin','gen_admin']),
    },
    {
        path: 'account/:id/edit',
        name:'summer_user_edit',
        component: SummerUserView,
        props:true,
        beforeEnter:requirePermission("usermanage.change_user",['sys_admin','gen_admin','gov_admin','dir_admin','summer_admin','gen_financial','user']),
    },
    {
        path: 'group/add', 
        name:'summer_add_group',
        component: SummerGroupView,
        beforeEnter:requirePermission("auth.add_group",['sys_admin','gen_admin','gov_admin','dir_admin','summer_admin','user']),
    },
    {
        path: 'account/change-password', 
        name:'summer_change_password',
        component: UserChangePassword,
    },
    {
        path: 'account/profile', 
        name:'user_profile',
        component: UserProfile,
    },
    {
        path: 'log-entries', 
        name:'log_entries',
        component: LogEntries,
    },
]
