<template>
<v-expansion-panels class="mb-2">
      <v-expansion-panel     >
          <v-expansion-panel-title class="px-4">
              <span>{{$t('globals.select-criteria')}}</span>
          </v-expansion-panel-title>
          <v-expansion-panel-text >
            <VRow class="mt-1">
              <VCol  cols="12" xs="12" sm="12" md="3" lg="3">
                  <VAutocomplete
                    :items="activityType"
                    v-model="fk_activity_type"
                    item-title="name"
                    item-value="id"
                    :label="$t('summer.activity.type')"
                    persistent-hint
                    prepend-inner-icon="mdi-soccer"
                    density="compact"
                    clearable
                  ></VAutocomplete>
              </VCol>
              <VCol  cols="12" xs="12" sm="12" md="3" lg="3">
                  <VAutocomplete
                    :items="activity_iterations"
                    v-model="iteration"
                    item-title="name"
                    item-value="id"
                    :label="$t('activity.iteration')"
                    persistent-hint
                    prepend-inner-icon="mdi-refresh"
                    density="compact"
                    clearable
                  >
                  </VAutocomplete>
              </VCol>
              <v-col cols="4" md="1" class="mt-2">
                  
                <v-btn  @click="this.page=1,getItems()" class="bg-indigo" size="small">
                  <span class="text-white">
                    {{ $t("globals.show") }}
                  </span>
                </v-btn>
              </v-col>
            </VRow>
          </v-expansion-panel-text>
      </v-expansion-panel>
</v-expansion-panels>
<DataTable
    v-if="CheckGetPermission('summer_center.view_activitysummer')"
    :items="tableList"
    :headers="headers"
    density="compact"
    :method="getItems"
    :create="
      () => {
        addDialog = true;
      }
    "
    :pagination="pagination"
    :editItem="editItem"
    :delItem="deleteItem"
    :del_perm="CheckGetPermission('summer_center.delete_activitysummer',['sys_admin','gen_admin'])"
    :edit_perm="CheckGetPermission('summer_center.change_activitysummer',['sys_admin','gen_admin'])"
    :add_perm="CheckGetPermission('summer_center.add_activitysummer',['sys_admin','gen_admin'])"
  >
  <template v-slot:item.school_recommendation="{item}">
    <span>{{trunccateString(item.raw.school_recommendation,50)}}</span>
  </template>
  <template v-slot:item.execution="{item}">
    <span>{{trunccateString(item.raw.execution,30)}}</span>
  </template>
  <template v-slot:item.iteration="{item}">
    <span>{{getActivityIterationName(item.raw.iteration)}}</span>
  </template>
  <template v-slot:item.fk_activity_type="{item}">
    <span>{{getActivityTypeName(item.raw.fk_activity_type)}}</span>
  </template>
  <template v-slot:item.goal="{item}">
    <span>{{trunccateString(item.raw.goal,30)}}</span>
  </template>
  </DataTable>
   <v-dialog
    v-model="addDialog"
    max-width="700"
    :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
  >
   <v-card flat>
      <v-card-text class="pb-0">
        <VForm ref="form" class="" @submit.prevent="is_update?updateItem():saveItem()">
          <VRow class="mt-2">
            <VCol  cols="12"  md="6">
                <VTextField
                  v-model="summerActivity.name"
                  prepend-inner-icon="mdi-abjad-arabic"
                  :label="$t('summer.activity.name')"
                  :placeholder="$t('summer.activity.name')"
                  :rules="namelRule"
                  density="compact"
                >
                </VTextField>
            </VCol>
            <VCol  cols="12" md="6" >
                <VAutocomplete
                  :items="activityType"
                  v-model="summerActivity.fk_activity_type"
                  item-title="name"
                  item-value="id"
                  :label="$t('summer.activity.type')"
                  persistent-hint
                  prepend-inner-icon="mdi-soccer"
                  density="compact"
                  :rules="activityTypeRule"
                ></VAutocomplete>
            </VCol>
            <VCol  cols="12" md="6" >
                <VAutocomplete
                  :items="activity_iterations"
                  v-model="summerActivity.iteration"
                  item-title="name"
                  item-value="id"
                  :label="$t('activity.iteration')"
                  persistent-hint
                  prepend-inner-icon="mdi-refresh"
                  density="compact"
                  :rules="iteration_rule"
                >
                </VAutocomplete>
            </VCol>
            <VCol cols="12"  md="6" >
                <VTextField
                  v-model="summerActivity.execution"
                  prepend-inner-icon="mdi-clipboard-clock"
                  :label="$t('activity.execution')"
                  :placeholder="$t('activity.execution')"
                  :rules="iteration_rule"
                  density="compact"
                >
                </VTextField>
            </VCol>
            <VCol  cols="12"  md="6" >
                <VTextField
                  v-model="summerActivity.student_number"
                  prepend-inner-icon="mdi-numeric"
                  :label="$t('summer.activity.student_count')"
                  :placeholder="$t('summer.activity.evaluation_placeholder')"
                  density="compact"
                  type="number"
                  clearable
                  :rules="students_count_Rule"
                >
                </VTextField>
            </VCol>
            <VCol  cols="12" md="6" >
                <VTextField
                  v-model="summerActivity.evalution"
                  prepend-inner-icon="mdi-star-outline"
                  :label="$t('summer.activity.evaluation_act')"
                  :placeholder="$t('summer.activity.evaluation_placeholder')"
                  density="compact"
                >
                </VTextField>
            </VCol>
            <VCol cols="12"  >
                <VTextField
                  v-model="summerActivity.goal"
                  prepend-inner-icon="mdi-bullseye-arrow"
                  :label="$t('activity.goal')"
                  :placeholder="$t('activity.goal')"
                  :rules="iteration_rule"
                  density="compact"
                >
                </VTextField>
            </VCol>
            <VCol cols="12">
                <VTextarea
                  v-model="summerActivity.school_recommendation"
                  prepend-inner-icon="mdi-thought-bubble"
                  :label="$t('activity.school-recommendation')"
                  :placeholder="$t('activity.school-recommendation')"
                  :rules="text_rule"
                  density="compact"
                  rows="2"
                  counter="1000"
                >
                </VTextarea>
            </VCol>
            <VCol v-if="summerActivity.iteration == 4" cols="12"  >
                <VTextField
                  v-model="summerActivity.participating_group"
                  prepend-inner-icon="mdi-account-group"
                  :label="$t('activity.participating-group')"
                  :placeholder="$t('activity.participating-group')"
                  :rules="participating_group"
                  density="compact"
                >
                </VTextField>
            </VCol>
            <VCol v-if="summerActivity.iteration == 4" cols="12" >
                <VTextField
                  v-model="summerActivity.entity_to_coordinate"
                  prepend-inner-icon="mdi-office-building"
                  :label="$t('activity.entity-to-coordinate')"
                  :placeholder="$t('activity.entity-to-coordinate')"
                  density="compact"
                  :rules="entity_to_coordinate"
                >
                </VTextField>
            </VCol>
            <VCol v-if="summerActivity.iteration == 4" cols="12">
                <VTextarea
                  v-model="summerActivity.media_recommendation"
                  prepend-inner-icon="mdi-thought-bubble"
                  :label="$t('activity.media-recommendation')"
                  :placeholder="$t('activity.media-recommendation')"
                  :rules="optional_text_rule"
                  density="compact"
                  rows="2"
                  counter="1000"
                >
                </VTextarea>
            </VCol>
            <VCol  cols="12" >
                <VTextarea
                  v-model="summerActivity.note"
                  clearable
                  prepend-inner-icon="mdi-note-outline"
                  :label="$t('globals.note')"
                  counter="250"
                  no-resize
                  :rules="note_rule"
                  density="compact"
                  rows="2"
                ></VTextarea>
            </VCol>
          </VRow>
        </VForm>
      </v-card-text>
      <VCardActions
        class="mx-4"
      >
        <VBtnSave
          v-if="!is_update"
          @click="saveItem()"
          :loading="saveDataLoading"
        >
         {{ $t("globals.add") }}
        </VBtnSave>
        <VBtnUpdate
          v-if="is_update"
          @click="updateItem()"
          :loading="editDataLoading"
        >
            {{ $t("globals.edit") }}
        </VBtnUpdate>
        <VBtn class="mx-3" size="small" @click="resetForm()">
          {{ $t("globals.clear") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
      </VCardActions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import useValidate from "@vuelidate/core";
import DataTable from "@/components/Globals/DataTable.vue";

export default {
  components:{
    DataTable
  },
  data() {
    return {
      summerActivity: {},
      addDialog:false,
      v$: useValidate(),
      level: undefined,
      saveDataLoading:false,
      editDataLoading:false,
      fk_activity_type: undefined,
      iteration: undefined,
      fk_employee: undefined,
      activity_list: [],
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
      headers: [
        { title: this.$t("summer.activity.type"), key: "fk_activity_type" },
        { title: this.$t("summer.activity.name"), key: "name" },
        { title: this.$t("summer.activity.evaluation_act"), key: "evalution" ,show:false},
        { title: this.$t("summer.activity.student_count"), key: "student_number",show:false },
        { title: this.$t("activity.goal"), key: "goal" },
        { title: this.$t("activity.iteration"), key: "iteration" },
        { title: this.$t("activity.school-recommendation"), key: "school_recommendation" },
        { title: this.$t("activity.media-recommendation"), key: "media_recommendation" ,show:false},
        { title: this.$t("activity.execution"), key: "execution" },
        { title: this.$t("activity.participating-group"), key: "participating_group" ,show:false},
        { title: this.$t("activity.entity-to-coordinate"), key: "entity_to_coordinate" ,show:false},
        { title: this.$t("globals.note"), key: "note" ,show:false},
        { title: this.$t("globals.actions"), key: "actions", sortable: false }
      ], 
      iteration_rule: [(value) => !!value || this.$t("globals.required_field")],
      activityTypeRule: [
        (value) => !!value || this.$t("globals.required_field"),
      ],
      namelRule: [
        (value) => !!value || this.$t("globals.required_field"),
        (value) =>
          /^[\u0621-\u064A /:()]+$/.test(value) ||
          this.$t("globals.must_be_letters"),
        (value) =>
          (value && value.length <= 100) ||
          `${this.$t("globals.biggest_number_of_character")} 100`,
        (value) =>
          (value && value.length >= 3) ||
          `${this.$t("globals.lowest_number_of_character")} 3`,
      ],
      participating_group:[
        (value) =>
          !value || value.length <= 1000 || `${this.$t("globals.biggest_number_of_character")} 1000`,
      ],
      entity_to_coordinate:[
        (value) =>
          !value || value.length <= 1000 || `${this.$t("globals.biggest_number_of_character")} 1000`,
      ],
      note_rule: [
        (value) =>
          value==undefined || value == null || (value && value.length <= 250) || value.length == 0 ||
          `${this.$t("globals.max_characters")} 250`,
      ],
      text_rule: [
        (value) => !!value || this.$t("globals.required_field"),
        (value) => (value && value.length <= 1000) || `${this.$t("globals.max_characters")} 1000`,
      ],
      optional_text_rule: [
        (value) =>  value==undefined || value == null || value.length == 0 || (value && value.length <= 1000) || `${this.$t("globals.max_characters")} 1000`,
      ],
      fkEmployeeRule: [(value) => !!value || this.$t("globals.required_field")],
      startDateRule: [
        (value) => !!value || this.$t("globals.required_field"),
        (value) =>
          this.summerActivity.end_date == undefined ||
          value <= this.summerActivity.end_date ||
          this.$t("errors.end-date"),
        (value) =>
          this.summerActivity.end_date == undefined ||
          value != this.summerActivity.end_date ||
          this.$t("errors.start-equal-end-date"),
      ],
      endDateRule: [
        (value) => !!value || this.$t("globals.required_field"),
        (value) =>
          this.summerActivity.start_date == undefined ||
          value >= this.summerActivity.start_date ||
          this.$t("errors.start-date"),
        (value) =>
          this.summerActivity.start_date == undefined ||
          value != this.summerActivity.start_date ||
          this.$t("errors.start-equal-end-date"),
      ],
      gendarRule: [(value) => !!value || this.$t("globals.required_field")],
      locationRule: [
        (value) => !!value || this.$t("globals.required_field"),
        (value) =>
          (value && value.length <= 100) ||
          `${this.$t("globals.biggest_number_of_character")} 100`,
        (value) =>
          (value && value.length >= 3) ||
          `${this.$t("globals.lowest_number_of_character")} 3`,
      ],
      evalutionRule: [
        (value) => !!value || this.$t("globals.required_field"),
        (value) =>
          /^[\u0621-\u064A ]+$/.test(value) ||
          this.$t("globals.must_be_letters"),
        (value) =>
          (value && value.length <= 20) ||
          `${this.$t("globals.biggest_number_of_character")} 20`,
        (value) =>
          (value && value.length >= 3) ||
          `${this.$t("globals.lowest_number_of_character")} 3`,
      ],
      students_count_Rule: [
        (value) =>
          value == undefined || value == null || value.length == 0 ||
          parseInt(value) <= 99 ||
          `${this.$t("globals.biggest_number_of_character")} 99`,
        (value) =>
          value == undefined || value == null || value.length == 0 ||
          parseInt(value) >= 1 ||
          `${this.$t("globals.lowest_number_of_character")} 1`,
      ],
    };
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getActivityType();
      await this.getActivityIterations();
      await this.getItems()
      if(this.checkrole(['sys_admin', 'gen_admin']))
        this.headers.push({ title: this.$t("globals.actions"), key: "actions", sortable: false })

      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  computed: {
    ...mapState({
      activityType: (state) => state.summer.activityType,
      user: (state) => state.User,
      activity_iterations: (state) => state.summer_globals.activity_iterations,
    }),
    ...mapGetters({
    }),
    tableList() {
      if (this.activity_list) {
        return JSON.parse(JSON.stringify(this.activity_list));
      }
    },
    CheckGetPermission() {
      return (prem,role) => {
        return this.$store.getters.checkpermission(prem,role);
      };
    },
  },
  methods: {
    ...mapActions({
      getActivityType: "summer/getActivityType",
      getActivityIterations: "summer_globals/getActivityIterations",
    }),
    getActivityIterationName(id){
      const iteration = this.activity_iterations?.find(iteration=>iteration.id==id)
      return iteration ? iteration?.name:id
    },
    getActivityTypeName(id){
      const type = this.activityType?.find(type=>type.id==id)
      return type ? type?.name:id
    },
    trunccateString(str,maxLength){
      return str?.length>maxLength?str?.slice(0,maxLength)+'  ...':str
    },
    checkrole(role=[]) {
        return this.$store.getters.checkrole(role);
    },
    resetForm() {
      this.$refs.form.reset();
      this.summerActivity = {};
      this.is_update=false
      this.addDialog = false;
    },
    addStudent() {
      this.$router.push("add-activity");
    },
    editItem(item) {
      this.is_update = true;
      this.summerActivity = Object.assign({},item)
      this.addDialog = true
    },
    async deleteItem(delete_id) {
      if(delete_id) {
        var status;
        let result = await this.axios
          .delete(`${this.base_url}api/summer/activity/${delete_id}`)
          .then((responce) => {
            status=true;
          })
          .catch((error) => {
            status = error
          });
        return status
      }
    },
    async getItems(page=1,per_page=10,ordering='id',search=null) {
      this.loading = true;
      await this.axios
        .get(`${this.base_url}api/summer/activity`, {
          params: {
            year: localStorage.getItem("current_year"),
            fk_activity_type: this.fk_activity_type,
            iteration: this.iteration,
            search:search,
            page: page,
            page_size: per_page,
            sort_by: ordering,
          }
        })
        .then((response) => {
          this.activity_list = response.data.results;
          this.pagination = response.data.pagination;
        })
    },
    async saveItem() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.saveDataLoading = true;
        let result = await this.axios
          .post(this.base_url + "api/summer/activity", this.summerActivity)
          .then((response) => {
            this.saveDataLoading=false
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.resetForm();
          })
          .catch((error) => {
            this.saveDataLoading=false
            if (error.response.data.name)
              this.$emit("errorAlert", this.$t("summer.already-visitor"));
            else this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
      }
    },
    async updateItem() {
      const { valid } = await this.$refs.form.validate();
      if (valid && this.summerActivity.id) {
        this.editDataLoading = true;
        let result = await this.axios
          .put(
            this.base_url + `api/summer/activity/${this.summerActivity.id}`,
            this.summerActivity)
          .then((response) => {
            this.editDataLoading=false;
            this.$emit("successAlert", this.$t("globals.data_updated"));
            this.resetForm()
          })
          .catch((error) => {
            this.editDataLoading = false;
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
      }
    },
  },
  
  watch: {
    addDialog(val){
      if(!val){
        this.resetForm()
      }
    }
  },
};
</script>

