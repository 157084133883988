<template>
    <DataTable
        :items="items"
        :method="getItems"
        :pagination="pagination"
        :headers="headers"
        :create="
          () => {
            $router.push({name:'criculms_gifts_supplies'})
          }
        "
    >
    </DataTable>
</template>
<script>
import DataTable from "@/components/Globals/DataTable.vue";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      items: [],
      pagination: {
        num_pages: 0,
        count: 0,
        current_page: 1,
      },
      headers: [
        {
          title: this.$t("globals.governorate"),
          key: "governorate_name",
        },
        {
          title: this.$t("globals.directorate"),
          key: "directorate_name",
        },
        {
          title: this.$t("summer.records-licenses"),
          key: "all_permit_documents",
        },
        {
          title: this.$t("summer.curriculums"),
          key: "all_approach",
        },
        {
          title: this.$t("summer.sports-supplies"),
          key: "all_sport_tools",
        },
        { title: this.$t("globals.cards"), key: "all_cards"},
        {
          title: this.$t("summer.gifts-prizes"),
          key: "all_gifts",
        },
        {
          title: this.$t("summer.collaborator-certificates"),
          key: "all_certificates",
        },
      ],
    };
  },
  methods: {
    async getItems(page = 1, per_page = 10) {
      await this.axios
        .get(`${this.base_url}api/summer/supplies-gifts/list`, {
          params: {
            page: page,
            page_size: per_page,
          },
        })
        .then((response) => {
          this.items = response.data?.results;
          this.pagination = response.data?.pagination;
        });
    },
  },
};
</script>