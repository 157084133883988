<template>
  <div style="height: 200px" class="justify-content-center">
    <chart :option="option_fiveteen_scheme" autoresize />
  </div>
</template>
<script>
const legend = {
  show: true,
  top: "20px",
  left: "left",
  itemWidth: 18,
  itemHeight: 12,
  orient: "vertical",
  textStyle: { fontFamily: "Almarai", fontSize: 11 },
};
const tooltip = {
  trigger: "item",
  formatter: function (params) {
    var name = params.name;
    var percent = params.percent;
    var value = params.value;
    return params.marker + " " + name + "<br/>" + value + " (" + percent + "%)";
  },
};
export default {
  props: ["data"],
  created() {
    this.charts();
  },
  methods: {
    charts() {
      this.series_fiveteen_scheme = [
        {
          type: "pie",
          bottom: "10px",
          radius: ["50%", "70%"],
          center: ["50%", "70%"],
          height: "80%",
          width: "100%",
          data: [
            { value: this.data.data, name: this.data.name },
            {
              value: this.data.total - this.data.data,
              name: this.$t("reportpublic.TotalActivities"),
            },
          ],
          label: {
            show: true,
          },
        },
      ];
      this.option_fiveteen_scheme = {
        title: [
          {
            text: this.data.name,
            subtext: `${this.data.data}`,
            left: "center",
            textStyle: {
              fontFamily: "Almarai",
              fontSize: 10,
              fontWeight: "bold",
            },
            subtextStyle: {
              fontSize: 19,
            },
          },
        ],
        series: this.series_fiveteen_scheme,
        grid: {
          position: "right",
        },
        tooltip: tooltip,
        legend: legend,
      };
    },
  },
};
</script>
