import custom_axios from "@/plugins/axios";

export default ({
    namespaced:true,
    state:{
        groups:[],  
    },
    getters:{
        getGroup(state,subjects){
            state.subjects=subjects
        },
    },
    actions:{
        async getGroup({state,commit}){
            let subjects=(await custom_axios.get(host()+'api/groups/')).data
            return commit('getGroup',groups)
        },
    },
    mutations:{
    
    }

})