<template>
  <v-card>
    <v-card-text class="pb-0">
      <v-row>
        <v-col cols="12" md="10">
          <v-form ref="form">
            <v-row class="mt-4">
              <v-col
                cols="12"
                md="6"
                v-if="
                  !id ||
                  !user_data.role ||
                  user_data.role <= 5 ||
                  user_data.role >= 8
                "
              >
                <v-text-field
                  color="indigo"
                  v-model="user_data.first_name"
                  clearable
                  counter="100"
                  density="compact"
                  prepend-inner-icon="mdi-abjad-arabic"
                  :label="$t('user.fname')"
                  :error-messages="
                    v$.user_data.first_name.$errors.map((e) => e.$message)
                  "
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
                v-if="
                  !id ||
                  !user_data.role ||
                  user_data.role <= 5 ||
                  user_data.role >= 8
                "
              >
                <v-text-field
                  color="indigo"
                  v-model="user_data.last_name"
                  clearable
                  counter="100"
                  density="compact"
                  prepend-inner-icon="mdi-abjad-arabic"
                  :label="$t('user.lname')"
                  :rules="lastname_rule"
                  :error-messages="
                    v$.user_data.last_name.$errors.map((e) => e.$message)
                  "
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  color="indigo"
                  v-model="user_data.email"
                  type="email"
                  clearable
                  counter="100"
                  density="compact"
                  prepend-inner-icon="mdi-alpha-e"
                  :label="$t('user.email')"
                  :error-messages="
                    v$.user_data.email.$errors.map((e) => e.$message)
                  "
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  color="indigo"
                  v-model="user_data.username"
                  clearable
                  counter="100"
                  density="compact"
                  prepend-inner-icon="mdi-abjad-arabic"
                  :label="$t('user.username')"
                  autocomplete="new-username"
                  :error-messages="
                    v$.user_data.username.$errors.map((e) => e.$message)
                  "
                />
              </v-col>
            </v-row>
            <v-row class="mt-4">
              <v-col cols="12" md="6" v-if="!id">
                <v-text-field
                  color="indigo"
                  type="password"
                  v-model="user_data.password"
                  clearable
                  counter="64"
                  density="compact"
                  autocomplete="new-password"
                  prepend-inner-icon="mdi-lock"
                  @input="v$.user_data.password.$touch"
                  :label="$t('user.password')"
                  :error-messages="
                    v$.user_data.password.$errors.map((e) => e.$message)
                  "
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
                v-if="
                  !id ||
                  !user_data.role ||
                  user_data.role <= 5 ||
                  user_data.role >= 8
                "
              >
                <v-autocomplete
                  color="indigo"
                  multiple
                  v-model="user_data.groups"
                  density="compact"
                  item-title="name"
                  item-value="pk"
                  prepend-inner-icon="mdi-account-group"
                  clearable
                  :items="groups_list"
                  :label="$t('user.group')"
                  :error-messages="
                    v$.user_data.groups.$errors.map((e) => e.$message)
                  "
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col v-if="allowRoleChange()" cols="12" md="6">
                <v-autocomplete
                  color="indigo"
                  v-model="user_data.role"
                  density="compact"
                  item-title="name"
                  item-value="id"
                  prepend-inner-icon="mdi-account-hard-hat"
                  clearable
                  :items="roleList"
                  :label="$t('user.role')"
                  :error-messages="
                    v$.user_data.role.$errors.map((e) => e.$message)
                  "
                ></v-autocomplete>
              </v-col>
              <v-col cols="3" md="3">
                <v-checkbox
                  :label="$t('user.active')"
                  color="indigo"
                  v-model="user_data.is_active"
                  density="compact"
                  hide-details
                >
                </v-checkbox>
              </v-col>
              <!-- <v-col cols="3" md="3">
                                <v-checkbox 
                                    :label="$t('user.staff')"
                                    color="indigo"
                                    v-model="user_data.staff"
                                    density="compact"
                                    hide-details
                                >
                                </v-checkbox>
                            </v-col> -->
            </v-row>
            <v-row
              class="pt-0 pb-0"
              v-if="role < 4 || role > 7"
            >
              <!-- <v-row class="pt-0 pb-0" > -->
              <v-col
                v-if="
                  ((user_data.role >= 2 && user_data.role < 5) ||
                    user_data.role == 8) &&
                  (role < 2 || role == 9)
                "
                class="pt-0 px-3"
                cols="12"
                xs="12"
                sm="12"
                md="4"
                lg="4"
              >
                <v-card-item class="pa-0">
                  <v-autocomplete
                    v-model="user_data.fk_governorate"
                    item-value="id"
                    item-title="name_ar"
                    prepend-inner-icon="mdi-city"
                    :items="governorate"
                    :label="$t('globals.governorate')"
                    persistent-hint
                    density="compact"
                    clearable
                    @update:modelValue="user_data.fk_directorate = undefined"
                    :rules="governorate_rule"
                  ></v-autocomplete>
                </v-card-item>
              </v-col>
              <v-col
                v-if="
                  user_data.role >= 3 &&
                  user_data.role < 5 &&
                  (role < 3 || user_data.role >= 8)
                "
                class="pt-0 px-3"
                cols="12"
                xs="12"
                sm="12"
                md="4"
                lg="4"
              >
                <v-card-item class="pa-0">
                  <v-autocomplete
                    v-model="user_data.fk_directorate"
                    item-value="id"
                    item-title="name_ar"
                    prepend-inner-icon="mdi-map-outline"
                    :items="
                      getDirectorateByGovernorates(user_data.fk_governorate)
                    "
                    :label="$t('globals.directorate')"
                    persistent-hint
                    density="compact"
                    clearable
                    @update:modelValue="
                      getAllSummerCenters(),
                        (user_data.fk_schoolsummer = undefined)
                    "
                    :rules="directorate_rule"
                  ></v-autocomplete>
                </v-card-item>
              </v-col>
              <v-col
                v-if="
                  user_data.role >= 4 &&
                  user_data.role < 5 &&
                  (role < 4 || user_data.role >= 8)
                "
                class="pt-0 px-3"
                cols="12"
                xs="12"
                sm="12"
                md="4"
                lg="4"
              >
                <v-card-item class="pa-0">
                  <v-autocomplete
                    v-model="user_data.fk_schoolsummer"
                    item-value="id"
                    item-title="name_ar"
                    prepend-inner-icon="mdi-domain"
                    :items="all_summer_centers"
                    :label="$t('globals.summer-centre')"
                    persistent-hint
                    density="compact"
                    clearable
                    :rules="schoolsummer_rule"
                  ></v-autocomplete>
                </v-card-item>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions
      class="px-3 mt-3"
      v-if="
        CheckGetPermission('usermanage.change_user') ||
        CheckGetPermission('usermanage.add_user')
      "
    >
      <v-btn
        :loading="saveProgress"
        v-if="!id && CheckGetPermission('usermanage.add_user')"
        @click="saveUser"
        class="bg-indigo"
        size="small"
      >
        <span class="text-white">
          {{ $t("globals.add") }}
        </span>
        <v-icon icon="mdi-content-save" color="white" end></v-icon>
      </v-btn>
      <v-btn
        :loading="saveProgress"
        v-if="id && CheckGetPermission('usermanage.change_user')"
        @click="updateUser()"
        class="bg-success"
        size="small"
      >
        <span class="text-white">
          {{ $t("globals.edit") }}
        </span>
        <v-icon icon="mdi-content-save" color="white" end></v-icon>
      </v-btn>
      <v-btn @click="resetForm" class="mx-3" size="small">
        {{ $t("globals.clear") }}
        <v-icon icon="mdi-broom" color="golden" end></v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
  <Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
  >
  </Alert>
</template>

<script>
import useValidate from "@vuelidate/core";
import { mapState, mapActions, mapGetters } from "vuex";
import {
  email,
  required,
  helpers,
  maxLength,
  minLength,
  url,
  alpha,
  alphaNum,
  numeric,
} from "@vuelidate/validators";
import ImageInput from "@/components/Libraries/ImageInput.vue";
// import ExamScheduleReportVue from '../Report/ExamScheduleReport.vue'

export default {
  name: "summer_user_view",
  props: {
    id: {
      type: Number,
    },
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.axios.get(`${this.base_url}user-role/`).then((response) => {
      this.role_list = response.data;
    });
    await this.axios
      .get(`${this.base_url}groups/?user=1`)
      .then((response) => {
        this.groups_list = response.data;
      });
    this.getGovernorates();
    this.getDirectorate();
    // this.getAllSummerCenters()

    if (this.id != undefined) {
      this.is_update = true;
      await this.axios
        .get(`${this.base_url}/userdata/${this.id}/`)
        .then((response) => {
          this.user_data = response.data;
          if (this.user_data.fk_directorate) this.getAllSummerCenters();
        });
    }
    if (
      (this.role >= 2 ||
        this.role == 8) &&
      this.role != 9
    )
      this.user_data.fk_governorate = localStorage.getItem("governorate");

    if (
      this.role >= 3 &&
      this.role != 8 &&
      this.role != 9
    ) {
      this.user_data.fk_directorate = localStorage.getItem("directorate");
      this.getAllSummerCenters();
    }

    if (
      this.role >= 4 &&
      this.role != 8 &&
      this.role != 9
    )
      this.user_data.fk_schoolsummer = localStorage.getItem("schoolsummer");
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
    
  },
  data() {
    return {
      v$: useValidate(),
      role_list: [],
      groups_list: [],
      saveProgress: false,
      all_summer_centers: [],
      user_data: {
        first_name: undefined,
        last_name: undefined,
        email: undefined,
        password: undefined,
        username: undefined,
        role: undefined,
        groups: undefined,
        is_active: false,
        data_entry: undefined,
        updated_by: undefined,
      },
      process_alert: undefined,
      icon_color: undefined,
      icon: undefined,
      alert_title: undefined,
      alert_message: undefined,
      is_update: false,
      governorate_rule: [
        (value) => {
          if (
            (this.user_data.role >= 2 || this.user_data.role == 8) &&
            (this.role <= 2 || this.role >= 8)
          )
            if (!!value) return true;
            else return this.$t("errors.required");
          return true;
        },
      ],
      directorate_rule: [
        (value) => {
          if (
            this.user_data.role >= 3 &&
            this.user_data.role != 8 &&
            (this.role <= 3 || this.role == 8)
          )
            if (!!value) return true;
            else return this.$t("errors.required");
          return false;
        },
      ],
      schoolsummer_rule: [
        (value) => {
          if (
            this.user_data.role >= 4 &&
            this.user_data.role != 8 &&
            (this.role <= 4 || this.role == 8)
          )
            if (!!value) return true;
            else return this.$t("errors.required");
          return true;
        },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.User,
      // directorate: (state) => state.school.directorate,
      governorate: (state) => state.school.governorates,
      // all_summer_centers: (state) => state.summer.all_summer_centers,
      // schoolsummer: (state) => state.schoolsummer,
      role:(state)=>state.role
    }),
    ...mapGetters({
      getDirectorateByGovernorates: "school/getDirectorateByGovernorates",
    }),
    roleList() {
      if (this.role == 4) {
        return this.role_list.filter((role) => role.id == 4 || role.id == 5);
      }
      return this.role_list.filter(
        (item) =>
          (this.role == "undefined" &&
            item.id < 5 &&
            item.id > 0) ||
          (item.id >= this.role &&
            item.id < 5 &&
            item.id > 0) ||
          (this.role <= 2 && item.id == 8) ||
          (this.role == 8 && item.id == 8) ||
          (this.role <= 1 && item.id == 9) ||
          (this.role == 9 && item.id >= 8)
      );
    },
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },

  validations() {
    return {
      user_data: {
        first_name: {
          // required:helpers.withMessage(this.$t('errors.required'),required),
          maxLength: helpers.withMessage(
            this.$t("errors.max-entry"),
            maxLength(100)
          ),
          // arabicOnly:helpers.withMessage(this.$t('errors.arabic-only'),arabicOnly),
        },
        last_name: {
          // required:helpers.withMessage(this.$t('errors.required'),required),
          maxLength: helpers.withMessage(
            this.$t("errors.max-entry"),
            maxLength(100)
          ),
          // englishOnly:helpers.withMessage(this.$t('errors.english-only'),englishOnly),
        },
        username: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
        groups: {
          required: this.id
            ? true
            : helpers.withMessage(this.$t("errors.required"), required),
        },
        email: {
          email: helpers.withMessage(this.$t("errors.email"), email),
        },
        password: {
          required: helpers.withMessage(this.$t("errors.required"), required),
          minLength: helpers.withMessage(
            this.$t("errors.min-entry"),
            minLength(8)
          ),
          maxLength: helpers.withMessage(
            this.$t("errors.max-entry"),
            maxLength(this.id ? 128 : 64)
          ),
        },
        role: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
        // fk_governorate:{
        //     required:helpers.withMessage(this.$t('errors.required'),required),
        // },
        // fk_directorate :{
        //     required:helpers.withMessage(this.$t('errors.required'),required),
        // },
        // fk_schoolsummer:{
        //     required:helpers.withMessage(this.$t('errors.required'),required),
        // },
      },
    };
  },
  methods: {
    ...mapActions({
      getGovernorates: "school/getGovernorates",
      getDirectorate: "school/getDirectorate",
      // getAllSummerCenters: "summer/getAllSummerCenters",
    }),
    allowRoleChange() {
      if (
        this.role< 4 ||
        this.role>= 8
      ) {
        if (
          this.user_data.role &&
          this.user_data.role > 4 &&
          this.user_data.role < 8
        )
          return false;
      } else {
        if (
          this.user_data.role &&
          this.user_data.role > 5 &&
          this.user_data.role < 8
        )
          return false;
      }
      return true;
    },
    async getAllSummerCenters() {
      await this.axios
        .get(`${this.base_url}api/summer/school-select-list`, {
          params: {
            directorate: this.user_data.fk_directorate,
            governorate: this.user_data.fk_governorate,
          }
        })
        .then((response) => {
          this.all_summer_centers = response.data;
        });
    },
    resetForm() {
      this.is_update = false;
      this.user_data.is_active = false;
      this.v$.user_data.$reset();
      this.$refs.form.reset();
      if (this.id != undefined) this.$router.push({ name: "summer_user_list" });
    },
    async saveUser() {
      this.v$.$validate();
      const { valid } = await this.$refs.form.validate();
      if (!this.v$.$error && valid) {
        this.saveProgress = true;
        this.user_data.system_type = 2;
        this.axios
          .post(`${this.base_url}userdata/`, this.user_data)
          .then((responce) => {
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.resetForm();
            this.saveProgress = false;
          })
          .catch((error) => {
            try {
              if (error.response.data.email != undefined)
                this.$emit(
                  "errorAlert",
                  this.$t("globals.user_already_exists")
                );
              else if (error.response.data.username != undefined)
                this.$emit("errorAlert", this.$t("user.username-unique"));
              else this.$emit("errorAlert", this.$t("globals.error_in_data"));
            } catch {
              this.$emit("errorAlert", this.$t("globals.error_in_data"));
            }
            this.saveProgress = false;
          });
      }
    },
    async updateUser() {
      this.v$.$validate();
      const { valid } = await this.$refs.form.validate();
      if (!this.v$.$error && valid && this.id != undefined) {
        this.saveProgress = true;
        this.user_data.system_type = 2;
        await this.axios
          .put(`${this.base_url}userdata/${this.id}/`, this.user_data)
          .then((responce) => {
            this.$emit("successAlert", this.$t("globals.data_updated"));
            setTimeout(() => {
              this.$router.push({ name: "summer_user_list" });
            }, 1500);
            this.saveProgress = false;
          })
          .catch((error) => {
            try {
              if (error.response.data.email != undefined)
                this.$emit(
                  "errorAlert",
                  this.$t("globals.user_already_exists")
                );
              else if (error.response.data.username != undefined)
                this.$emit("errorAlert", this.$t("user.username-unique"));
              else this.$emit("errorAlert", this.$t("globals.error_in_data"));
            } catch {
              this.$emit("errorAlert", this.$t("globals.error_in_data"));
            }
            this.saveProgress = false;
          });
      }
    },
  },
  watch: {
    id() {
      this.resetForm();
    },
    "user_data.email"() {
      if (this.user_data.email == "") {
        this.user_data.email = null;
      }
    },
  },
};
</script>

<style scoped>
.row-hover:hover {
  background: #d4d3d3;
}
</style>