<template>
  <v-container  :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="text-center">
    <h1>test correct</h1>
    <DatetimePicker v-model="date"></DatetimePicker>
    {{ selected }}
    <auto-select
      v-model="selected"
      :items="items"
      class="mt-2 w-25 mx-auto"
      item-title="name"
      item-value="id"
    ></auto-select>
  </v-container>
</template>
<script>
import DatetimePicker from "@/components/Globals/DatetimePicker.vue";
import AutoSelect from "@/components/Globals/AutoSelect.vue";

export default {
  data() {
    return {
      date: "",
      props:{
        placeholder:'Select a governorate'
      },
      menu:false,
      selected: null,
      filteredItems:[],
      search:'',
      items:[
        {name:"صنعاء",id:1},
        {name:"ذمار",id:2},
        {name:"عدن",id:3},
        {name:"صعدة",id:4},
        {name:"تعز",id:5},
        {name:"البيضاء",id:6},
        {name:"حجة",id:7},
        {name:"المحويت",id:8},
      ],
    };
  },
  components: {
    DatetimePicker,
    AutoSelect
  },
  computed:{
    
  },
  mounted(){
    
  },
  
 
};
</script>
