<template>
  <v-card class="pa-2" v-if="!exist">
    <h3 class="text-grey-darken-2 px-2">{{ $t("globals.select-criteria") }}</h3>
    <v-row class="my-2 mt-6">
      <v-col v-if="role<=1" cols="12" md="3" sm="6" class="mb-3">
        <v-select
          color="indigo"
          v-model="gov_id"
          density="compact"
          item-title="name"
          item-value="id"
          prepend-inner-icon="mdi-stairs-box"
          :items="listgov"
          :label="`${$t('reportpublic.Governorate')}`"
          hide-details
          clearable
        />
      </v-col>
      <v-col cols="6" md="3" sm="4">
        <v-autocomplete
          color="indigo"
          v-model="type_id"
          density="compact"
          item-title="type"
          item-value="id"
          clearable="false"
          hide-details
          prepend-inner-icon="mdi-calendar-week"
          :items="typeList"
          :label="$t('summer.summer_type')"
        />
      </v-col>
      <v-col cols="6" md="3" sm="4">
        <v-autocomplete
          color="indigo"
          v-model="from_date"
          :items="calendar"
          density="compact"
          item-title="date"
          item-value="date"
          prepend-inner-icon="mdi-calendar-week"
          clearable
          hide-details
          :label="$t('globals.from')"
        />
      </v-col>
      <v-col cols="6" md="3" sm="4">
        <v-autocomplete
          color="indigo"
          v-model="to_date"
          :items="calendar"
          density="compact"
          item-title="date"
          item-value="date"
          hide-details
          prepend-inner-icon="mdi-calendar-week"
          clearable
          :label="$t('globals.to')"
        />
      </v-col>
 
    </v-row>
       <VCardActions class="px-3">
                <VBtn
                          :loading="loading"

                  class="bg-indigo"
                  type="submit"
                  @click="getGovForReports()"
                  size="small"
                >
                  <span class="text-white">
                    {{ $t("globals.show") }}
                  </span>
                  <VIcon icon="mdi-presentation" color="white" end></VIcon>
                </VBtn>
                <VBtn class="mx-3" size="small" @click="from_date=undefined,to_date=undefined,type_id=undefined,gov_id=undefined">
                  {{ $t("globals.clear") }}
                  <VIcon icon="mdi-broom" color="golden" end></VIcon>
                </VBtn>
              </VCardActions>
  </v-card>
  <v-card v-show="exist">
    <ReportHeader class="pb-0">
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          <div class="text-h5">
           {{
                $t(
                  "reportpublic.VariousActivitiesDuringTheSummerCentersPeriod2"
                )
              }}
          </div>
        </div>
      </template>
    </ReportHeader>
    <v-card-title>
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <div class="d-flex justify-end" style="flex: 1">
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                density="compact"
                class="pa-0 mx-1"
                style="min-width: 25px"
                @click="printTable()"
              >
                <v-icon color="icon-color">mdi-printer</v-icon>
                <v-tooltip class="tooltip" activator="parent" location="top">
                  <small>{{ $t("globals.print-file") }}</small>
                </v-tooltip>
              </v-btn>
            </template>
          </v-menu>
          <v-btn density="compact" class="mx-1" @click="exist = false">
            <span class="text-indigo">{{ $t("globals.back") }}</span>
          </v-btn>
        </div>
      </div>
      <v-container>
        <v-row align="center">
          <v-col cols="auto" class="px-0">
            <v-sheet class="chart-label-sheet" elevation="0" rounded="0">
              {{
                $t(
                  "reportpublic.VariousActivitiesDuringTheSummerCentersPeriod2"
                )
              }}
              
            </v-sheet>
          </v-col>
          <v-col class="pa-0" cols="auto">
            <v-sheet
              :class="`border chart-label-arrow-${this.$i18n.locale} text-center`"
            >
              <h4>
                {{ to_date }} / {{from_date}}
              </h4>
            </v-sheet>
          </v-col>
          <v-col cols="max" align-self="center" class="pa-0">
            <v-divider
              :thickness="1"
              class="border-opacity-100"
              color="black"
            ></v-divider>
          </v-col>
          <v-sheet
            color="black"
            height="6"
            width="6"
            style="border-radius: 50%"
          >
          </v-sheet>
        </v-row>
      </v-container>
    </v-card-title>
    
    <v-card-item>
      <activities-operations-statistics-vue
      
        :data="results"
        :title="
          $t('reportpublic.VariousActivitiesDuringTheSummerCentersPeriod')
        "
      />
    </v-card-item>

    
  </v-card>
</template>
<script>
;
import ActivitesChart from "@/components/reports/ActivitesChart.vue";
import ReportHeader from "@/components/SummerGlobals/ReportHeader.vue";
import ActivitiesOperationsStatisticsVue from "@/components/reports/ActivitiesOperationsStatistics.vue";
import { mapGetters,mapActions,mapState} from "vuex";

export default {
  components: {
    ActivitesChart,
    ReportHeader,
    ActivitiesOperationsStatisticsVue,
  },
  
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);

      await this.getCalendar();

      // var role = localStorage.getItem("role");
      if(this.role<2)
          this.visi_gov = true;

      if (this.role >= 2) {
        this.gov_id = localStorage.getItem("governorate");
      }
      await this.getGovernorates();
      await this.$store.commit("updateLoadingValue", false);
    } catch (erroe) {
      await this.$store.commit("updateLoadingValue", false);
    }
  },
  data() {
    return {
      from_date: null,
      calendar: [],
      loading:false,
      to_date: null,
      visi_gov: false,
      id: 1,
      data_charts: [],
      results: [],
      gov_id: undefined,
      type_id: undefined,
      listgov: [],
      exist: false,
      governorate_name_ar: "",
      options: {},
      typeList: [
        { id: 1, type: this.$t("reportpublic.Open") },
        // { id: 2, type: this.$t("reportpublic.Close") },
        { id: 2, type: this.$t("reportpublic.Typical") },
      ],
      total:0
    };
  },
  methods: {
    ...mapActions({
            getGovernorates: "school/getGovernorates",

      getDirectorate: "school/getDirectorate"
    }),
     async getTitle() {
      this.title = "";
      if(this.type_id)
      {
          this.title +="ال" + this.typeList.find((item) => item.id == this.type_id).type
      }else{
         this.title +="ال" + this.typeList[0].type + " وال" + this.typeList[1].type
      }    
      if(this.gov_id)
     {   this.title +=
          " في محافظة " +
          " ( " +
          this.listgov.find((item) => item.id == this.gov_id)
            .name +
          " ) ";
  } else {
        this.title += " في كل المحافظات ";
      }
      if(this.role>2){
       await this.axios.get(this.base_url+'/directorate/?gov='+this.gov_id).then((response)=>{
                    this.title +=
          " في مديرية " +
          " ( " +
          response.data.find((item) => item.id ==1).name_ar +
          " ) ";

            })
      
        
   
      } else {
        this.title += " في كل المديريات ";
      }
      if(this.from_date &&this.to_date){
        this.title+="من تاريخ "+ this.from_date +" الى تاريخ "+this.to_date
      } else  if(this.from_date){
        this.title+="في تاريخ "+ this.from_date 
      }else if(this.to_date){
        this.title+="الى تاريخ "+ this.to_date 
      }else{
        this.title +=" حتى اليوم"
      }
    },
    async getCalendar() {
      await  this.axios
        .get(`${this.base_url}api/summer/calendar`)
        .then((response) => {
          this.calendar = response.data.results;
        })
        .catch((error) => {});
    },
    async getGovernorates() {
      await  this.axios
        .get(this.base_url + "governorate/?country=" + 1)
        .then((response) => {
          this.listgov = response.data.map((item) => {
            return {
              id: item.id,
              name: this.$i18n.locale == "ar" ? item.name_ar : item.name_en,
            };
          });
        })
        .catch((error) => {});
    },
    getGovForReports() {
      this.getTitle()
      
      this.id = this.gov_id;
      const item = this.listgov.find((i) => i.id === this.gov_id);
      this.governorate_name_ar = item ? item.name : "";
      this.fetchData();
    },
    async fetchData() {
      this.loading = true
      this.options = {};
      let dir = null;
      if(this.role>=3){
        dir = localStorage.getItem('directorate')
      }
      await  this.axios
        .get(`${this.base_url}public-reports/summer-activities`, {
          params: {
            gov_id: this.id,
            dir_id: dir,
            type_id: this.type_id,
            from_date: this.from_date,
            to_date: this.to_date,
          },
        })
        .then((response) => {         
          this.results = response.data.results.results;
          this.total =response.data.results.total??''
          if(!this.results)
          {
            this.$emit('warningAlert',this.$t('globals.not-found-items'))
          }
          else
            this.exist = true;
        })
        .catch((error) => {
 
           this.$emit('errorAlert',this.$t('globals.error_in_data'))
        });
      this.loading = false

    },

    async printTable() {
    
      window.print();
    },
  },
  computed: {
    // role(){
    //   return localStorage.getItem('role')
    // }
    ...mapState(['role'])
  },
};
</script>
