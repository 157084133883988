<template>
  <v-autocomplete
    v-model="value"
    :items="items"
    :item-title="itemTitle"
    :item-value="itemValue"
    :label="label"
    :prepend-inner-icon="prependInnerIcon"
    :placeholder="placeholder"
    :prepend-icon="prependIcon"
    :itemprop="itemProp"
    @update:search="(e) => (search = e)"
    @keydown.enter.prevent="selectFirst()"
    @keydown.tab="selectFirst()"
  ></v-autocomplete>
</template>
<script>
export default {
  props: {
    itemProp:Object,
    items: Object,
    label: String,
    modelValue:Number,
    label:String,
    itemTitle:String,
    itemValue:String,
    prependInnerIcon:String,
    placeholder:String,
    prependIcon:String,
  },
  data() {
    return {
      filteredItems: [],
      search: "",
    };
  },
  methods: {
    selectFirst() {
      if (this.filteredItems?.length > 0) {
        const selected = this.filteredItems[0][this.itemValue];
        if(selected){
            this.$emit('update:modelValue',selected)
        }
      }
    },
  },
  computed:{
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  watch: {
    search(val) {
      if (val)
        this.filteredItems = this.items.filter((item) =>
          item[this.itemTitle].toLowerCase().includes(this.search?.toLowerCase())
        );
      else {
        this.filteredItems = [];
      }
    },
  },
};
</script>