<template>
  <v-container class="ma-auto" >
    <v-locale-provider :locale="locale">
      <v-row align="center" justify="center" >
        <v-col cols="12"  sm="12" lg="8" md="10" xl="6">
          <v-card elevation="2" class="mt-10 rounded-xl" >
            <v-window v-model="step" >
              <v-window-item :value="1">
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-form
                      ref="form"
                      @keydown.enter="loginAction(username, password, year)"
                    >
                    <v-card-text class="my-16" :class="{'mt-6 mb-6':breakPointXS}" >
                      <h2 class="text-center text-h4" style="font-family: 'Almarai' !important;">{{$t('globals.login')}}</h2>
                      <br>
                      <h4 class="text-center grey--text">
                        {{$t('globals.login-account-summer')}}
                      </h4>
                      <v-row align="center" justify="center" class=" mb-4 pb-2 ">
                        <v-col cols="12" sm="10">
                          <v-text-field
                            autofocus
                            v-model="username"
                            :label="$t('globals.username')"
                            :placeholder="$t('globals.username')"
                            dense
                            :disabled="timerCount>0"
                            variant="outlined"
                            class="mt-10"
                            aria-autocomplete="off"
                            clearable
                            @input="error ? (error = null) : null"
                            :error-messages="v$.username.$errors.map((e) => e.$message)"
                          >
                            <template v-slot:prepend-inner>
                              <v-icon color="indigo">mdi-account</v-icon>
                            </template>
                          </v-text-field>
                          <v-text-field
                            v-model="password"
                            :type="showPassword?'text':'password'"
                            :disabled="timerCount>0"
                            variant="outlined"
                            color="blue"
                            clearable
                            name="password"
                            id="password"
                            @input="error ? (error = null) : null"
                            autocomplete="new-password"
                            :placeholder="$t('globals.placeholder_password')"
                            dense
                            :label="$t('globals.password')"
                            :error-messages="v$.password.$errors.map((e) => e.$message)"
                          >
                            <template v-slot:prepend-inner >
                              <v-icon color="indigo" @click="showPassword=!showPassword">{{showPassword?'mdi-eye':'mdi-eye-off'}}</v-icon>
                            </template>
                          </v-text-field>
                          <v-row dense>
                            <v-col cols="12" sm="7">
                              <v-checkbox :disabled="timerCount>0" :label="$t('globals.remeber-me')" class="mt-n1"  density="compact" hide-details="" ></v-checkbox>
                            </v-col>
                          </v-row>
                          <small
                            v-if="error"
                            class="text-error text-center my-2 d-block"
                          >
                            {{ error }} <span v-if="timerCount>0">{{timer}}</span>
                          </small>
                          <v-btn
                            :loading="loading"
                            tile
                            :disabled="timerCount>0"
                            color="blue"
                            dark
                            block
                            @click.prevent="loginAction(username, password, year)"
                          >
                          {{$t('globals.enter')}}
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    </v-form>
                  </v-col>
                  <v-col 
                    cols="12" 
                    sm="6" 
                    class="d-flex align-center justify-center bg-blue"
                    :style="locale=='ar'?'border-radius: 0 25% 25% 0;':'border-radius: 25% 0 0 25%;'"
                    >
                    <!-- :style="backgroundStyle" -->
                    <!-- <v-card  width="100%" height="100%" :style="backgroundStyle">
                      <v-img  :src="require('@/assets/summer_centers_logo.png')">

                      </v-img>
                    </v-card> -->
                    <!-- <v-icon  size="250" color="blue">mdi-account-circle</v-icon> -->
                    
                  </v-col>
                </v-row>
              </v-window-item>
            </v-window>
          </v-card>
        </v-col>
      </v-row>
    </v-locale-provider>
  </v-container>
  <!-- <v-layout class="mainbg" :style="backgroundStyle">
    <div :dir="getdir" class="ma-auto">
      <v-card
        width="320"
        elevation="15"
        rounded="lg"
      >
        <v-card-text class="text-center rou">
          <v-form
            ref="form"
            @keydown.enter="loginAction(username, password, year)"
          >
            <v-row justify="center" class="mt-1">
              <v-col cols="12" md="12" class="ma-1">
                <v-text-field
                  autofocus
                  v-model="username"
                  :label="$t('globals.username')"
                  :placeholder="$t('globals.username')"
                  density="compact"
                  aria-autocomplete="off"
                  clearable
                  @input="error ? (error = null) : null"
                  hide-details="auto"
                  :error-messages="v$.username.$errors.map((e) => e.$message)"
                >
                  <template v-slot:prepend-inner>
                    <v-icon color="indigo">mdi-account</v-icon>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" md="12" class="ma-1">
                <v-text-field
                  v-model="password"
                  :type="showPassword?'text':'password'"
                  hide-details="auto"
                  clearable
                  name="password"
                  id="password"
                  @input="error ? (error = null) : null"
                  autocomplete="new-password"
                  :placeholder="$t('globals.placeholder_password')"
                  density="compact"
                  :label="$t('globals.password')"
                  :error-messages="v$.password.$errors.map((e) => e.$message)"
                >
                <template v-slot:prepend-inner >
                  <v-icon color="indigo" @click="showPassword=!showPassword">{{showPassword?'mdi-eye-off':'mdi-eye'}}</v-icon>
                </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" md="12" class="ma-1">
                <v-autocomplete
                  v-model="year"
                  :items="years"
                  item-title="year"
                  item-value="year"
                  hide-details="auto"
                  clearable
                  @input="error ? (error = null) : null"
                  density="compact"
                  :label="$t('year.select')"
                  :error-messages="v$.year.$errors.map((e) => e.$message)"
                >
                  <template v-slot:prepend-inner>
                    <v-icon color="indigo">mdi-calendar</v-icon>
                  </template>
                </v-autocomplete>
              </v-col>
              <p
                v-if="error"
                class="text-error text-start mt-1"
                style="font-size: 11px"
              >
                {{ error }}
              </p>
              <v-col cols="12" class="my-2">
                <VBtn
                  width="100%"
                  :loading="loading"
                  class="bg-indigo"
                  size="small"
                  @click.prevent="loginAction(username, password, year)"
                >
                  <span class="text-white">
                    {{ $t("globals.enter") }}
                  </span>
                </VBtn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </v-layout> -->
</template>
<script>
import { mapState, mapActions } from "vuex";
import { mapGetters } from "vuex";
import useValidate from "@vuelidate/core";
import { email, required, helpers } from "@vuelidate/validators";
;
import host from "./../../main";
import moment from 'moment-hijri'

// import store from './store'
// ;
export default {
  data() {
    return {
      v$: useValidate(),
      timerCount:null,
      intervalId:null,
      timer:null,
      showPassword:false,
      step:1,
      overlay:false,
      years: [],
      year: null,
      username: undefined,
      loading: false,
      password: undefined,
      backgroundImage: require("@/assets/summer_centers_logo.png"),
      error: null,
      lang: localStorage.getItem("user-locale")
        ? localStorage.getItem("user-locale")
        : "ar",
    };
  },
  created() {
    this.axios(`${this.base_url}api/all-years/`)
      .then((response) => {
        this.years = response.data;
      })
      .catch((error) => {
        if (error.message.includes("Network Error")) {
          this.error = this.$t("alert.failure.connect");
        } else {
          if (error.response.status == 403) {
            this.error = this.$t("errors.user-logged-in");
          }
          if (error.response.status == 401) {
            this.error = this.$t("user.login_error");
          }
        }
      });
    // const dir = localStorage.getItem("user-locale") === "en" ? "ltr" : "rtl";
    // this.$store.dispatch("updatelanguage", localStorage.getItem("user-locale"));
    // this.$store.dispatch("updatedirection", dir);
  },
  validations() {
    return {
      username: {
        required: helpers.withMessage(this.$t("errors.required"), required),
        // email: helpers.withMessage(this.$t("errors.email"), email),
      },
      password: {
        required: helpers.withMessage(this.$t("errors.required"), required),
      },
      year: {
        // required: helpers.withMessage(this.$t("errors.required"), required),
      },
    };
  },
  computed: {
    timer(){
      return this.formatTime(this.timerCount)
    },
    backgroundStyle() {
      return {
        "background-image": `linear-gradient(rgba(52,135, 246,.2), rgba(52,135,246,.8)),url(${this.backgroundImage})`,
        "background-size": "cover",
      };
    },
    ...mapGetters(["getdir"]),
    ...mapState({
      login_error: (state) => state.login_error,
    }),
    breakPointXS(){
      return this.$vuetify.display.xs
    },
    locale(){
      return this.$i18n.locale
    },

  },
  methods: {
    resetForm() {
      this.v$.$reset();
      this.$refs.form.reset();
      this.login_error = undefined;
    },
    formatTime(seconds){
      let duration = moment.duration(seconds,'seconds')
      let formatedTime = moment.utc(duration.asMilliseconds()).format('HH:mm:ss')
      return formatedTime
    },
    startTimer(){
      this.intervalId=setInterval(() => {
        this.timerCount--;
      }, 1000);
    },
    async loginAction(username, password, year) {
      this.v$.$validate();
      if (!this.v$.$error) {
        try {
          this.loading = true;
          await this.axios
            .post(host() + "/api/token/", { username, password, year })
            .then((res) => {
              
              if(res.data?.status && (res.data?.status==4000 || res.data?.status==3000))
                if(res.data?.data?.includes('role is disabled')){
                  this.error = "لا يمكن حاليا تسجيل الدخول بهذا الدور. الرجاء اعادة المحاولة لاحقا ";
                }else{
                  this.error = this.$t("user.login_error");
                }
              else{
                const token = res?.data?.access
                if(token){
                  this.axios.defaults.headers.common['Authorization']=`Bearer ${token}`
                }
                this.$store.dispatch("setAuthenticated", res);
              }
              this.loading = false;
            });
        } catch (error) {
          this.loading = false;
          if (error.message.includes("Network Error")) {
            this.error = this.$t("alert.failure.connect");
          } 
          else if(error?.response?.data?.detail?.includes('Expected available')){
            console.log(error.response);
            let str = error?.response?.data?.detail
            this.timerCount=str.match(/\d+/d)[0]
            this.startTimer()
            this.error = this.$t('errors.maximum-attempts')
          }
          else {
            if (error.response.status == 403) {
               this.error = this.$t("user.login_error");
            }
            else if (error.response.status == 401) {
              this.error = this.$t("user.login_error");
            }
          }

          return 0;
        }
      }
    },
  },
  watch:{
    timerCount(val){
      if(val>0){
        if(!this.intervalId){
          this.startTimer()
        }
      }else{
        clearInterval(this.intervalId)
        this.intervalId=null
      }
    }
  }
  
};
</script>
<style>

</style>