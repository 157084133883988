<template>
  <v-card class="pa-2" v-if="!exist">
    <h3 class="text-grey-darken-2 px-2">{{ $t("globals.select-criteria") }}</h3>
    <v-row class="my-2 mt-6">
      <v-col cols="12" md="4" sm="6" class="mb-3">
        <v-select
          color="indigo"
          v-model="filter_data.fk_level"
          density="compact"
          item-title="level_with_phase_name"
          item-value="id"
          prepend-inner-icon="mdi-stairs-box"
          :items="levels"
          :label="$t('summer.level.name_ar')"
          hide-details
          clearable
          :error-messages="v$.filter_data.fk_level.$errors.map((e) => e.$message)"
          :rules="rules.requird_filed"
        />
      </v-col>

      <v-col cols="6" sm="2" class="d-flex align-center">
        <v-btn
          density="comfortable"
          append-icon="mdi-presentation"
          class="w-100"
          color="indigo"
          :loading="loading"
          @click="checkFilterData"
        >
          <span>{{ $t("globals.show") }}</span>
        </v-btn>
      </v-col>
    </v-row>
  </v-card>

  <!-- filter data  -->
  <v-card flat class="justify-center" id="lectures-report" v-if="exist">
    <ReportHeader>
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          <div class="text-h5">
            {{ $t("report.talent") }} ( {{ getLevelName(this.filter_data.fk_level) }} )
          </div>
        </div>
      </template>
    </ReportHeader>
    <v-card-text class="mb-4">
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <!-- search box  -->
        <div class="d-flex justify-end" style="flex: 1">
          <v-menu>
            <template v-slot:activator="{ props }">
             <v-btn
                v-bind="props"
                density="compact"
                class="pa-0"
                style="min-width: 25px"
                @click="printTable"
              >
                <v-icon color="icon-color">mdi-printer</v-icon>
                <v-tooltip class="tooltip" activator="parent" location="top">
                  <small>{{ $t("globals.print-file") }}</small>
                </v-tooltip>
              </v-btn>
            </template>
            
          </v-menu>
          <v-btn
            density="compact"
            class="mx-1"
            @click="(exist = false), (exist_table = false), v$.filter_data.$reset()"
          >
            <span class="text-indigo">{{ $t("globals.back") }}</span>
          </v-btn>
        </div>
      </div>
      <div id="myTable">
        <v-card v-if="items.length < 1" class="pa-2">
          <h3 class="text-center text-grey-darken-2">
            {{ $t("globals.not-found-items") }}
          </h3>
        </v-card>
        <v-card class="pt-2" :loading="loading" rounded="0" flat v-if="exist_table">
          <table class="report-t">
            <thead>
              <tr>
                <th style="width: 50px;" >{{$t("globals.#")}}</th>

                <th>{{ $t("report.student-name-cap") }}</th>
                <th
                  v-for="item in headers"
                  :key="item"
                  
                >
                  {{ item.title }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item,index) in items" :key="index" class="text-center">
                <td>{{index+1}}</td>
                <td>{{ item.student_name }}</td>
                <td v-for="act in headers" :key="act">
                  <v-icon v-if="item.talents.includes(act.id)" color="success"
                    >mdi-check-circle</v-icon
                  >
                  <v-icon v-else color="error">mdi-close-circle</v-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </v-card>
      </div>
    </v-card-text>
  </v-card>
  <Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
  >
  </Alert>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import useValidate from "@vuelidate/core";
import ReportHeader from "../../../components/SummerGlobals/ReportHeader";

import { required, helpers } from "@vuelidate/validators";
export default {
  components: { ReportHeader },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.$store.dispatch("updatePermission", localStorage.getItem("userinfo"));

      await this.getLevels();
      this.current_year = this.getYearName(localStorage.getItem("current_year"));
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },

  data() {
    return {
      rules: {
        requird_filed: [(value) => !!value || this.$t("globals.required_field")],
      },
      theaders: [],

      current_year: "",
      fees_types: [],
      sub_list: [],
      drivers: [],
      parents: [],
      sub_list_sub: [],
      process_alert: false,
      icon_color: undefined,
      alert_title: undefined,
      alert_message: undefined,
      v$: useValidate(),
      exist: false,
      exist_table: false,
      items: [],
      headers: [],
      loading: false,
      txt_search: "",
      selectedHead: ["id", "name", "adjective", "entity", "phone_number"],

      perPage: 25,
      length: 0,
      totalItems: 0,
      itemsPerPage: [
        { value: 5, text: "5" },
        { value: 10, text: "10" },
        { value: 15, text: "15" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 75, text: "75" },
        { value: 100, text: "100" },
        { value: "", text: this.$t("globals.all") },
      ],
      page: 1,
      pagination: {},
      filter_data: {},
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.User,
      committee: (state) => state.summer.committee,
      levels: (state) => state.summer.levels,
      weeks: (state) => state.summer.weeks,
    }),

    ...mapGetters({}),

    selectedHeaders() {
      return this.headers.filter((header) => this.selectedHead.includes(header.key));
    },
  },
  methods: {
    ...mapActions({
      getCommittee: "summer/getCommittee",
      getLevels: "summer/getLevels",
      getweeks: "summer/getweeks",
    }),
    getYears() {
      this.year_data = localStorage.getItem("current_year_name_h");
      this.filter_data.year = localStorage.getItem("current_year");
      return this.year_data;
    },
    getLevelName(driver_id) {
      if (driver_id) {
        const committees = this.levels.find((committees) => committees.id === driver_id);
        return committees.level_with_phase_name;
      }
      return "===========";
    },
    getCommitName(driver_id) {
      if (driver_id) {
        const committees = this.committee.find(
          (committees) => committees.id === committees.id
        );
        return committees.name;
      }
      return "===========";
    },
    checkFilterData() {
      this.v$.filter_data.$validate();
      if (!this.v$.filter_data.$error) {
        this.getData();
      }
    },
    async printTable() {
      window.print();
    },
    async getData() {
      this.loading = true;
      await this.axios
        .get(`${this.base_url}api/summer/report/get-talent-by-student-and-level`, {
          params: {
            level: this.filter_data.fk_level,
          },
        })
        .then((response) => {
          this.exist = true;
          this.exist_table = true;
          if (response.data) {
            this.items = response.data[0].data;
            this.headers = response.data[0].header;

            if (this.items.length < 1 || this.headers.length < 1) {
              this.exist_table = false;
            }
            this.loading = false;
          }
        })
        .catch((error) => {
          this.icon = "cancel";
          this.icon_color = "error";
          if (error.message.includes("Network Error")) {
            this.alert_title = this.$t("alert.failure.title");
            this.alert_message = this.$t("alert.failure.connect");
          } else {
            this.alert_title = this.$t("alert.not-found.title");
            this.alert_message = this.$t("alert.not-found.message");
          }
          this.process_alert = true;
          setTimeout(() => {
            this.process_alert = false;
          }, 2000);
        });
    },
  },
  validations() {
    return {
      filter_data: {
        fk_level: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
      },
    };
  },
  watch: {
    perPage() {
      this.getData();
    },
    page() {
      this.getData(this.page);
    },
  },
};
</script>
<style scoped>
#stable {
  width: 100%;
}

.pp2 {
  padding: 2px !important;
}
#stable th {
  padding: 10px;
  border: 1px solid #649add !important;
  background-color: #fff;
  color: black;
}

#stable td {
  border: 1px solid #649add !important;
  background-color: #fff;
  padding: 8px;
}

/* #stable tr:nth-child(even){
  background-color: skyblue;

} */

/* #stable tr:hover{background-color: skyblue;} */

/* #stable th {
border: 2px solid skyblue;
padding-top: 12px;
padding-bottom: 12px;
text-align: center;
background-color: blue;
color: black;

} */

/* td {
  padding: 2px;
  font-size: 12px;
}
th {
  font-size: 14px;
  padding-bottom: 2px;
} */

.search:focus {
  outline: unset;
}
.fontsize {
  font-size: 14px;
}
.borders {
  border: solid #c1c1c1 1px;
  border-radius: 8px;
}
@media print {
  #sidebar,
  #header,
  #pagination-bar *,
  #tableHead,
  .tooltip,
  #print-menu {
    display: none !important;
  }
  #lectures-report {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    /* height: 100%; */
  }
}
</style>
