<template>
  <div style="height: 400px" class="justify-content-center">
    <chart :option="statistics_opetions" autoresize />
  </div>
</template>
<script>
const legend = {
  show: true,
  top: "20px",
  left: "left",
  itemWidth: 18,
  itemHeight: 12,
  orient: "vertical",
  textStyle: { fontFamily: "Almarai", fontSize: 10 },
};
export default {
  props:["data","title","subtitle"  ],
  created() {
   
  },
  data(){
    return{
      statistics_opetions:{}
    }
  },
  methods: {
    charts() {
      this.statistics_opetions = {
        title: [
          {
            text: this.title,
            subtext: this.subtitle,
            left: "center",
            textStyle: {
              fontFamily: "Almarai",
              fontSize: 10,
              fontWeight: "bold",
            },
            subtextStyle: {
              fontSize: 18,
              fontFamily: "Almarai",
            },
          },
        ],
        xAxis:{
          type:'category',
          data:this.data?.map(item=>item.name)??[],
          axisLabel:{
            interval:0,
            rotate:30,
            textStyle:{
                fontFamily:'Almarai',
                fontSize:'10px'
            }
          },
        },
        yAxis:{
          type:'value'
        },

        series: {
          type:'bar',
          data:this.data,
          label: {
            show: true,
          },
        },
        tooltip: {
          trigger:'axis',
          axisPointer:{
            type:'shadow'
          }
        },
        legend:legend
      };
      // return this.statistics_opetions
    },
  },
  watch:{
    data(){
      this.charts()
    }
  }
  
};
</script>
