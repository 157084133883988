<template>
<VForm ref="form">
  <VCard>
    <v-card-text class="pb-0">
      <VRow    class="mb-2 mt-1"  v-if="listEmployees.length > 0">
        <v-col cols="12" md="4" sm="12">
          <v-text-field
              color="indigo"
              v-model="txt_search"
              hide-details
              prepend-inner-icon="mdi-magnify"
              single-line
              clearable
              :placeholder="$t('globals.search-here')"
              density="compact"
            >
          </v-text-field>
        </v-col>
        <VCol  cols="6" md="4">
            <VAutocomplete
              :items="weeks"
              v-model="employeeAttendance.fk_week"
              item-title="week_name"
              item-value="id"
              :label="$t('summer.calendar.select-week')"
              persistent-hint
              hide-details
              prepend-inner-icon="mdi-calendar-week"
              density="compact"
              @update:model-value="
                employeeAttendance.fk_week?(
                employeeAttendance.attendance_date = null,
                getDateList()):null
              "
            ></VAutocomplete>
        </VCol>
        <VCol  cols="6" md="4">
          <VAutocomplete
            :items="listWeeks"
            v-model="employeeAttendance.attendance_date"
            density="compact"
            :placeholder="$t('summer.employee.date-attendance')"
            clearable
            hide-details
            prepend-inner-icon="mdi-calendar-clock-outline"
            :label="$t('summer.employee.date-attendance')"
            persistent-hint
            @update:modelValue="employeeAttendance.attendance_date?getEmployeesForTable():null"
          ></VAutocomplete>
        </VCol>
        
      </VRow>

      <v-progress-linear 
        color="indigo" 
        :reverse="$i18n.locale=='ar'"
        height="3px" 
        indeterminate 
        v-if="DataLoading"
      >
      </v-progress-linear>
      <v-data-table
        v-if="listEmployees.length > 0"
        :headers="headers"
        :items="listEmployees"
        items-per-page="-1"
        item-key="id"
        id="vDataTable"
        fixed-footer
        density="compact"
        class="elevation-1"
        :search="txt_search"
      >
        <template v-slot:column.is_attendance="{ column }">
          <v-checkbox 
            v-model="check_all"
            hide-details
            density="compact"
            :label="$t('permission.is_attendance')"
            @change="checkAllEmployees"
          >
          </v-checkbox>
        </template>
        <template v-slot:item="{ item, index }">
          <tr >
            <td >
                {{ index + 1 }}
            </td>
            <td>
              {{item.raw.employee_name}}
            </td>
            <td>
                <v-checkbox
                  v-model="item.raw.is_attendance"
                  color="indigo"
                  density="compact"
                  hide-details
                  @change="item.raw.is_permission=false"
                >
                </v-checkbox>
            </td>
            <td>
                <v-checkbox
                  v-model="item.raw.is_permission"
                  color="indigo"
                  density="compact"
                  hide-details
                  @change="item.raw.is_attendance=false"

                >
                </v-checkbox>
            </td>
          </tr>
        </template>
        <template v-slot:bottom></template>
      </v-data-table>
      <v-card
        v-else-if="!DataLoading || (!loading && listEmployees.length > 0)"
        class="pa-2 text-center mb-2"
      >
        <span >
          {{ $t("globals.not-found-items") }}
        </span>
      </v-card>
    </v-card-text>
    <VCardActions
      class="mx-1"
      v-if="
          (listEmployees.length > 0 &&
            CheckGetPermission('summer_center.add_employeeattendance', [
              'sys_admin',
              'summer_admin',
              'user',
            ])) ||
          (listEmployees.length > 0 &&
            CheckGetPermission('summer_center.change_employeeattendance', [
              'sys_admin',
              'summer_admin',
              'user',
            ]))
        "
    >
      <VBtn
        class="bg-indigo"
        @click="saveEmployeeAttendance()"
        size="small"
        :loading="saveDataLoading"
      >
        <span class="text-white">
          {{ $t("globals.save") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
      <VBtn class="mx-3" size="small" @click="resetForm()">
        {{ $t("globals.clear") }}
        <VIcon icon="mdi-broom" color="golden" end></VIcon>
      </VBtn>
    </VCardActions>
  </VCard>
</VForm>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import Datetime from "vue3-datetime-js";
export default {
  data() {
    return {
      DataLoading: false,
      check_all:false,
      saveDataLoading: false,
      is_update: false,
      is_found_data: false,
      selectValue: false,
      checkAttendance: [],
      checkPermission: [],
      listEmployees: [],
      listWeeks: [],
      employeeAttendance: {
        fk_week:null,
        attendance_date:null
      },
      sortBy: [
        {
          key: "index",
          order: "asc",
        },
      ],

      dialogVisible: false,
      delete_id: undefined,
      selectedHead: ["index", "employee_name", "is_attendance", "is_permission"],
      headers: [
        // { title: "", key: "checkbox" },
        { title: this.$t("globals.number"), key: "index", sortable: false },
        { title: this.$t("summer.employee.name"), key: "employee_name" },
        {
          title: this.$t("permission.is_attendance"),
          key: "is_attendance",
          sortable: false,
        },
        {
          title: this.$t("permission.is_permission"),
          key: "is_permission",
          sortable: false,
        },
      ],
      txt_search: undefined,
    };
  },
  components: { Datetime },
  computed: {
    ...mapState({
      employees: (state) => state.summer.employees,
      weeks: (state) => state.summer.weeks,
    }),
    isCheckAll(){
      var check_all=this.listEmployees.every(employee=>employee.is_attendance)
      this.check_all=check_all
      return check_all
    },
    ...mapGetters({}),
    CheckGetPermission() {
      return (prem, role) => {
        return this.$store.getters.checkpermission(prem, role);
      };
    },
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getEmployeesForTable();
      await this.getWeeks();
      // await this.getDateList();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    ...mapActions({
      getWeeks: "summer/getWeeks",
    }),
    async getDateList() {
      await this.axios
        .get(this.base_url + "api/summer/get-work-days", {
          params: {
            week_id: this.employeeAttendance.fk_week,
          },
        })
        .then((response) => {
          this.listWeeks = response.data.date;
        })
        .catch((error) => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
    resetForm() {
      this.employeeAttendance = {};
      this.listWeeks = [];
      this.is_found_data = false;
      this.is_update = false;
      this.selectValue = false;
      this.getEmployeesForTable()
    },
    
    async saveEmployeeAttendance() {
      this.saveDataLoading = true;
      let newListEmployees = [];
        newListEmployees = this.listEmployees.map((element) => ({
          fk_employee: element.fk_employee,
          is_attendance: element.is_attendance,
          is_permission: element.is_permission,
          fk_week: element.fk_week,
          attendance_date: element.attendance_date,
          id: element.id,
        }));
      let result = await this.axios
        .post(this.base_url + "api/summer/add-attendance-to-employee", newListEmployees)
        .then((response) => {
          this.$emit("successAlert", this.$t("globals.data_added"));
          this.saveDataLoading = false;
          this.getEmployeesForTable()
        })
        .catch((error) => {
          if(error.response.data && error.response.data == "date_error")
            this.$emit("errorAlert", this.$t("globals.future-attendance"));
          else
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          this.saveDataLoading = false;
        });
    },

    async getEmployeesForTable() {
      this.DataLoading = true;
      this.listEmployees = [];
      let result = await this.axios
        .get(this.base_url + "api/summer/add-attendance-to-employee", {
          params: {
            fk_week: this.employeeAttendance.fk_week,
              attendance_date: this.employeeAttendance.attendance_date,
            },
          })
        .then((response) => {
          this.listEmployees = response.data.results;
          this.employeeAttendance.fk_week=Number(response.data?.week)
          this.employeeAttendance.attendance_date=response.data?.date
          if(this.employeeAttendance.fk_week){
            this.getDateList()
          }
          this.DataLoading = false;
        })
        .catch((error) => {
          this.DataLoading = false;
          if (error.response.data === "is_holiday") {
            this.$emit("warningAlert", this.$t("summer.is-holiday"));
          } else if (error.response.data === "out_of_range") {
            this.$emit("warningAlert", this.$t("summer.out-of-range"));
          } else {
            this.$emit("warningAlert", this.$t("summer.not-found-data"));
          }
        });
    },
    checkAllEmployees(){
      this.listEmployees.forEach(employee => {
        employee.is_attendance=this.check_all
        employee.is_permission=false
      });
    }
  },
  watch:{
    isCheckAll(){
      this.check_all=this.isCheckAll
    }
  }
};
</script>

<style>

</style>
