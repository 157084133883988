<template>
  <v-form ref="form" @submit.prevent>
    <v-card class="pa-2" v-if="!exist">
      <h3 class="text-grey-darken-2 px-2">
        {{ $t("globals.select-criteria") }}
      </h3>
      <v-container class="pa-0">
        <div
          class="my-2 mt-6 d-flex align-center flex-wrap flex-grow-1"
          style="gap: 1.5rem"
        >
          <v-col
            class="pt-0 px-3"
            cols="12"
            xs="12"
            sm="12"
            md="4"
            lg="4"
            v-if="visiGovernorat"
          >
            <v-card-item class="pa-0">
              <v-autocomplete
                v-model="fk_governorate"
                item-value="id"
                item-title="name_ar"
                prepend-inner-icon="mdi-city"
                :items="governorates"
                :label="$t('globals.governorate')"
                persistent-hint
                density="compact"
                clearable
                :rules="rules"
                @update:modelValue="
                  (fk_directorate = undefined), (fk_schoolsummer = undefined)
                "
              ></v-autocomplete>
            </v-card-item>
          </v-col>
          <v-col
            v-if="visiDirectorate"
            align-self=""
            class="pt-0 px-3"
            cols="12"
            xs="12"
            sm="12"
            md="4"
            lg="4"
          >
            <v-card-item class="pa-0">
              <v-autocomplete
                v-model="fk_directorate"
                item-value="id"
                item-title="name_ar"
                prepend-inner-icon="mdi-map-outline"
                :items="getDirectorateByGov(fk_governorate)"
                :label="$t('globals.directorate')"
                persistent-hint
                density="compact"
                clearable
                :rules="rules"
                @update:modelValue="
                  sendSummerCenterRequest(fk_directorate),
                    (fk_schoolsummer = undefined),
                    (fk_role = undefined)
                "
              ></v-autocomplete>
            </v-card-item>
          </v-col>

          <v-col class="pt-0 px-3" cols="12" xs="12" sm="12" md="4" lg="4">
            <v-card-item class="pa-0">
              <v-autocomplete
                v-model="fk_schoolsummer"
                item-value="id"
                item-title="name_ar"
                prepend-inner-icon="mdi-domain"
                :items="summers"
                :label="$t('globals.summer-centre')"
                persistent-hint
                :rules="rules"
                density="compact"
                clearable
              ></v-autocomplete>
            </v-card-item>
          </v-col>
          <div>
            <v-btn
              :loading="dataLoading"
              max-width="100px"
              density="comfortable"
              append-icon="mdi-presentation"
              class="table"
              style="width: 100%; border-collapse: collapse"
              color="indigo"
              type="submit"
              @click="fetchData((page = 1))"
            >
              <span>{{ $t("globals.show") }}</span>
            </v-btn>
          </div>
        </div>
      </v-container>
    </v-card>
  </v-form>
  <v-card id="report" v-if="exist">
    <ReportHeader class="pb-0">
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          <div class="text-h5">
            {{ $t("reportpublic.summer_center_student_data") }}
          </div>
        </div>
      </template>
    </ReportHeader>
    <v-card-title class="mb-5 pa-0">
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <div class="d-flex justify-end" style="flex: 1">
          <v-btn
            @click="printTable((all = true))"
            v-bind="props"
            density="compact"
            class="pa-0 mx-1"
            style="min-width: 25px"
          >
            <v-icon color="icon-color">mdi-printer</v-icon>
            <v-tooltip class="tooltip" activator="parent" location="top">
              <small>{{ $t("globals.print-file") }}</small>
            </v-tooltip>
          </v-btn>

          <v-btn
            v-if="visiBack"
            density="compact"
            class="mx-1"
            @click="exist = false"
          >
            <span class="text-indigo">{{ $t("globals.back") }}</span>
          </v-btn>
        </div>
      </div>
      <v-container>
        <v-row align="center">
          <v-col cols="auto">
            <v-sheet class="chart-label-sheet" elevation="0" rounded="0">
              {{ $t("school.students_info") }}

              {{ title }}
            </v-sheet>
          </v-col>
          <v-col class="pa-0" cols="auto">
            <v-sheet
              :class="`border chart-label-arrow-${this.$i18n.locale} text-center`"
            >
              <h4>
                {{ totalItems }}
              </h4>
            </v-sheet>
          </v-col>
          <v-col cols="max" align-self="center" class="pa-0">
            <v-divider
              :thickness="1"
              class="border-opacity-100"
              color="black"
            ></v-divider>
          </v-col>
          <v-sheet
            color="black"
            height="6"
            width="6"
            style="border-radius: 50%"
          >
          </v-sheet>
        </v-row>
      </v-container>
    </v-card-title>

    <v-card-item v-if="data?.length > 0">
        <table class="report-t">
         <thead>
            <tr>
              <template v-for="header in headers" :key="header">
                <th v-if="header.key=='index'" style="width: 50px;">{{header.title}}</th>
                <th v-else>{{header.title}}</th>
              </template>
            </tr>
          </thead>
          <tbody>
         
             <tr v-for="(item, key) in data" :key="key">
                  <td >{{key+1}}</td>
                  <td >{{item[headers[1].key]}}</td>
                  <td >{{item[headers[2].key]}}</td>
              </tr>

          </tbody>
      </table>
      <!-- <v-data-table-server
        :headers="headers"
        :items="data"
        item-value="name"
        item-key="id"
        density="compact"
        class="table stable"
        style="width: 100%; border-collapse: collapse"
        :items-per-page="perPage"
      >
       <template v-slot:item="{ item,index }">
            <tr class="row-hover">
              <td v-for="(val, key) in item.columns" :key="key">
                <v-td v-if="key == 'index'">
                  {{index +1}}
                </v-td>
                <span v-else> {{ val }}</span>
              </td></tr>
              </template>
            <template v-slot:bottom> 
            <div class="d-flex" id="pagination-bar">
              <v-pagination
                v-model="page"
                :length="pagination.num_pages"
                density="compact"
                show-first-last-page
              >
              </v-pagination>
              <div class="d-flex">
                <v-select
                  v-model="perPage"
                  class="pa-0"
                  :items="itemsPerPage"
                  item-value="value"
                  item-title="text"
                  density="compact"
                  hide-details
                  variant="text"
                >
                </v-select>
                <span class="mt-2 px-2"
                  >{{ $t("globals.per-page") }} {{ $t("globals.from") }} [
                  {{ pagination.count }} ] {{ $t("globals.item") }}</span
                >
              </div>
            </div>
        </template>
      </v-data-table-server> -->
    </v-card-item>

    
    <v-card-item v-else>
      <h3 class="text-center text-grey-darken-2">
        {{ $t("globals.not-found-items") }}
      </h3>
    </v-card-item>
  </v-card>
</template>

<script>
;
import ReportHeader from "@/components/SummerGlobals/ReportHeader.vue";
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  components: {
    ReportHeader,
  },
  data() {
    return {
      title: "",
      summers:[],
      exist: false,
      visiBack: true,
      visiGovernorat: false,
      visiDirectorate: false,
      governorates: [],
      data: [],
      fk_governorate: null,
      fk_directorate: null,
      fk_schoolsummer: undefined,
      dataLoading: false,
      totalItems: null,
      length: 0,
        page: 1,
      perPage: 10,
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
        itemsPerPage: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 1000, text: this.$t("globals.all") },
      ],
      headers: [
           {
          title: this.$t("globals.#"),
          key: "index",
          sortable: false,
          
        },

        {
          title: this.$t("report.student-name-cap"),
          key: "name",
          sortable: false,
        },
        {
          title: this.$t("class.phase"),
          key: "level",
          sortable: false,
        },
      ],
      rules: [(value) => (value ? true : this.$t("globals.required_field"))],
    };
  },
  async created() {
    await this.$store.commit("updateLoadingValue", true);
    try {
      await this.getGovernorates();
      await this.getDirectorate();
      this.visiGovernorat = true;
      this.visiDirectorate = true;
      // var role = localStorage.getItem("role");
      var gov = localStorage.getItem("governorate");
      if (this.role == 0 || this.role == 1) {
        this.governorates = this.governorate;
      } else if (this.role == 2) {
        // this.governorates = this.governorate.filter((item) => item.id == gov);
        this.fk_governorate = parseInt(gov);
        this.visiGovernorat = false;
      } else if (this.role == 3) {
        this.fk_governorate = parseInt(gov);
        this.fk_directorate = parseInt(localStorage.getItem("directorate"));
        this.visiGovernorat = false;
        this.visiDirectorate = false;
        this.sendSummerCenterRequest(this.fk_directorate);
      }
    } catch {}
    await this.$store.commit("updateLoadingValue", false);
  },
  computed: {
    ...mapState({
      governorate: (state) => state.school.governorates,
      all_summer_centers: (state) => state.summer.all_summer_centers,
      role:(state)=>state.role
    }),
    ...mapGetters({
      getDirectorateByGovernorates: "school/getDirectorateByGovernorates",
    }),
  },
  methods: {
    ...mapActions({
      getGovernorates: "school/getGovernorates",
      getDirectorate: "school/getDirectorate",
      getAllSummerCenters: "summer/getAllSummerCenters",
    }),
    getTitle() {
      this.title = "";
      if (this.fk_governorate) {
        this.title +=
          " في محافظة " +
          " ( " +
          this.governorate.find((item) => item.id == this.fk_governorate)
            .name_ar +
          " ) ";
      } else {
        this.title += " في كل المحافظات ";
      }
      if (this.fk_directorate) {
        const dir = this.getDirectorateByGov(this.fk_governorate);
        this.title +=
          " في مديرية " +
          " ( " +
          dir.find((item) => item.id == this.fk_directorate).name_ar +
          " ) ";
      } else {
        this.title += " في كل المديريات ";
      }

      if (this.fk_schoolsummer) {
        this.title +=
          " الدورة الصيفية " +
          " ( " +
          this.all_summer_centers.find(
            (item) => item.id == this.fk_schoolsummer
          ).name_ar +
          " ) ";
      } else {
        this.title += "  لكل المراكز الصيفية ";
      }
    },
    async sendSummerCenterRequest(dir) {
      if (dir) {
        await this.getAllSummerCenters(dir);
        this.summers = this.all_summer_centers;
      } else this.summers = [];
    },
    getDirectorateByGov(gov) {
      if (this.role == 3) {
        var l = this.getDirectorateByGovernorates(gov);

        return l.filter(
          (item) => item.id == localStorage.getItem("directorate")
        );
      }

      return this.getDirectorateByGovernorates(gov);
    },

    async fetchData(page) {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.getTitle()
        this.dataLoading = true;        
        await this.axios(
          `${this.base_url}public-reports/summer-center-student-data-report`,
          {
            params: {
              page: page,
              page_size: 1000000,
              summer_id: this.fk_schoolsummer,
            }}
        )
          .then((response) => {
            this.data = response.data.results;
            this.exist = true;
            this.pagination = response.data.pagination;
            this.length = response.data.pagination.num_pages;
            this.itemsPerPage[4].value = response.data.pagination.count;
            this.totalItems = response.data.pagination.count;
          })
          .catch((err) => {});
        this.dataLoading = false;
      }
    },
    async printTable(all) {
      if (all) {
        this.txt_search = null;
        this.perPage = this.totalItems;
        await this.fetchData();
      }
      window.print();
    },
  },
  watch: {
    perPage() {
      this.fetchData();
    },
    page() {
      this.fetchData(this.page);
    },
  },
};
</script>
<style>
.stable {
  width: 100%;
}

.stable th {
  padding: 10px;
  border: 0.1px solid #649add !important;
  background-color: #cfd8dc !important;
}

.stable td {
  border: 0.1px solid #649add !important;
  background-color: #fff;
  padding: 8px;
}
</style>
