<template>
  <v-form @submit.prevent v-if="!exist &&role<=2">
    <v-card class="pa-2" >
      <h3 class="text-grey-darken-2 px-2">
        {{ $t("globals.select-criteria") }}
      </h3>
      <v-container class="pa-0">
        <div
          class="my-2 mt-6 d-flex align-center flex-wrap flex-grow-1"
          style="gap: 1.5rem"
        >
          <v-col
            class="pt-0 px-3"
            cols="12"
            xs="12"
            sm="12"
            md="4"
            lg="4"
            v-if="role==0||role==1"
          >
            <v-card-item class="pa-0">
              <v-autocomplete
                v-model="fk_governorate"
                item-value="id"
                item-title="name_ar"
                prepend-inner-icon="mdi-city"
                :items="governorate"
                :label="$t('globals.governorate')"
                persistent-hint
                density="compact"
                clearable
                 @update:modelValue="
                  (fk_directorate = undefined), (fk_schoolsummer = undefined)
                "
              ></v-autocomplete>
            </v-card-item>
          </v-col>
          <v-col
            align-self=""
            class="pt-0 px-3"
            cols="12"
            xs="12"
            sm="12"
            md="4"
            lg="4"
          >
            <v-card-item class="pa-0">
              <v-autocomplete
                v-model="fk_directorate"
                item-value="id"
                item-title="name_ar"
                prepend-inner-icon="mdi-map-outline"
                :items="getDirectorateByGov(fk_governorate)"
                :label="$t('globals.directorate')"
                persistent-hint
                 density="compact"
                clearable
                v-if="role>=0&&role<=2"
              ></v-autocomplete>
            </v-card-item>
          </v-col>

          <div>
            <v-btn
              :loading="dataLoading"
              type="submit"
              max-width="100px"
              density="comfortable"
              append-icon="mdi-presentation"
              class="w-100 text-white"
              color="indigo"
              @click="getReportsSchool((page = 1))"
            >
              <span>{{ $t("globals.show") }}</span>
            </v-btn>
          </div>
        </div>
      </v-container>
    </v-card>
  </v-form>
  <v-card id="report" v-if="exist">
    <ReportHeader class="pb-0">
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          <div class="text-h5">
            {{ $t("reportpublic.SummerCouresStatistics") }}
          </div>
        </div>
      </template>
    </ReportHeader>
    <v-card-title class="mb-5 pa-0">
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <div class="d-flex justify-end" style="flex: 1">
          <v-btn
            @click="printTable((all = true))"
            v-bind="props"
            density="compact"
            class="pa-0 mx-1"
            style="min-width: 25px"
          >
            <v-icon color="icon-color">mdi-printer</v-icon>
            <v-tooltip class="tooltip" activator="parent" location="top">
              <small>{{ $t("globals.print-file") }}</small>
            </v-tooltip>
          </v-btn>

          <v-btn
            v-if="role<=2"
            density="compact"
            class="mx-1"
            @click="exist = false"
          >
            <span class="text-indigo">{{ $t("globals.back") }}</span>
          </v-btn>
        </div>
      </div>
      <v-container>
        <v-row align="center">
          <v-col cols="auto">
            <v-sheet class="chart-label-sheet" elevation="0" rounded="0">
              {{ $t("reportpublic.SchoolReport") }}
              {{ title }}
            </v-sheet>
          </v-col>
          <v-col class="pa-0" cols="auto">
            <v-sheet
              :class="`border chart-label-arrow-${this.$i18n.locale} text-center`"
            >
              <h4>
                {{ totalItems }}
              </h4>
            </v-sheet>
          </v-col>
          <v-col cols="max" align-self="center" class="pa-0">
            <v-divider
              :thickness="1"
              class="border-opacity-100"
              color="black"
            ></v-divider>
          </v-col>
          <v-sheet
            color="black"
            height="6"
            width="6"
            style="border-radius: 50%"
          >
          </v-sheet>
        </v-row>
      </v-container>
    </v-card-title>
    <v-card-item v-if="school_report.length>0" >
    <table class="report-t">
            <thead>
            <tr>
              <template v-for="header in headers" :key="header">
                <th v-if="header.key=='index'" style="width: 50px;">{{header.title}}</th>
                <th v-else>{{header.title}}</th>
              </template>
              </tr>
            </thead>
            <tbody>         
            <tr v-for="(item, key) in table_list" :key="key">
                  <td >{{key+1}}</td>
                  <td >{{item[headers[1].key]}}</td>
                  <td >{{item[headers[2].key]}}</td>
                  <td >{{item[headers[3].key]}}</td>
                  <td >{{item[headers[4].key]}}</td>
                  <td >{{item[headers[5].key]}}</td>
                  <td >{{item[headers[6].key]}}</td>
                  <td >{{item[headers[7].key]}}</td>
          
            </tr>

          </tbody>
          </table>
    
    </v-card-item>
       
  </v-card>
  
</template>

<script>
import ReportHeader from "@/components/SummerGlobals/ReportHeader.vue";
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  components: {
    ReportHeader,
  },
  data() {
    return {
      title: "",
      exist: false,
      visiBack: true,
      visiGovernorat: true,
      dataLoading:false,
      school_report: [],
      fk_governorate: undefined,
      fk_directorate: undefined,
      totalItems: null,
      length: 0,
     page: 1,
      perPage: 10,
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
        itemsPerPage: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 1000, text: this.$t("globals.all") },
      ],

      headers: [
         {
          title: this.$t("globals.#"),
          key: "index",
          sortable: false,
          
        },


        {
          title: this.$t("reportpublic.CenterName"),
          key: "name_ar",
          sortable: false,
          
        },
        // {
        //   title: this.$t("globals.country"),
        //   key: "country_name",
        //   sortable: false,
        // },
        {
          title: this.$t("globals.governorate"),
          key: "governorate_name",
          sortable: false,
        },
        {
          title: this.$t("globals.directorate"),
          key: "directorate_name",
          sortable: false,
        },
        {
          title: this.$t("school.student_address"),
          key: "address",
          sortable: false,
        },
        {
          title: this.$t("summer.shift-time"),
          key: "shift_time",
          sortable: false,
        },
        // {
        //   title: this.$t("summer.school-has-garden"),
        //   key: "is_garden",
        //   sortable: false,
        // },
       
        // {
        //   title: this.$t("summer.school-has-license"),
        //   key: "have_license",
        //   sortable: false,
        // },
        {
          title: this.$t("summer.summer_type"),
          key: "school_type",
          sortable: false,
        },
        // {
        //   title: this.$t("school.license_number"),
        //   key: "license_number",
        //   sortable: false,
        // },
        // {
        //   title: this.$t("school.establish_date"),
        //   key: "date_of_establishment",
        //   sortable: false,
        // },
        {
          title: this.$t("school.students_type"),
          key: "students_type",
          sortable: false,
        },
      ],
  
    };
  },
  async created() {

    await this.$store.commit('updateLoadingValue',true)

   try{

    //  var role = localStorage.getItem("role");
    if (this.role == 2) this.visiGovernorat = false;
    if (this.role == 3) {
      this.exist = true;
      this.visiGovernorat = false;
    }
    await this.getGovernorates();
    await this.getDirectorate();
    if(this.role==2 || this.role==3){
      this.fk_governorate = localStorage.getItem('governorate')
    }
    if (this.role==3) {
      this.exist = true;
      this.fk_directorate = localStorage.getItem('directorate')
      await this.getReportsSchool()
    }
    await this.getShoolTypes();
    await this.getStudentTypes();
    await this.getShiftTimes();
   }catch{}

    await this.$store.commit('updateLoadingValue',false)

  },
  computed: {
    // role(){
    //   return localStorage.getItem('role')
    // },
    ...mapState({
      governorate: (state) => state.school.governorates,
      shoolTypes: (state) => state.summer.shoolTypes,
      studentTypes: (state) => state.summer.studentTypes,
      shiftTimes: (state) => state.summer.shiftTimes,
      role: (state) => state.role,
    }),
    ...mapGetters({
      getDirectorateByGovernorates: "school/getDirectorateByGovernorates",
    }),
    table_list() {
      let list = [];
      this.school_report.forEach((element) => {
        list.push({
          country_name: element.country_name,
          directorate_name: element.directorate_name,
          governorate_name: element.governorate_name,
          name_ar: element.name_ar,
          name_en: element.name_en,
          address: element.address,
          class_count: element.class_count,
          shift_time: this.getShiftTimesName(element.shift_time),
          is_garden: element.is_garden ? "نعم" : "لا",
          evaluation: element.evaluation,
          have_license: element.have_license ? "نعم" : "لا",
          school_type: this.getShoolTypesName(element.school_type),
          license_number: element.license_number,
          date_of_establishment: element.date_of_establishment,
          students_type: this.getStudentTypesName(element.students_type),
        });
      });
      return list;
    },
  },
  methods: {
    ...mapActions({
      getGovernorates: "school/getGovernorates",
      getDirectorate: "school/getDirectorate",
      getShoolTypes: "summer/getShoolTypes",
      getStudentTypes: "summer/getStudentTypes",
      getShiftTimes: "summer/getShiftTimes",
    }),
    getTitle() {
      this.title = "";
      if (this.fk_governorate) {
        this.title +=
          " في محافظة " +
          " ( " +
          this.governorate.find((item) => item.id == this.fk_governorate)
            ?.name_ar +
          " ) ";
      } else {
        this.title += " في كل المحافظات ";
      }
      if (this.fk_directorate) {
        const dir = this.getDirectorateByGov(this.fk_governorate);
        this.title +=
          " في مديرية " +
          " ( " +
          dir.find((item) => item.id == this.fk_directorate).name_ar +
          " ) ";
      } else {
        this.title += " في كل المديريات ";
      }
    },

    getShoolTypesName(value) {
      let shoolType = this.shoolTypes.find((element) => element.id === value);
      return shoolType ? shoolType.name : "";
    },
    getStudentTypesName(value) {
      let studentType = this.studentTypes.find(
        (element) => element.id === value
      );
      return studentType ? studentType.name : "";
    },
    getShiftTimesName(value) {
      let shiftTime = this.shiftTimes.find((element) => element.id === value);
      return shiftTime ? shiftTime.name : "";
    },
     getReportsSchool(page = 1) {
      this.getTitle();
      // var role = localStorage.getItem("role");
      if (this.role == 0 || this.role == 1) {
        this.fetchData(page);
      } else if (this.role == 2) {
        if (this.fk_governorate) {
          this.fetchData(page);
        }
      } else if (this.role == 3) {
        if (this.fk_governorate && this.fk_directorate) {
          this.fetchData(page);
        }
      }
    },
    getDirectorateByGov(gov) {
      if (this.role == 3) {
        var l = this.getDirectorateByGovernorates(gov);

        return l.filter(
          (item) => item.id == localStorage.getItem("directorate")
        );
      }

      return this.getDirectorateByGovernorates(gov);
    },

    async fetchData(page) {
         this.dataLoading =true
      await this.axios(`${this.base_url}public-reports/summer-reports/`, {
        params: {
          page: page,
          page_size: 1000000,
          gov_id: this.fk_governorate,
          directorate_id: this.fk_directorate,
        }})
        .then((response) => {
          this.school_report = response.data.results;


          this.pagination = response.data.pagination;
          this.length = response.data.pagination.num_pages;
          this.itemsPerPage[4].value = response.data.pagination.count;
          this.totalItems = response.data.pagination.count;

          this.exist = true;
        })
        .catch((err) => {});

           this.dataLoading =false
    },
    async printTable(all) {
      if (all) {
        this.txt_search = null;
        this.perPage = this.totalItems;
        await this.fetchData();
      }
      window.print();
    },
  },
  watch: {
    perPage() {
      this.getReportsSchool();
    },
    page() {
      this.getReportsSchool(this.page);
    },
  },
};
</script>
