<template>
 
  <v-expansion-panels class="mb-2" v-if="role >= 0 && role <= 3">
    <v-expansion-panel>
      <v-expansion-panel-title class="px-4">
        <span>{{ $t("globals.select-criteria") }} </span>
      </v-expansion-panel-title>
      <v-expansion-panel-text>
        <v-row class="py-2">
          <VCol
            class="mt-1"
            cols="6"
            md="3"
            sm="4"
            v-if="role == 0 || role == 1"
          >
            <VAutocomplete
              :items="filterGovernorates(1)"
              v-model="fk_governorate"
              item-title="name_ar"
              item-value="id"
              :label="$t('globals.governorate')"
              persistent-hint
              density="compact"
              hide-details
              clearable
              a
              :no-data-text="$t('governorate.not-found')"
              prepend-inner-icon="mdi-city"
              @update:modelValue="
                getEmployees(),
                  getSummerSchools(),
                  (fk_directorate = null),
                  (fk_summer = null)
              "
            ></VAutocomplete>
          </VCol>
          <VCol
            class="mt-1"
            cols="6"
            md="3"
            sm="4"
            v-if="role >= 0 && role <= 2"
          >
            <VAutocomplete
              :items="filterDirectorates(fk_governorate)"
              v-model="fk_directorate"
              item-title="name_ar"
              item-value="id"
              :label="$t('globals.directorate')"
              persistent-hint
              hide-details
              density="compact"
              clearable
              :no-data-text="$t('directorate.not-found')"
              prepend-inner-icon="mdi-map-outline"
              @update:modelValue="
                getEmployees(), getSummerSchools(), (fk_summer = null)
              "
            ></VAutocomplete>
          </VCol>
          <VCol
            class="mt-1"
            cols="6"
            md="3"
            sm="4"
            v-if="role >= 0 && role <= 3"
          >
            <VAutocomplete
              v-model="fk_summer"
              item-value="id"
              item-title="name_ar"
              prepend-inner-icon="mdi-domain"
              :items="summer_schools"
              :label="$t('globals.summer-centre')"
              persistent-hint
              hide-details
              density="compact"
              :no-data-text="$t('summer.not-found')"
              @update:modelValue="getEmployees(), getSummerSchools()"
              clearable
            ></VAutocomplete>
          </VCol>
          <v-col class="mt-1" cols="6" md="3">
            <VAutocomplete
              v-model="fk_role"
              clearable
              :items="roles"
              item-title="name"
              item-value="id"
              :label="$t('summer.work-type')"
              persistent-hint
              density="compact"
              hide-details="auto"
              prepend-inner-icon="mdi-format-list-bulleted"
              @update:modelValue="getEmployees((page = 1))"
            ></VAutocomplete>
          </v-col>
        </v-row>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
  <!-- ######## Header Of Data Table  ####### -->
  <v-card class="mt-2" v-if="CheckGetPermission('summer_center.view_employee')">
    <v-card-text>
      <v-row class="mt-1 mb-2">
        <v-col cols="4">
          <v-text-search
            v-model="txt_search"
            @input="excelData?.length == 0 ? getEmployees((page = 1)) : null"
            class="search"
          >
          </v-text-search>
        </v-col>
        <v-col cols="8" class="text-end">
          <v-btn-group class="ms-2">
            <v-menu :close-on-content-click="false">
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props">
                  <v-icon class="me-2">mdi-eye-outline</v-icon>
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-card elevation="1">
                <v-card-text>
                  <v-checkbox
                    v-for="header in headers"
                    :key="header.key"
                    :label="header.title"
                    :value="header.key"
                    color="indigo"
                    v-model="selectedHead"
                    density="compact"
                    hide-details
                  >
                  </v-checkbox>
                </v-card-text>
              </v-card>
            </v-menu>
            <VBtn
              size="small"
              @click="handelImportBtn"
              :loading="importProgress"
              v-if="role == 0 "
            >
              <span>{{ $t("import.import") }}</span>
              <VIcon color="green-darken-2" end>mdi-file-excel</VIcon>
            </VBtn>
            <v-btn
              size="small"
              @click="exportToExcel"
              :loading="exportProgress"
            >
              <span>{{ $t("export.export") }} </span>
              <v-icon size="large" color="green-darken-2" end
                >mdi-file-excel</v-icon
              >
            </v-btn>
          </v-btn-group>
          <input
            v-show="false"
            type="file"
            ref="excelInput"
            @input="importExcelFile"
            @click="resetExcelFileSelected"
            accept=".xlsx,.xls"
          />
        </v-col>
      </v-row>
      <!-- ######## End Header Of Data Table Server ####### -->
      <v-card
        :disabled="DataLoading"
        :loading="DataLoading"
        v-if="this.excelData.length == 0"
      >
        <v-data-table-server
          :headers="selectedHeaders"
          :items="tableList"
          v-model:sort-by="sortBy"
          item-value="name"
          item-key="id"
          fixed-header
          :height="tableList?.length > 10 ? 700 : null"
          id="vDataTable"
          fixed-footer
          density="compact"
          class="elevation-1"
        >
          <template v-slot:item="{ item, index }">
            <tr>
              <td v-for="(value, key) in item.columns" :key="key">
                <v-lazy>
                  <span v-if="key == 'index_no'">
                    {{ index + 1 }}
                  </span>
                  <span v-else-if="key == 'shared_before'">{{
                    value ? $t("globals.yes") : $t("globals.no")
                  }}</span>
                  <span
                    v-if="key === 'actions'"
                    style="min-width: 100px !important"
                  >
                    <v-btn
                      variant="text"
                      class="me-1 v-btn-icon"
                      size="large"
                      v-if="
                        CheckGetPermission(
                          'opensummercenter.change_schoolsummeropen'
                        )
                      "
                      @click="editEmployee(item.raw)"
                    >
                      <v-icon color="warning">mdi-pencil-outline</v-icon>
                    </v-btn>
                    <v-btn
                      variant="text"
                      size="small"
                      class="v-btn-icon"
                      @click="deleteItem(item.raw)"
                      v-if="
                        CheckGetPermission(
                          'opensummercenter.delete_schoolsummeropen'
                        )
                      "
                    >
                      <v-icon color="error">mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </span>
                  <span v-else>
                    {{ value }}
                  </span>
                </v-lazy>
              </td>
            </tr>
          </template>
          <template v-slot:bottom>
            <div class="d-flex" id="pagination-bar">
              <v-pagination
                v-model="page"
                :length="pagination.num_pages"
                density="compact"
                show-first-last-page
              >
              </v-pagination>
              <div class="d-flex">
                <v-select
                  v-model="perPage"
                  class="pa-0"
                  :items="itemsPerPage"
                  item-value="value"
                  item-title="text"
                  density="compact"
                  hide-details
                  variant="text"
                >
                </v-select>
                <span class="mt-2 px-2"
                  >{{ $t("globals.per-page") }} {{ $t("globals.from") }} [
                  {{ pagination.count }} ] {{ $t("globals.item") }}</span
                >
              </div>
            </div>
          </template>
        </v-data-table-server>
      </v-card>
      <div v-else>
        <v-card>
          <v-locale-provider :locale="$i18n.locale">
            <v-data-table
              :headers="selectedHeaders"
              :items="excelData"
              item-value="name"
              item-key="id"
              id="vDataTable"
              fixed-footer
              :search="txt_search"
              density="compact"
              fixed-header
              :height="excelData?.length > 9 ? 400 : null"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td v-for="(value, key) in item.columns" :key="key">
                    <v-lazy>
                      {{ value }}
                    </v-lazy>
                  </td>
                </tr>
              </template>
              <template v-slot:footer.prepend
                ><span class="me-2">{{
                  $t("globals.per-page")
                }}</span></template
              >
            </v-data-table>
          </v-locale-provider>
        </v-card>
        <v-btn
          block
          @click="saveImportedEmployees"
          :loading="saveImportRecordsProgress"
          class="mt-2"
          color="blue-darken-2"
        >
          <v-icon size="17" class="me-2">mdi-database-arrow-up-outline</v-icon>
          <span class="text-white"
            >{{ $t("import.import-records-to-db") }}
            <span class="mx-2">{{ excelData?.length }}</span>
            {{ $t("globals.record") }}</span
          >
        </v-btn>
      </div>
      <v-card v-if="employees_with_errors?.length>0"  class="mt-2">
        <v-card-title class="py-2 bg-indigo d-flex justify-space-between"> 
          <span class="text-white">الأخطاء الموجودة في الملف</span>
          <v-icon color="error" size="25" @click="employees_with_errors=[]">mdi-close</v-icon>  
        </v-card-title>
        <v-table
          density="compact"
          fixed-header
          height="400"
        > 
        <thead>
          <tr>
            <th>{{$t('globals.no')}}</th>
            <th>{{$t('globals.employee-name')}}</th>
            <th>{{$t('globals.error')}}</th>
            <th>{{$t('globals.value')}}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(error,index) in employees_with_errors" :key="index">
            <td>
              <v-lazy>{{index+1}}</v-lazy>
            </td>
            <td>
              <v-lazy>
                {{error.employee}}
              </v-lazy>
            </td>
            <td>
              <v-lazy>
                {{error.error}}
              </v-lazy>
            </td>
            <td>
              <v-lazy>
                {{error.value}}
              </v-lazy>
            </td>
          </tr>
        </tbody>
        </v-table>
      </v-card>
    </v-card-text>
  </v-card>
  <v-dialog v-model="dialog" max-width="900" persistent>
    <v-form ref="form" @keydown.enter="updateEmployee">
      <v-card
        :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
        class="text-main-color overflow-auto"
        max-height="700 "
      >
        <v-card-title class="py-2 bg-indigo mb-4">
          <v-icon size="30" class="me-2">mdi-account-tie</v-icon>
          <span class="text-h6">{{ $t("employee.data") }}</span>
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" md="8">
              <VTextField
                v-model="employee.name_ar"
                autofocus
                counter="100"
                :label="$t('globals.employee-name')"
                persistent-hint
                ref="employee_name"
                clearable
                density="compact"
                prepend-inner-icon="mdi-account-tie"
                @input="v$.employee.name_ar.$touch"
                :error-messages="
                  v$.employee.name_ar.$errors.map((e) => e.$message)
                "
              ></VTextField>
            </v-col>
            <v-col cols="6" md="4">
              <DatetimePicker
                v-model="employee.birthdate"
                :calendar="'gregorg'"
                :placeholder="$t('globals.year-month-day')"
                :label="$t('school.student_birthdate')"
                @input="v$.employee.birthdate.$touch"
                :error-messages="
                  v$.employee.birthdate.$errors.map((e) => e.$message)
                "
              ></DatetimePicker>
            </v-col>
            <v-col cols="6" md="4">
              <AutoSelect
                :items="genders"
                v-model="employee.gender"
                item-title="name"
                item-value="id"
                :label="$t('globals.gender')"
                persistent-hint
                density="compact"
                prepend-inner-icon="mdi-gender-male-female"
                @input="v$.employee.gender.$touch"
                :error-messages="
                  v$.employee.gender.$errors.map((e) => e.$message)
                "
              ></AutoSelect>
            </v-col>
            <v-col cols="6" md="4">
              <AutoSelect
                :items="roles"
                v-model="employee.role"
                item-title="name"
                item-value="id"
                :label="$t('summer.work-type')"
                persistent-hint
                prepend-inner-icon="mdi-list-status"
                density="compact"
                @input="v$.employee.role.$touch"
                :error-messages="
                  v$.employee.role.$errors.map((e) => e.$message)
                "
              ></AutoSelect>
            </v-col>
            <v-col cols="6" md="4">
              <VTextField
                v-model="employee.entity_type"
                :label="$t('summer.employee.place-of-work')"
                persistent-hint
                clearable
                counter="100"
                density="compact"
                prepend-inner-icon="mdi-account-hard-hat"
                @input="v$.employee.entity_type.$touch"
                :error-messages="
                  v$.employee.entity_type.$errors.map((e) => e.$message)
                "
              ></VTextField>
            </v-col>
            <v-col cols="6" md="4">
              <VTextField
                v-model="employee.identity_id"
                :label="$t('globals.identity-id')"
                persistent-hint
                clearable
                counter="15"
                density="compact"
                type="number"
                prepend-inner-icon="mdi-numeric"
                @input="v$.employee.identity_id.$touch"
                :error-messages="
                  v$.employee.identity_id.$errors.map((e) => e.$message)
                "
              ></VTextField>
            </v-col>
            <v-col cols="6" md="4">
              <AutoSelect
                :items="qualifications"
                v-model="employee.qualifications"
                item-title="name"
                item-value="id"
                :label="$t('globals.qualification')"
                persistent-hint
                density="compact"
                prepend-inner-icon="mdi-certificate-outline"
                clearable
                @input="v$.employee.qualifications.$touch"
                :error-messages="
                  v$.employee.qualifications.$errors.map((e) => e.$message)
                "
              ></AutoSelect>
            </v-col>
            <v-col cols="6" md="4">
              <VTextField
                v-model="employee.specialization"
                :label="$t('globals.specialization')"
                persistent-hint
                clearable
                counter="50"
                density="compact"
                prepend-inner-icon="mdi-clipboard-account"
                @input="v$.employee.specialization.$touch"
                :error-messages="
                  v$.employee.specialization.$errors.map((e) => e.$message)
                "
              ></VTextField>
            </v-col>
            <v-col cols="6" md="4">
              <AutoSelect
                :items="skills"
                multiple
                v-model="employee.skill"
                item-title="title"
                item-value="id"
                :label="$t('globals.skills')"
                persistent-hint
                density="compact"
                prepend-inner-icon="mdi-account-star"
                clearable
              ></AutoSelect>
            </v-col>
            <v-col cols="6" md="4">
              <VTextField
                v-model="employee.phone_number"
                :label="$t('summer.employee.mobile-number')"
                persistent-hint
                clearable
                density="compact"
                type="number"
                prepend-inner-icon="mdi-cellphone-text"
                @input="v$.employee.phone_number.$touch"
                :error-messages="
                  v$.employee.phone_number.$errors.map((e) => e.$message)
                "
              ></VTextField>
            </v-col>
            <v-col cols="6" md="4">
              <AutoSelect
                :items="filterGovernorates(1)"
                v-model="employee.fk_governorate"
                item-title="name_ar"
                item-value="id"
                :label="$t('globals.governorate')"
                persistent-hint
                density="compact"
                prepend-inner-icon="mdi-city"
                @update:modelValue="employee.fk_directorate = null"
              ></AutoSelect>
            </v-col>
            <v-col cols="6" md="4">
              <AutoSelect
                :items="filterDirectorates(employee.fk_governorate)"
                v-model="employee.fk_directorate"
                item-title="name_ar"
                item-value="id"
                :label="$t('globals.directorate')"
                persistent-hint
                density="compact"
                prepend-inner-icon="mdi-map-outline"
                @input="v$.employee.fk_directorate.$touch"
                :error-messages="
                  v$.employee.fk_directorate.$errors.map((e) => e.$message)
                "
              ></AutoSelect>
            </v-col>
            <v-col cols="6" md="4">
              <v-text-field
                counter="50"
                type="input"
                v-model="employee.address"
                density="compact"
                clearable
                prepend-inner-icon="mdi-map-marker"
                :label="$t('summer.solitude')"
                @input="v$.employee.address.$touch"
                :error-messages="
                  v$.employee.address.$errors.map((e) => e.$message)
                "
              />
            </v-col>
            <v-col cols="6" md="4">
              <v-checkbox
                class="mt-2"
                v-model="employee.shared_before"
                density="compact"
                hide-details="auto"
                :label="$t('employee.shared-in-summer')"
              />
            </v-col>

            <VCol cols="12">
              <VTextarea
                v-model="employee.note"
                clearable
                :label="$t('globals.note')"
                color="indigo"
                density="compact"
                hide-details="auto"
                rows="3"
                prepend-inner-icon="mdi-note-outline"
                @input="v$.employee.note.$touch"
                :error-messages="
                  v$.employee.note.$errors.map((e) => e.$message)
                "
              ></VTextarea>
            </VCol>
          </v-row>
        </v-card-text>
        <v-card-actions class="px-4 py-0">
          <v-btn
            v-if="update"
            class="bg-success"
            size="small"
            @click="updateEmployee"
            :loading="editDataLoading"
          >
            <span class="text-white">
              {{ $t("globals.edit") }}
            </span>
            <VIcon icon="mdi-content-save" color="white" end></VIcon>
          </v-btn>
          <v-btn size="small" @click="dialog = false">{{
            $t("globals.cancel")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
  <delete-dialog
    v-model="del_dialog"
    :loading="deleteDataLoading"
    @confirm-delete="deleteEmployee()"
  ></delete-dialog>
  <v-dialog
    v-model="excel_sheet_dialog"
    max-width="500"
    max-height="700"
    persistent
  >
    <v-card :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="text-main-color">
      <v-card-title class="py-4 bg-indigo text-h6"
        ><v-icon class="me-2" size="20">mdi-file-excel-outline</v-icon
        >{{ $t("globals.select-excel-sheet") }}</v-card-title
      >
      <v-card-text>
        <p>{{ $t("globals.there-are-many-sheets") }}</p>
        <v-radio-group v-model="excel_sheet_id" color="indigo">
          <v-card
            class="mt-2 border rounded d-flex align-center px-1"
            v-for="(sheet, index) in excel_sheets"
            :key="index"
            elevation="0"
          >
            <v-radio
              class="py-2"
              :label="sheet.name"
              :value="sheet.id"
            ></v-radio>
            <span class="text-medium-emphasis">
              {{ $t("globals.record") }} ({{ sheet.rowCount }})</span
            >
          </v-card>
        </v-radio-group>
      </v-card-text>
      <v-card-actions class="px-3">
        <v-btn
          :disabled="!excel_sheet_id"
          class="bg-indigo"
          size="small"
          @click="selectExcelSheet()"
          >{{ $t("import.import") }}</v-btn
        >
        <v-btn @click="excel_sheet_dialog = false" size="small">{{
          $t("globals.cancel")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import Datetime from "vue3-datetime-js";
import useValidate from "@vuelidate/core";
import AutoSelect from "@/components/Globals/AutoSelect";
import { required, helpers, maxLength, minLength } from "@vuelidate/validators";
import Exceljs from "exceljs";
export default {
  data() {
    return {
      employees_with_errors:[],
      excel_sheet_id: null,
      selectSheetLoading: false,
      excel_sheets: [],
      excel_sheet_dialog: false,
      DataLoading: false,
      errors_of_fields: [],
      del_dialog: false,
      deleteDataLoading: false,
      editDataLoading: false,
      employee: {
        name_ar: null,
        birthdate: null,
        gender: null,
        qualifications: null,
        role: null,
        phone_number: null,
        entity_type: null,
        identity_id: null,
        fk_directorate: null,
        address: null,
        skill: [],
        specialization: null,
        shared_before: false,
        fk_errorofiled: [],
        is_review: false,
        note: null,
      },
      selected_employee: null,
      dialog: false,
      saveImportRecordsProgress: false,
      importProgress: false,
      exportProgress: false,
      protected_records: false,
      fk_governorate: null,
      fk_directorate: null,
      excelData: [],
      fk_summer: null,
      fk_role: null,
      summer_schools: [],
      data_message: [],
      tab: null,
      groups_list: [],
      employees: [],
      page: 1,
      perPage: 10,
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
      itemsPerPage: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 1000, text: "1000" },
      ],
      sortBy: [
        {
          key: "name_ar",
          order: "asc",
        },
      ],

      dialogVisible: false,
      v$: useValidate(),
      is_update: false,
      updated_id: undefined,
      delete_id: undefined,
      user_delete_id: undefined,
      selectedHead: [
        "index_no",
        "name_ar",
        "birthdate",
        "gender_type",
        "role_name",
        "user_username",
        "user_email",
        "state",
        "actions",
        "group_user_name",
      ],
      excel_headers: [
        { header: this.$t("globals.#"), key: "index" },
        { header: this.$t("globals.governorate"), key: "school_governorate" },
        { header: this.$t("globals.directorate"), key: "school_directorate" },
        {
          header: this.$t("summer.serial-number"),
          key: "school_serial_number",
        },
        { header: this.$t("summer.place-name"), key: "school_place" },
      ],
      headers: [
        { title: this.$t("globals.#"), key: "index_no" },
        { title: this.$t("summer.name-employees"), key: "name_ar" },
        { title: this.$t("globals.birth_date"), key: "birthdate" },
        { title: this.$t("globals.gender"), key: "gender_type" },
        {
          title: this.$t("summer.employee.qualifications"),
          key: "qualifications_name",
        },
        { title: this.$t("summer.work-type"), key: "role_name" },
        {
          title: this.$t("summer.employee.mobile-number"),
          key: "phone_number",
        },
        { title: this.$t("summer.card-number"), key: "identity_id" },
        { title: this.$t("globals.address"), key: "address" },
        { title: this.$t("globals.skills"), key: "skills_names" },
        { title: this.$t("summer.employee.place-of-work"), key: "entity_type" },
        { title: this.$t("globals.job-id"), key: "job_id" },
        { title: this.$t("summer.shift-time"), key: "work_period_name" },
        { title: this.$t("globals.governorate"), key: "governorate_name" },
        { title: this.$t("globals.directorate"), key: "directorate_name" },
        {
          title: this.$t("summer.accommodation-type"),
          key: "accommodation_type_name",
        },
        { title: this.$t("employee.shared-in-summer"), key: "shared_before" },
        { title: this.$t("globals.note"), key: "note", sortable: false },
        { title: this.$t("globals.actions"), key: "actions", sortable: false },
      ],
      txt_search: undefined,
    };
  },
  components: { Datetime, AutoSelect },
  computed: {
    ...mapState({
      subjects: (state) => state.summer.subjects,
      countries: (state) => state.school.countries,
      directorates: (state) => state.school.directorate,
      employeeStates: (state) => state.summer.employeeStates,
      nationalities: (state) => state.school.nationalities,
      governorates: (state) => state.school.governorates,
      directorates: (state) => state.school.directorate,
      roles: (state) => state.summer.roles,
      user: (state) => state.User,
      role: (state) => state.role,
      qualifications: (state) => state.summer.qualifications,
      genders: (state) => state.summer.genders,
      skills: (state) => state.summer.skills,
    }),
    // role(){
    //   return localStorage.getItem('role')
    // },

    ...mapGetters({
      filterGovernorates: "school/getGovernoratesByCountry",
      filterDirectorates: "school/getDirectorateByGovernorates",
    }),
    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
    tableList() {
      if (this.employees) {
        return JSON.parse(JSON.stringify(this.employees));
      }
    },
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
    CheckGetPermission() {
      return (prem, role) => {
        return this.$store.getters.checkpermission(prem, role);
      };
    },
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getEmployees();
      await this.getGovernorates();
      await this.getDirectorate();
      await this.getRoles();
      await this.getGenders();
      await this.getQualifications();
      await this.getEmployeesErrorsOfFields();
      await this.getSkills();

      // const role = localStorage.getItem('role')
      if (this.role == 2) {
        this.fk_governorate = localStorage.getItem("governorate");
      }
      if (this.role == 3) {
        this.fk_directorate = localStorage.getItem("directorate");
        this.getSummerSchools();
      }

      if (this.role >= 0 && this.role <= 3) {
        this.headers.splice(3, 0, {
          title: this.$t("summer.address-summer"),
          key: "summer_address",
        });
        this.headers.splice(2, 0, {
          title: this.$t("summer.summer-camp"),
          key: "school_summer",
        });
        this.selectedHead.push("school_summer");
        this.selectedHead.push("summer_address");
      }
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    getBooleanValue(val) {
      if (val == true || val == 1) {
        return val;
      }
      if (typeof date == "string") {
        if (
          ["yes", "نعم", "عم", "نع", "1"].includes(val?.trim().toLowerCase())
        ) {
          return 1;
        }
      }
      return 0;
    },
    getValidBirthday(date) {
      if (typeof date == "number") {
        return date;
      }
      return date?.replace(/\D/g, "")?.slice(0, 4);
    },
    getIntegerValue(val) {
      if (typeof val == "number") {
        return val;
      }
      return parseInt(val?.replace(/\D/g, "")) || undefined;
    },
    async getEmployeesErrorsOfFields() {
      await this.axios(
        `${this.base_url}/api/summer/school-open-employees-errors`
      ).then((res) => {
        this.errors_of_fields = res.data;
      });
    },
    resetForm() {
      this.dialog = false;
      this.$refs.form.reset();
      this.v$.$reset();
    },
    deleteItem(employee) {
      this.employee = Object.assign(employee);
      this.del_dialog = true;
    },
    editEmployee(employee) {
      this.employee = Object.assign({}, employee);
      this.dialog = true;
      this.update = true;
    },
    async updateEmployee() {
      this.v$.employee.$validate();
      if (!this.v$.employee.$error && this.employee) {
        this.editDataLoading = true;
        await this.axios
          .put(
            `${this.base_url}/api/summer/school-open-employees/${this.employee?.id}`,
            this.employee
          )
          .then((response) => {
            this.$emit("successAlert", this.$t("alert.success.update"));
            this.editDataLoading = false;
            this.getEmployees();
            this.resetForm();
          })
          .catch((error) => {
            console.log(error);
            this.$emit("errorAlert", this.$t("alert.failure.update"));
            this.editDataLoading = false;
          });
      } else {
        this.$emit("errorAlert", this.$t("globals.error_in_data"));
      }
    },
    async deleteEmployee() {
      if (this.employee?.id) {
        this.deleteDataLoading = true;
        await this.axios
          .delete(
            `${this.base_url}/api/summer/school-open-employees/${this.employee?.id}`
          )
          .then((response) => {
            this.$emit("successAlert", this.$t("alert.success.delete"));
            this.deleteDataLoading = false;
            this.del_dialog = false;
            this.getEmployees();
          })
          .catch((error) => {
            this.del_dialog = false;
            this.$emit("errorAlert", this.$t("alert.failure.delete"));
            this.deleteDataLoading = false;
          });
      } else {
        this.$emit("errorAlert", this.$t("globals.error_in_data"));
      }
    },
    async exportToExcel() {
      const currentYear = new Date().getFullYear();
      this.exportProgress = true;
      var error = false;
      var employees = [];
      await this.axios(`${this.base_url}/api/summer/school-open-employees`, {
        params: {
          role: this.fk_role,
          governorate: this.fk_governorate,
          directorate: this.fk_directorate,
          school_summer: this.fk_summer,
        },
      })
        .then((response) => {
          employees = JSON.parse(
            JSON.stringify(response.data.results, (key, value) =>
              value == null ? "" : value
            )
          );
          employees.forEach((emp, index) => {
            (emp.index = index + 1),
              (emp.shared_before = emp.shared_before ? "نعم" : "لا");
          });
        })
        .catch((err) => {
          this.$emit("errorAlert", this.$t("export.error-loading"));
          error = true;
        });
      if (error) {
        this.exportProgress = false;
        return 0;
      }
      const headers = [
        ...this.excel_headers,
        ...this.headers
          .filter(
            (el) =>
              ![
                "actions",
                "job_id",
                "summer_address",
                "work_period_name",
                "governorate_name",
                "directorate_name",
                "accommodation_type_name",
                "index_no",
              ].includes(el.key)
          )
          .map((item, index) => {
            return { header: item.title, key: item.key };
          }),
      ];
      const temp = headers[5];
      headers[5] = headers[6];
      headers[6] = temp;
      console.log(headers);
      const workbook = new Exceljs.Workbook();
      const worksheet = workbook.addWorksheet("Data");

      worksheet.columns = headers;
      worksheet.insertRow(1, {
        index: `  كشف بيانات العاملين في الدورات الصيفية المفتوحة  ${currentYear}`,
      });
      worksheet.insertRow(2, {
        index: "البيانات الأساسية للدورة الصيفية",
        name_ar: "بيانات العاملين الأساسية",
      });
      worksheet.mergeCells("A1:R1");
      worksheet.mergeCells("A2:F2");
      worksheet.mergeCells("G2:R2");
      const head_1 = worksheet.getRow(1);
      const head_2 = worksheet.getRow(2);
      const head_3 = worksheet.getRow(3);
      head_1.eachCell((cell) => {
        cell.style = { font: { size: 16, bold: true } };
      });
      head_2.eachCell((cell) => {
        cell.style = {
          font: { size: 14, bold: true, color: { argb: "FFFFFF" } },
          fill: {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "2F75B5" },
          },
        };
      });
      head_3.eachCell((cell) => {
        cell.style = {
          font: { size: 12, bold: true, color: { argb: "FFFFFF" } },
          fill: {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "2F75B5" },
          },
        };
      });
      const rows = worksheet.getRows(1, 3);
      rows.forEach((row, index) => {
        row.height = 24;
      });
      worksheet.addRows(employees);
      worksheet.eachRow((row, index) => {
        if (index > 3) row.height = 20;
        if (index > 2) {
          row.values.forEach((value, col) => {
            const column = worksheet.getColumn(col);
            const cell = value ? value.length : 0;
            if (cell > column.width) {
              column.width = cell + 1;
            }
          });
        }
        row.eachCell((cell) => {
          cell.alignment = { horizontal: "center", vertical: "middle" };
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
            bottom: { style: "thin" },
          };
        });
      });
      const file_name = `بيانات العاملين.xlsx`;
      workbook.xlsx.writeBuffer().then((buffer) => {
        const data = new Blob([buffer], { type: "application/octet-stream" });
        saveAs(data, file_name);
        this.exportProgress = false;
      });
      // await this.$ExportToExcel(headers,employees,'بيانات العاملين').then(res=>{
      //   this.exportProgress=false
      // }).catch(err=>{
      //   this.exportProgress=false
      // })
    },
    async saveImportedEmployees() {
      this.saveImportRecordsProgress = true;
      await this.axios
        .post(
          `${this.base_url}/api/summer/import-school-open-employees`,
          this.excelData
        )
        .then((resp) => {
          this.saveImportRecordsProgress = false;
          this.$emit("successAlert", this.$t("alert.success.save"));
          this.excelData = [];
          if(resp.data?.length>0){
            this.employees_with_errors = resp.data
          }
          this.getEmployees();
        })
        .catch((err) => {
          this.saveImportRecordsProgress = false;
          this.$emit("errorAlert", this.$t("alert.failure.save"));
          this.excelData = [];
        });
    },
    handelImportBtn() {
      this.$refs.excelInput.click();
    },
    resetExcelFileSelected() {
      this.$refs.excelInput.value = "";
    },
    fillDataTable(sheet) {
      if (sheet) {
        sheet?.eachRow((row, rowIndex) => {
          if (rowIndex > 3) {
            this.excelData.push({
              index_no: row.getCell(1).value,
              school_serial_number: row.getCell(4).value,
              school_summer: row.getCell(5).value,
              name_ar: row.getCell(7).value,
              birthdate: this.getValidBirthday(row.getCell(8).value),
              summer_address:
                row.getCell(2).value + " - " + row.getCell(3).value,
              gender_type: row.getCell(9).value,
              specialization:
                row.getCell(10).value + " - " + row.getCell(11).value,
              role_name: row.getCell(12).value,
              phone_number: row.getCell(13).value,
              identity_id: this.getIntegerValue(row.getCell(14).value),
              address: row.getCell(15).value,
              skills_names: row.getCell(16).value,
              entity_type: row.getCell(17).value,
              shared_before: this.getBooleanValue(row.getCell(18).value),
            });
          }
        });
      }
      console.log(this.excelData);
    },
    async selectExcelSheet() {
      this.selectSheetLoading = true;
      if (this.excel_sheet_id) {
        const sheet = this.excel_sheets?.find(
          (sheet) => sheet.id == this.excel_sheet_id
        );
        if (sheet) {
          await this.fillDataTable(sheet);
        }
      }
      this.excel_sheet_dialog = false;
    },
    async importExcelFile(event) {
      this.excel_sheet_id = null;
      this.excelData = [];
      const file = event.target.files[0];
      const workbook = new Exceljs.Workbook();
      if (file) {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = () => {
          this.importProgress = true;
          const buffer = reader.result;
          workbook.xlsx.load(buffer).then((WB) => {
            if (WB?.worksheets?.length > 1) {
              this.excel_sheets = WB.worksheets;
              this.excel_sheet_dialog = true;
            } else {
              this.fillDataTable(WB.worksheets[0]);
            }
            this.importProgress = false;
          });
        };
      }
    },
    async getSummerSchools() {
      await this.axios
        .get(`${this.base_url}api/summer/school-open-list`, {
          params: {
            directorate: this.fk_directorate,
            governorate: this.fk_governorate,
          },
        })
        .then((response) => {
          this.summer_schools = response.data;
        });
    },
    ...mapActions({
      getCountries: "school/getCountries",
      getRoles: "summer/getRoles",
      getGovernorates: "school/getGovernorates",
      getDirectorate: "school/getDirectorate",
      getGenders: "summer/getGenders",
      getQualifications: "summer/getQualifications",
      getSkills: "summer/getSkills",
    }),
    async getEmployees(page = 1) {
      this.DataLoading = true;
      await this.axios(`${this.base_url}/api/summer/school-open-employees`, {
        params: {
          search: this.txt_search,
          page: page,
          page_size: this.perPage,
          sort_by: this.order_data,
          role: this.fk_role,
          governorate: this.fk_governorate,
          directorate: this.fk_directorate,
          school_summer: this.fk_summer,
        },
      })
        .then((response) => {
          this.employees = response.data.results;
          this.pagination = response.data.pagination;
          this.length = response.data.pagination.num_pages;
          this.DataLoading = false;
        })
        .catch((error) => {
          this.DataLoading = false;
        });
    },
  },
  validations() {
    return {
      employee: {
        name_ar: {
          maxLength: helpers.withMessage(
            this.$t("errors.max-entry"),
            maxLength(100)
          ),
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
        birthdate: {
          minLength: helpers.withMessage(" تاريخ غير صحيح", minLength(4)),
        },
        gender: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
        qualifications: {},
        role: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
        phone_number: {
          maxLength: helpers.withMessage(
            this.$t("errors.max-entry"),
            maxLength(14)
          ),
        },
        entity_type: {
          maxLength: helpers.withMessage(
            this.$t("errors.max-entry"),
            maxLength(100)
          ),
        },
        identity_id: {
          maxLength: helpers.withMessage(
            this.$t("errors.max-entry"),
            maxLength(15)
          ),
          minLength: helpers.withMessage(
            this.$t("globals.lowest_number_of_character") + "5",
            minLength(5)
          ),
        },
        fk_directorate: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
        address: {
          maxLength: helpers.withMessage(
            this.$t("errors.max-entry"),
            maxLength(50)
          ),
        },
        skill: {},
        specialization: {
          maxLength: helpers.withMessage(
            this.$t("errors.max-entry"),
            maxLength(50)
          ),
        },
        shared_before: {},
        note: {
          maxLength: helpers.withMessage(
            this.$t("errors.max-entry"),
            maxLength(250)
          ),
        },
      },
    };
  },
  watch: {
    perPage() {
      this.getEmployees();
    },
    page() {
      this.getEmployees(this.page);
    },
    order_data() {
      this.getEmployees(this.page);
    },
  },
};
</script>

<style>
</style>
