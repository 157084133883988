<template>
  <v-card v-if="!exist">
    <v-card-text>
      <h3 class="text-grey-darken-2">{{ $t("globals.select-criteria") }}</h3>
      <v-row class="my-2 mt-6">
        
        <v-col cols="6" md="3" sm="4" class="mb-1" v-if="role>=0&&role<=1">
          <VAutocomplete
            :items="filterGovernorates(1)"
            v-model="fk_governorate"
            item-title="name_ar"
            item-value="id"
            :label="$t('globals.governorate')"
            persistent-hint
            density="compact"
            hide-details
            clearable
            :no-data-text="$t('governorate.not-found')"
            prepend-inner-icon="mdi-city"
            >
          </VAutocomplete>
        </v-col>
        <v-col cols="6" md="3" sm="4" class="mb-1" v-if="role>=0&&role<=2">
          <VAutocomplete
            :items="studentTypes"
            v-model="student_type"
            item-title="name"
            item-value="id"
            :label="$t('school.students_type')"
            persistent-hint
            hide-details
            density="compact"
            clearable
            :no-data-text="$t('directorate.not-found')"
            prepend-inner-icon="mdi-map-outline"
            
          ></VAutocomplete>
        </v-col>
        <v-col cols="6" md="2" sm="4" class="mt-2">
          <v-btn
            density="comfortable"
            append-icon="mdi-presentation"
            class="w-100 text-white"
            color="indigo"
            :loading="loading"
            @click="getData"
          >
            <span>{{ $t("globals.show") }}</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>

  <v-card flat v-if="exist" id="report" >
    <ReportHeader class="pb-0">
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          <h3 >
            {{ $t("report.students-dropout") }}
          </h3>
        </div>
      </template>
    </ReportHeader>
    <v-card-text class="mt-4">
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <!-- search box  -->

        <div class="d-flex justify-end" style="flex: 1">
          <!-- <v-btn
            density="compact"
            class="pa-0 mx-1"
            style="min-width: 25px"
            @click="exportToExcel"
          >
            <v-icon color="green-darken-2">mdi-file-excel</v-icon>
            <v-tooltip class="tooltip" activator="parent" location="top" >
              <small>{{ $t("export.excel") }}</small>
            </v-tooltip>
          </v-btn> -->
          <v-btn
            density="compact"
            class="pa-0 mx-1"
            style="min-width: 25px"
            @click="printTable"
          >
            <v-icon color="icon-color">mdi-printer</v-icon>
            <v-tooltip class="tooltip" activator="parent" location="top">
              <small>{{ $t("globals.print-file") }}</small>
            </v-tooltip>
          </v-btn>

          <v-btn
            density="compact"
            @click="(exist = false)"
          >
            <span class="text-indigo">{{ $t("globals.back") }}</span>
          </v-btn>
        </div>
      </div>
      <div id="myTable">
        <v-card  class="pa-2" v-if="items?.data?.length<1">
          <h3 class="text-center text-grey-darken-2">
            {{ $t("globals.not-found-items") }}
          </h3>
        </v-card>
        <v-card
          
        >
          <table class="report-t text-center" ref="table">
            <thead>
              <tr>
                <th>{{$t('globals.directorate')}}</th>
                <th>{{$t('summer.registered-school')}}</th>
                <th> 
                    <span>{{$t('summer.registered-total')}}</span>
                    <br>
                    <span v-if="student_type==1">{{$t('globals.males')}}</span>
                    <span v-if="student_type==2">{{$t('globals.females')}}</span>
                </th>
                <th> 
                    <span>{{$t('summer.regular-total')}}</span>
                    <br>
                    <span v-if="student_type==1">{{$t('globals.males')}}</span>
                    <span v-if="student_type==2">{{$t('globals.females')}}</span>
                </th>
                <th>
                    <span>{{$t('summer.dropout-total')}}</span>
                    <br>
                    <span v-if="student_type==1">{{$t('globals.males')}}</span>
                    <span v-if="student_type==2">{{$t('globals.females')}}</span>
                </th>
                <th>
                    <span v-if="student_type!=1&&student_type!=2">{{$t('globals.percent')}}</span>
                    <span v-else>{{$t('summer.dropout-percent')}} <br></span>
                    <span v-if="student_type==1">{{$t('globals.males')}}</span>
                    <span v-if="student_type==2">{{$t('globals.females')}}</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in items.data" :key="index">
                <td>{{item?.directorate}}</td>
                <td>{{item?.schools}}</td>
                <td>{{item?.registered}}</td>
                <td>{{item?.regular}}</td>
                <td>{{item?.dropout}}</td>
                <td>{{item?.percent}}%</td>
              </tr>
            </tbody>
            <tfoot>
                <tr>
                    <th>{{$t('globals.grand-total')}}</th>
                    <th>{{items?.total?.schools??0}}</th>
                    <th>{{items?.total?.registered??0}}</th>
                    <th>{{items?.total?.regular??0}}</th>
                    <th>{{items?.total?.dropout??0}}</th>
                    <th>{{items?.total?.percent??0}}%</th>
                </tr>
            </tfoot>
          </table>
        </v-card>
      </div>
    </v-card-text>
  </v-card>

</template>

<script>
import ReportHeader from "@/components/SummerGlobals/ReportHeader.vue";
import { mapState, mapActions, mapGetters } from "vuex";

import { required, helpers } from "@vuelidate/validators";
export default {
  components: { ReportHeader },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getGovernorates();
      await this.getStudentType();

      if(this.role==2){
        this.fk_governorate = parseInt(localStorage.getItem('governorate'))
      }
      if(this.role==3){
        await this.getData()
      }
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },

  data() {
    return {
      fk_governorate:null,
      student_type:null,
      exist: false,
      loading:false,
    };
  },
  computed: {
    ...mapState({
      governorates: (state) => state.school.governorates,
      role:(state)=>state.role,
      studentTypes: (state) => state.summer_globals.student_type,
    }),
    ...mapGetters({
      filterGovernorates: "school/getGovernoratesByCountry",
    }),
  },
  methods: {
    ...mapActions({
      getGovernorates: "school/getGovernorates",
      getStudentType: "summer_globals/getStudentType",
    }),
    
    async printTable() {
      window.print();
    },
    async getData() {
      this.loading = true;
      await this.axios
        .get(`${this.base_url}api/summer/school-open-dropout`, {
          headers: {
            "Content-Type": "application/json",
          },

          params: {
            governorate:this.fk_governorate,
            student_type:this.student_type,

          },
        })
        .then((response) => {
          this.loading = false;
          this.exist = true;
          this.items = response.data;
        })
        .catch((error) => {
          console.log(error.response.data);
          this.loading = false;
          this.exist = false;
        });
    },
  },
};
</script>

