<template>
  <v-card id="report">
    <ReportHeader class="pb-0">
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          <div class="text-h5">
            {{ $t('reportpublic.SchoolStatisticsMFSecondtReport') }}
          </div>
        </div>
      </template>
    </ReportHeader>
    <v-card-title>
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <div class="d-flex justify-end" style="flex: 1">
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                density="compact"
                class="pa-0 mx-1"
                style="min-width: 25px"
                @click="printTable((all = true))"
              >
                <v-icon color="icon-color">mdi-printer</v-icon>
                <v-tooltip class="tooltip" activator="parent" location="top">
                  <small>{{ $t("globals.print-file") }}</small>
                </v-tooltip>
              </v-btn>
            </template>
          </v-menu>
        </div>
      </div>
      <v-container>
        <v-row align="center">
          <v-col cols="auto" class="pl-0">
            <v-sheet class="px-2 chart-label-sheet" elevation="0" rounded="0">
              {{
               $t('reportpublic.SchoolStatisticsMFSecondtReport')
              }}
              {{ $t("reportpublic.InGovernorate") }}
              (
              {{
                this.$i18n.locale == "ar"
                  ? results.gov_name_ar
                  : results.gov_name_en
              }}
              )
            </v-sheet>
          </v-col>
          <v-col class="pa-0" cols="auto">
            <v-sheet
              :class="`border chart-label-arrow-${this.$i18n.locale} text-center`"
            >
              <h4>{{ totals.total_student }}</h4>
            </v-sheet>
          </v-col>
          <v-col cols="max" align-self="center" class="pa-0">
            <v-divider
              :thickness="1"
              class="border-opacity-100"
              color="black"
            ></v-divider>
          </v-col>
          <v-sheet
            color="black"
            height="6"
            width="6"
            style="border-radius: 50%"
          >
          </v-sheet>
        </v-row>
      </v-container>
    </v-card-title>
    <v-card-item>
     
          <table class="report-t">
            <thead>
              <tr>               
                <th rowspan="2"  style="width:50px">{{ $t("globals.#") }}</th>
                <th rowspan="2">{{ $t("reportpublic.directorate") }}</th>
                <th colspan="2">{{ $t("reportpublic.Open") }}</th>
                <th colspan="2">{{ $t("reportpublic.Typical") }}</th>
                <th rowspan="2">
                  {{ $t("reportpublic.TotalNumberOfStudents") }}
                </th>
                <th rowspan="2">
                  {{ $t("reportpublic.TotalNumberOfFStudents") }}
                </th>
                <th rowspan="2">
                  {{
                    $t("reportpublic.ATotal") +
                    " " +
                    $t("reportpublic.Students")
                  }}
                </th>
              </tr>
              <tr>
                
                <template v-for="index in 2" :key="index">
                  
                  <th>{{ $t("reportpublic.MaleStudents") }}</th>
                  <th>{{ $t("reportpublic.FemaleStudents") }}</th>
                </template>
              </tr>

              <tr></tr>
            </thead>
            <tbody v-for="(val, key) in data" :key="key">
              <tr>
              <td>{{key+1}}</td>

                <td>
                  {{
                    this.$i18n.locale == "ar"
                      ? val.directorate_name_ar
                      : val.directorate_name_en
                  }}
                </td>
             

                <td class="text-center">{{ val.total_open_school_male }}</td>
                <td class="text-center">{{ val.total_open_school_fmale }}</td>

                <td class="text-center">{{ val.total_typical_school_male }}</td>
                <td class="text-center">{{ val.total_typical_school_fmale }}</td>

                <td class="text-center">{{ val.total_student_male }}</td>
                <td class="text-center">{{ val.total_student_fmale }}</td>
                <td class="text-center">{{ val.total_student }}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th colspan="2">
                  {{ $t("reportpublic.GrandTotal") }}
                </th>
                
              
                <th>
                  {{ totals.total_student__school_open_male }}
                </th>
                <th>
                  {{ totals.total_student__school_open_fmale }}
                </th>
                <th>
                  {{ totals.total_student__school_typical_male }}
                </th>
                <th>
                  {{ totals.total_student__school_typical_fmale }}
                </th>
                <th>{{ totals.total_student_male }}</th>
                <th>{{ totals.total_student_fmale }}</th>
                <th>{{ totals.total_student }}</th>
              </tr>
            </tfoot>
          </table>
  
    </v-card-item>
  </v-card>
</template>

<script>
;
import ReportHeader from "@/components/SummerGlobals/ReportHeader.vue";

export default {
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.fetchData();
      await this.$store.commit("updateLoadingValue", false);
    } catch (erroe) {
      await this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    async fetchData() {
      await  this.axios
        .get(
          `${this.base_url}public-reports/governorate-reports/student-statistics-by-gender`)
        .then((response) => {
          this.results = response.data.results;
          this.data = response.data.results.data;
          this.totals = response.data.results.total_gov;
        })

        .catch((error) => {});
    },
    async printTable(all) {
      if (all) {
        this.txt_search = null;
        this.perPage = this.totalItems;
        await this.fetchData();
      }
      window.print();
    },
  },
  data() {
    return {
      results: {},
      data: [],
      totals: [],
    };
  },
  computed: {
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },
  components: {
    ReportHeader,
  },
};
</script>
