<template>
  <VForm ref="form">
    <VCard class="mt-2">
      <v-card-text class="pb-0">
        <v-table
          density="compact"
          class="mt-1  elevation-1 rounded"
          v-if="levels_data.length > 0"
        >
          <thead>
            <tr>
              <th  class="text-center">{{$t('globals.number')}}</th>
              <th class="text-center">{{ $t("summer.levels") }}</th>
              <th  class="text-center" v-for="division in divisions" :key="division">
                {{ division.name }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(level, index) in levels_data" :key="level">
              <td  class="text-center">
                <span >{{ index + 1 }}</span>
              </td>
              <td  class="text-center">
                <span>{{ level.name }}</span>
              </td>
              <td v-for="(division,index) in divisions" :key="division" class="text-center">
                <div class=" d-inline-flex ">
                  <v-checkbox
                    density="compact"
                    :value="division.id"
                    v-model="level.divisions"
                    color="indigo"
                    hide-details
                    :disabled="(index<=level.divisions.length&&index>=level.divisions.length-1)?false:true"
                    @change="addLevelsToList(level.id)"
                  >
                  </v-checkbox>
                </div>
              </td>
            </tr>
          </tbody>
        </v-table>
        <v-card
          v-else-if="!loading || (!loading && levels_data.length > 0)"
          class="pa-2 "
        >
          <h3 class="text-center text-grey-darken-2">
            {{ $t("globals.not-found-items") }}
          </h3>
        </v-card>
      </v-card-text>
      <VCardActions class="mx-1" >
        <VBtn
          v-if="!is_update && CheckGetPermission('summer_center.add_level',['sys_admin','summer_admin','user'])"
          class="bg-indigo"
          @click="saveData"
          size="small"
          :loading="DataLoading"
        >
          <span class="text-white">
            {{ $t("globals.save") }}
          </span>
          <VIcon icon="mdi-content-save" color="white" end></VIcon>
        </VBtn>
      </VCardActions>
      <!-- ###################  Dialog Delete  ################### --->
    </VCard>
  </VForm>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  props: {
    id: {
      type: Number,
    },
  },
  data() {
    return {
      DataLoading: false,
      filter_divisions:[],
      divisions_selected:[],
      levels_changed:[],
      process_alert: undefined,
      icon_color: undefined,
      icon: undefined,
      alert_title: undefined,
      alert_message: undefined,
      levels_data:[]
    };
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.fk_year = localStorage.getItem("current_year");
      await this.getLevelsData()
      await this.getDivisions();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  computed: {
    ...mapState({
      divisions: (state) => state.summer.divisions,
      user: (state) => state.User,
    }),
    levels_divisions(){
      return this.levels_data.filter(item=>this.levels_changed.includes(item.id)).map(item=>{
        return {id:item.id,divisions:item.divisions}
      })
    },
    CheckGetPermission() {
      return (prem,role) => {
        return this.$store.getters.checkpermission(prem,role);
      };
    },
  },
  methods: {
    ...mapActions({
      getDivisions: "summer/getDivisions",
    }),
    addLevelsToList(id){
      if(!this.levels_changed.includes(id)){
        this.levels_changed.push(id)
      }
    },
    // resetForm() {
    //   this.divisions_selected=[]      
    // },
    async getLevelsData(){
      await this.axios(
            this.base_url + "api/summer/level-division-data")
          .then((response) => {
            this.levels_data=response.data
            this.DataLoading = false;
          })
          .catch((error) => {
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
            this.DataLoading = false;
          });
    },
    async saveData() {
      this.DataLoading=true
      await this.axios
        .post(this.base_url + "api/summer/level-division-data", this.levels_divisions)
        .then((response) => {
          this.$emit("successAlert", this.$t("globals.data_added"));
          this.levels_changed = [];
          this.DataLoading = false;
        })
        .catch((error) => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
          this.DataLoading = false;
        });
      }
  },
  
};
</script>
<style scoped>

</style>
