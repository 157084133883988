<template>
  <v-expansion-panels class="mb-2">
    <v-expansion-panel >
      <v-expansion-panel-title class="px-4">
        <span>{{ $t("globals.select-criteria") }}</span>
      </v-expansion-panel-title>
      <v-expansion-panel-text>
        <v-row class="py-1">
          <v-col v-if="role < 2" cols="6" md="3" lg="2" class="mt-1">
              <v-autocomplete
                v-model="fk_governorate"
                item-value="id"
                item-title="name_ar"
                prepend-inner-icon="mdi-city"
                :items="governorate"
                hide-details="auto"
                :label="$t('globals.governorate')"
                persistent-hint
                density="compact"
                clearable
                @update:modelValue="
                  getItems((page = 1)),
                    (fk_directorate = undefined),
                    (fk_schoolsummer = undefined),
                    (filter.fk_governorate = fk_governorate)
                "
              ></v-autocomplete>
          </v-col>
          <v-col  v-if="role < 3 || role == 8" lg="2"  cols="6" md="3" class="mt-1">
              <v-autocomplete
                v-model="fk_directorate"
                hide-details="auto"
                item-value="id"
                item-title="name_ar"
                prepend-inner-icon="mdi-map-outline"
                :items="getDirectorateByGovernorates(filter.fk_governorate)"
                :label="$t('globals.directorate')"
                persistent-hint
                density="compact"
                clearable
                @update:modelValue="
                  getItems((page = 1)),
                    sendSummerCenterRequest(fk_directorate),
                    (fk_schoolsummer = undefined)
                "
              ></v-autocomplete>
          </v-col>
          <v-col v-if="role < 4 || role == 8"   cols="6" md="3" class="mt-1">
              <v-autocomplete
                v-model="fk_schoolsummer"
                hide-details="auto"
                item-value="id"
                item-title="name_ar"
                prepend-inner-icon="mdi-domain"
                :items="view_centers"
                :label="$t('globals.summer-centre')"
                persistent-hint
                density="compact"
                clearable
                @update:modelValue="getItems((page = 1))"
              ></v-autocomplete>
          </v-col>
          <v-col  cols="6" md="3"  class="mt-1">
            <v-autocomplete
              color="indigo"
              hide-details="auto"
              v-model="fk_group"
              density="compact"
              item-title="name"
              item-value="pk"
              prepend-inner-icon="mdi-account-group"
              clearable
              :items="fk_schoolsummer ? [] : group_list"
              :label="$t('user.group')"
              @update:modelValue="getItems((page = 1))"
            ></v-autocomplete>
          </v-col>
          <v-col  cols="6" md="3" lg="2" class="mt-1">
            <v-autocomplete
              color="indigo"
              hide-details="auto"
              v-model="fk_role"
              density="compact"
              item-title="name"
              item-value="id"
              prepend-inner-icon="mdi-account-hard-hat"
              clearable
              :items="fk_schoolsummer ? [] : roleList"
              :label="$t('user.role')"
              @update:modelValue="getItems((page = 1))"
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
  <v-dialog
    v-model="resetDialog"
    width="300"
    :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
    class="text-center"
  >
    <v-card>
      <v-card-title>
        <h3>
          {{ $t("globals.confirm") }}
        </h3>
      </v-card-title>
      <v-divider />
      <v-card-text class="px-2 py-1">
        <h4>{{ selectedUser }}</h4>
        <small>{{ $t("user.confirm-reset") }}</small>
      </v-card-text>
      <v-divider />
      <v-card-actions class="pa-2 justify-space-between">
        <v-btn :loading="resetProgress" @click="resetPassword" class="w-50">
          <span class="text-error">{{ $t("globals.restore") }}</span>
        </v-btn>
        <v-divider vertical />
        <v-btn @click="resetDialog = false" class="w-50">
          <span class="text-indigo"> {{ $t("globals.cancel") }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <DataTable
    v-if=" CheckGetPermission('usermanage.view_user')"
    :items="tableList"
    :headers="headers"
    density="compact"
    :method="getItems"
    :create="
      () => {
        $router.push('add')
      }
    "
    :exports="exportToExcel"
    :pagination="pagination"
    :editItem="(item)=>{
      $router.push(`${item.id}/edit/`)
    }"
    :delItem="deleteItem"
    :del_perm="
      CheckGetPermission('usermanage.delete_user')
    "
    :edit_perm="
      CheckGetPermission('usermanage.change_user')
    "
    :add_perm="
      CheckGetPermission('usermanage.add_user')
    "
  >
  <template v-slot:item.reset="{item}">
    <v-btn 
      color="indigo"  
      rounded 
      density="compact"
      @click="
        (resetDialog = true),
        (selectedUser = item.raw.username),
        (selectedUserID = item.raw.id)
      "
    >
    <small>{{$t("globals.restore")}}</small></v-btn>
  </template>
  <template v-slot:item.groups="{item}">
    <v-chip
      v-for="group in getGroupNames(item.raw.groups).split(' - ')"
      size="small"
      class="ma-1"
      :key="group"
    >
      <span>{{ group }}</span>
    </v-chip>
  </template>
  <template v-slot:item.is_active="{item}">
    {{item.raw.is_active? "مفعل" : "معطل"}}
  </template>
  </DataTable>
  <v-dialog
    v-model="addDialog"
    max-width="700"
    :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
  >
    <v-card flat>
      <v-card-text class="pb-0">
        <VForm
          ref="form"
          class=""
          @submit.prevent="is_update ? updateItem() : saveItem()"
        >
          <VRow class="mt-2">
            <VCol cols="12" md="6">
              <VTextField
                v-model="schedule.quotas_start_time"
                :label="$t('school.schedule_start_time')"
                density="compact"
                type="time"
                clearable
                :error-messages="
                  v$.schedule.quotas_start_time.$errors.map((e) => e.$message)
                "
                ref="startWeek"
              >
              </VTextField>
            </VCol>
            <VCol cols="12" md="6">
              <VTextField
                v-model="schedule.quotas_end_time"
                :label="$t('school.schedule_end_time')"
                density="compact"
                type="time"
                clearable
                :error-messages="
                  v$.schedule.quotas_end_time.$errors.map((e) => e.$message)
                "
                ref="endWeek"
              >
              </VTextField>
            </VCol>
            <VCol cols="12" md="6">
              <VCardItem class="pa-0">
                <VTextField
                  clearable
                  v-model="schedule.quotas"
                  prepend-inner-icon="mdi-format-text"
                  :label="$t('school.schedule_quotas')"
                  :placeholder="$t('school.placeholder_schedule_quotas')"
                  density="compact"
                  type="text"
                  :error-messages="
                    v$.schedule.quotas.$errors.map((e) => e.$message)
                  "
                >
                </VTextField>
              </VCardItem>
            </VCol>
            <VCol cols="12" md="6">
              <VCardItem class="pa-0">
                <VRadioGroup inline v-model="schedule.period">
                  <VRadio
                    :label="$t('school.morning_period')"
                    :value="true"
                  ></VRadio>
                  <VRadio
                    :label="$t('school.evening_period')"
                    :value="false"
                  ></VRadio>
                </VRadioGroup>
              </VCardItem>
            </VCol>
            <VCol cols="12" >
              <VTextarea
                prepend-inner-icon="mdi-note-outline"
                v-model="schedule.note"
                clearable
                :label="$t('globals.note')"
                counter="250"
                no-resize
                hide-details="auto"
                density="compact"
                rows="2"
                color="indigo"
                :error-messages="
                  v$.schedule.note.$errors.map((e) => e.$message)
                "
              ></VTextarea>
            </VCol>
          </VRow>
        </VForm>
      </v-card-text>
      <VCardActions class="mx-4">
        <VBtnSave
          v-if="!is_update"
          @click="saveItem()"
          :loading="saveDataLoading"
        >
          {{ $t("globals.add") }}
        </VBtnSave>
        <VBtnUpdate
          v-if="is_update"
          @click="updateItem()"
          :loading="editDataLoading"
        >
          {{ $t("globals.edit") }}
        </VBtnUpdate>
        <VBtn class="mx-3" size="small" @click="resetForm()">
          {{ $t("globals.clear") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
      </VCardActions>
    </v-card>
  </v-dialog>
</template>

<script>
import useValidate from "@vuelidate/core";
import { mapState, mapActions, mapGetters } from "vuex";
import DataTable from "@/components/Globals/DataTable.vue";

export default {
  components:{
    DataTable
  },
  name: "summer_user_list",
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.axios.get(`${this.base_url}user-role/`).then((response) => {
        this.role_list = response.data;
      });
      this.getGovernorates();
      this.getDirectorate();
      await this.getItems();
      if (
        this.role >= 2 ||
        this.role == 8
      )
        this.filter.fk_governorate = localStorage.getItem("governorate");
      else if (this.role >= 3) {
        this.filter.fk_directorate = localStorage.getItem("directorate");
        this.getAllSummerCenters(this.filter.fk_directorate);
      }

      await this.axios
        .get(`${this.base_url}groups/?user=1`)
        .then((response) => {
          this.group_list = response.data;
        });
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  data() {
    return {
      selectedUser: "",
      addDialog:false,
      selectedUserID: null,
      resetProgress: false,
      resetDialog: false,
      protected_records: false,
      data_message: [],
      del_dialog: false,
      user_del_id: undefined,
      deleteProgress: false,
      filter: {
        fk_governorate: undefined,
        fk_directorate: undefined,
      },
      role_list: [],
      group_list: [],
      fk_group: undefined,
      fk_role: undefined,
      fk_governorate: undefined,
      fk_directorate: undefined,
      fk_schoolsummer: undefined,
      pagination: {},
      users_list: [],
      v$: useValidate(),
      headers: [
        { title: this.$t("user.username"), key: "username" },
        { title: this.$t("user.role"), key: "role_name", sortable: false },
        { title: this.$t("user.fname"), key: "first_name" ,show:false},
        { title: this.$t("user.lname"), key: "last_name" ,show:false},
        { title: this.$t("user.email"), key: "email" ,show:false},
        { title: this.$t("user.group"), key: "groups" },
        { title: this.$t("user.active"), key: "is_active" },
        {
          title: this.$t("user.reset-password"),
          key: "reset",
          sortable: false,
        },
        {
          title: this.$t("globals.governorate"),
          key: "governorate",
          sortable: false,
        },
        {
          title: this.$t("globals.directorate"),
          key: "directorate",
          sortable: false,
        },
        { title: this.$t("globals.actions"), key: "actions", sortable: false },
      ],
    };
  },
  computed: {
    dateToday() {
      const today = new Date();
      const options = { year: "numeric", month: "2-digit", day: "2-digit" };
      const dateString = today
        .toLocaleDateString("en-US", options)
        .replace(/\//g, "-");
      return dateString;
    },
    tableList(){
      if(this.users_list){
        return JSON.parse(JSON.stringify(this.users_list)); 
      }
    },
    ...mapState({
      user: (state) => state.User,
      governorate: (state) => state.school.governorates,
      all_summer_centers: (state) => state.summer.all_summer_centers,
      role: (state) => state.role,
    }),
    ...mapGetters({
      getDirectorateByGovernorates: "school/getDirectorateByGovernorates",
    }),
    view_centers() {
      if (this.fk_directorate != undefined ||this.role>= 3)
        return this.all_summer_centers;
      else return [];
    },
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
    CheckRole() {
      return (prem) => {
        return this.$store.getters.checkrole(prem);
      };
    },
    
    roleList() {
      return this.role_list.filter(
        (item) =>
          (this.role == "undefined" &&
            item.id < 5 &&
            item.id > 0) ||
          (this.role <= item.id &&
            item.id < 5 &&
            item.id > 0) ||
          (this.role == 4 && item.id > 3) ||
          (this.role <= 2 && item.id == 8) ||
          (this.role == 8 &&
            ((item.id < 5 && item.id > 2) || item.id == 8)) ||
          (this.role <= 1 && item.id == 9) ||
          (this.role == 9 && item.id >= 8)
      );
    },
    
  },
  methods: {
    convertToTableList(users_list) {
      let list = [];
      if (this.role_list)
        users_list.forEach((element) => {
          list.push({
            date_joined: element.date_joined,
            email: element.email ? element.email : "",
            first_name: element.first_name ? element.first_name : "",
            groups: this.getGroupNames(element.groups),
            id: element.id,
            is_active: element.is_active ? "مفعل" : "معطل",
            last_login: element.last_login,
            last_name: element.last_name ? element.last_name : "",
            role_name: element.role ? element.role_name : "",
            username: element.username ? element.username : "",
            governorate: element.governorate ? element.governorate : "",
            directorate: element.directorate ? element.directorate : "",
          });
        });
      return list;
    },
    async resetPassword() {
      this.resetProgress = true;
      await this.axios(`${this.base_url}api/user/reset-password`, {
        params: {
          user_id: this.selectedUserID,
        }
      })
        .then((response) => {
          this.$emit("successAlert", this.$t("alert.success.reset-password"));
          this.resetProgress = false;
          this.resetDialog = false;
        })
        .catch((error) => {
          this.$emit("successAlert", this.$t("alert.failure.reset-password"));
          this.resetProgress = false;
          this.resetDialog = false;
        });
    },
    async exportToExcel() {
      var users_list = await this.axios(`${this.base_url}userdata/`, {
        params: {
          group: this.fk_group,
          role: this.fk_role,
          governorate: this.fk_governorate,
          directorate: this.fk_directorate,
          schoolsummer: this.fk_schoolsummer,
          sys_type: 2,
        }
      }).then(res=>res.data)
      const table_list = this.convertToTableList(users_list)
      const headers = this.headers
        .filter((el) => !["actions", "reset"].includes(el.key))
        .map((item) => {
          return { header: item.title, key: item.key };
        });
      this.$ExportToExcel(headers, table_list, "بيانات المستخدمين");
    },
    ...mapActions({
      getGovernorates: "school/getGovernorates",
      getDirectorate: "school/getDirectorate",
      getAllSummerCenters: "summer/getAllSummerCenters",
    }),
    getGroupNames(groups) {
      let names = [];
      groups.forEach((g) => {
        let group = this.group_list.find((item) => item.pk == g);
        if (group != undefined) {
          names.push(group.name);
        }
      });
      return names.join(" - ");
    },
    getGroupName(id) {
      let group = this.group_list.find((item) => item.pk == id);
      if (group != undefined) return group.name;
      else return group;
    },
    editUser(id) {
      this.$router.push(`${id}/edit/`);
    },
    addUser() {
      // this.changeCurrentPage({page:this.length,count:this.perPage})
      this.$router.push("add");
    },
    sendSummerCenterRequest(dir) {
      if (dir) this.getAllSummerCenters(dir);
    },
    async deleteItem(delete_id) {
      if (delete_id) {
        var status;
        this.deleteProgress = true;
        let result = await this.axios
          .delete(`${this.base_url}userdata/${delete_id}/`)
          .then((responce) => status=true)
          .catch((error) => status=error);
        return status
      }
    },
    async getItems(page = 1,per_page=10,ordering='id',search=null) {
      this.loading = true;
      return await this.axios(`${this.base_url}userdata/`, {
        params: {
          search: search,
          page: page,
          page_size: per_page,
          sort_by: ordering,
          group: this.fk_group,
          role: this.fk_role,
          governorate: this.fk_governorate,
          directorate: this.fk_directorate,
          schoolsummer: this.fk_schoolsummer,
          sys_type: 2,
        }
      })
        .then((response) => {
          this.users_list = response.data.results;
          this.pagination = response.data.pagination;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
  watch: {
    resetDialog() {
      if (this.resetDialog == false) {
        this.selectedUser = "";
        this.selectedUserID = null;
      }
    },
    
  },
};
</script>

<style scoped>
.row-hover:hover {
  background: #d4d3d3;
}
</style>