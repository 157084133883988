<template>
  <v-card class="px-2 pt-1 my-4" style="height: 350px">
    <chart :option="totalOfStudentsMF" autoresize />
  </v-card>
</template>

<script>
export default {
  props: ["chartData"],
  created() {
    this.totalOfStudentsMF = {
      title: {
        text: this.chartData.school_type,
        subtext: `${this.chartData.summer_count}`,
        right: "center",
        textStyle: {
          fontFamily: "Almarai",
        },
        subtextStyle: {
          fontFamily: "Almarai",
          fontSize: 15,
        },
      },
      dataset: [
        {
          dimensions: [
            "name",
            this.$t("reportpublic.centers") +
              " " +
              this.$t("reportpublic.Males"),
            this.$t("reportpublic.centers") +
              " " +
              this.$t("reportpublic.Females"),
            this.$t("reportpublic.centers") +
              " " +
              this.$t("reportpublic.General"),
          ],
          source: this.chartData.directorates_data.map((item) => [
            this.$i18n.locale == "ar"
              ? item.directorate_name_ar
              : item.directorate_name_en,
            item.summer_males_count,
            item.summer_fameles_count,
            item.summer_general_count,
          ]),
        },
        {
          transform: {
            type: "sort",
            config: { dimension: "constant", order: "desc" },
          },
        },
      ],
      xAxis: {
        type: "category",
        axisLabel: {
          interval: 0,
          rotate: 60,
        },
      },
      yAxis: {
        type: "value",
        position: this.$i18n.locale == "ar" ? "right" : "",
      },
      series: [
        {
          type: "bar",
          itemStyle: { borderRadius: [4, 4, 0, 0] },
          label: {
            show: true,
            position: "top",
            rotate: "90",
            offset: [3, 7],
          },
          // barWidth: 20,
        },
        {
          type: "bar",
          itemStyle: { borderRadius: [4, 4, 0, 0] },
          label: {
            show: true,
            position: "top",
            rotate: "90",
            offset: [3, 7],
          },
          // barWidth: 20,
        },
        {
          type: "bar",
          itemStyle: { borderRadius: [4, 4, 0, 0] },
          label: {
            show: true,
            position: "top",
            rotate: "90",
            offset: [3, 7],
          },
          // barWidth: 20,
        },
      ],
      legend: {
        bottom: "bottom",
        top: "90%",
        left: "center",
        itemHeight: 10,
        itemWidth: 13,
        borderWidth: 1,
        borderColor: "black",
        bottom: "30%",
      },
      transform: {
        type: "sort",
        config: { dimension: "constant", order: "desc" },
      },

      toolbox: {
        feature: {
          saveAsImage: {
            title: this.$t("dashboard.save-as-png"),
          },
        },
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
        formatter: function (params) {
          let tooltipText = params[0].axisValue + "<br/>";
          params.forEach((item, index) => {
            tooltipText +=
              item.value[index + 1] +
              "&nbsp;&nbsp;&nbsp;" +
              item.marker +
              " " +
              item.seriesName +
              "<br/>";
          });

          return tooltipText;
        },
      },
      grid: {
        height: "50%",
        top: "20%",
      },
    };
  },
  methods: {
    async printTable(all) {
      if (all) {
        this.txt_search = null;
        this.perPage = this.totalItems;
        await this.fetchData();
      }
      window.print();
    },
  },
};
</script>
<style scoped>
.report-table tfoot th:last-child,
.report-table thead th:last-child,
.report-table td:last-child {
  width: 30%;
}
.report-table tfoot th:first-child,
.report-table thead th:first-child,
.report-table td:first-child {
  width: 22%;
}
</style>
