<template>
  <v-card  flat id="lectures-report" v-if="exist">
    <ReportHeader class="pb-0">
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          <div class="text-h5">{{ $t("report.study-clander") }}</div>
        </div>
      </template>
    </ReportHeader>

    <v-card-text class="mt-4">
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <div class="d-flex justify-end" style="flex: 1">
          
          <v-btn
                v-bind="props"
                density="compact"
                class="pa-0"
                @click="printTable"
                style="min-width: 25px"
              >
                <v-icon color="icon-color">mdi-printer</v-icon>
                <v-tooltip class="tooltip" activator="parent" location="top">
                  <small>{{ $t("globals.print-file") }}</small>
                </v-tooltip>
              </v-btn>
        </div>
      </div>

      <div id="myTable">
        <v-card v-if="items.length < 1" class="pa-2">
          <h3 class="text-center text-grey-darken-2">
            {{ $t("globals.not-found-items") }}
          </h3>
        </v-card>
        <v-card class="pt-2" :loading="loading" rounded="0" flat>
          <table id="stable" class="report-table" style="width: 100%; border-collapse: collapse">
            <thead class="table-light">
              <tr>
                <th class="bg-blue-grey-lighten-4">م</th>

                <th v-if="$i18n.locale === 'ar'" class="bg-blue-grey-lighten-4 pa-2pa-2">اليوم</th>
                <th v-if="$i18n.locale === 'en'" class="bg-blue-grey-lighten-4 pa-2pa-2">day</th>

                <th v-if="$i18n.locale === 'ar'" class="bg-blue-grey-lighten-4 pa-2pa-2">
                  تاريخ ميلادي
                </th>
                <th v-if="$i18n.locale === 'en'" class="bg-blue-grey-lighten-4 pa-2pa-2">date</th>

                <th v-if="$i18n.locale === 'ar'" class="bg-blue-grey-lighten-4 pa-2pa-2">
                  تاريخ هجري
                </th>
                <th v-if="$i18n.locale === 'en'" class="bg-blue-grey-lighten-4 pa-2pa-2">
                  Hijiri date
                </th>
                <th class="bg-blue-grey-lighten-4 pa-2pa-2">
                  {{ $t("summer.is-holidays") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, key) in items" :key="item.id">
                <td class="text-center border pp2">
                  {{ key + 1 }}
                </td>
                <td class="text-center border pp2">
                  {{ item.day_name }}
                </td>
                <td class="text-center border pp2">
                  {{ item.date_m }}
                </td>

                <td class="text-center border pp2">
                  {{ item.date }}
                </td>
                <td class="text-center border pp2">
                  <v-icon v-if="item.holiday == true" color="success"
                    >mdi-check-circle</v-icon
                  >
                  <v-icon v-if="item.holiday == false" color="error"
                    >mdi-close-circle</v-icon
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </v-card>
      </div>
    </v-card-text>
  </v-card>
  <Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
  >
  </Alert>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import ReportHeader from "@/components/SummerGlobals/ReportHeader.vue";
import useValidate from "@vuelidate/core";

import { required, helpers } from "@vuelidate/validators";
export default {
  components: { ReportHeader },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.$store.dispatch("updatePermission", localStorage.getItem("userinfo"));

      await this.getData();
      await this.getYears();
      this.current_year = this.getYearName(localStorage.getItem("current_year"));
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },

  data() {
    return {
      theaders: [],
      current_year: "",
      fees_types: [],
      sub_list: [],
      drivers: [],
      parents: [],
      sub_list_sub: [],
      process_alert: false,
      icon_color: undefined,
      alert_title: undefined,
      alert_message: undefined,
      v$: useValidate(),
      exist: false,
      items: [],
      loading: false,
      txt_search: "",
      selectedHead: ["id", "name", "adjective", "entity", "phone_number"],
      headers: [
        { title: "#", key: "id" },
        { title: this.$t("globals.name"), key: "name" },
        { title: this.$t("globals.adjective"), key: "adjective" },
        { title: this.$t("summer.employee.place-of-work"), key: "entity" },
        { title: this.$t("globals.phone_number"), key: "phone_number" },
      ],

      perPage: 25,
      length: 0,
      totalItems: 0,
      itemsPerPage: [
        { value: 5, text: "5" },
        { value: 10, text: "10" },
        { value: 15, text: "15" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 75, text: "75" },
        { value: 100, text: "100" },
        { value: "", text: this.$t("globals.all") },
      ],
      page: 1,
      pagination: {},
      filter_data: {},
    };
  },
  computed: {
    ...mapState({
      days: (state) => state.summer.days,
    }),

    ...mapGetters({
      getClassDivisions: "school/getClassDivisions",
    }),

    selectedHeaders() {
      return this.headers.filter((header) => this.selectedHead.includes(header.key));
    },
  },
  methods: {
    ...mapActions({
      getDays: "school/getDays",
    }),
    getYears() {
      this.year_data = localStorage.getItem("current_year_name_h");
      this.filter_data.year = localStorage.getItem("current_year");
      return this.year_data;
    },

    getCommitName(driver_id) {
      if (driver_id) {
        const committees = this.committee.find(
          (committees) => committees.id === committees.id
        );
        return committees.name;
      }
      return "===========";
    },
    checkFilterData() {
      this.v$.filter_data.$validate();
      if (!this.v$.filter_data.$error) {
        this.getData();
      }
    },
    async printTable() {
      window.print();
    },
    async getData() {
      this.loading = true;

      await this.axios
        .get(`${this.base_url}api/summer/report/summer-calendar`, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.exist = true;
          if (response.data) {
            this.items = response.data;

            this.loading = false;
          }
        })
        .catch((error) => {
          this.icon = "cancel";
          this.icon_color = "error";
          if (error.message.includes("Network Error")) {
            this.alert_title = this.$t("alert.failure.title");
            this.alert_message = this.$t("alert.failure.connect");
          } else {
            this.alert_title = this.$t("alert.not-found.title");
            this.alert_message = this.$t("alert.not-found.message");
          }
          this.process_alert = true;
          setTimeout(() => {
            this.process_alert = false;
          }, 2000);
        });
    },
  },

  watch: {
    perPage() {
      this.getData();
    },
    page() {
      this.getData(this.page);
    },
  },
};
</script>
<style scoped>
#stable {
  width: 100%;
}

.pp2 {
  padding: 2px !important;
}
#stable th {
  padding: 10px;
  border: 1px solid #649add !important;
  background-color: #fff;
}

#stable td {
  border: 1px solid #649add !important;
  background-color: #fff;
  padding: 8px;
}

.search:focus {
  outline: unset;
}
.fontsize {
  font-size: 14px;
}
.borders {
  border: solid #c1c1c1 1px;
  border-radius: 8px;
}
@media print {
  #sidebar,
  #header,
  #pagination-bar *,
  #tableHead,
  .tooltip,
  #print-menu {
    display: none !important;
  }
  #lectures-report {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    /* height: 100%; */
  }
}
</style>
