<template>
  <!-- <VLocaleProvider :rtl="$i18n.locale==='en'?false:true"> -->
      <VApp  >
        <router-view />
      </VApp>

  <!-- </VLocaleProvider> -->
</template>

<script>
import { mapGetters } from "vuex";
export default {
  created(){
    if(localStorage.getItem('token')){
      this.getActiveCirculars()
      this.getUserProfile()
    }
    const locale=localStorage.getItem('user-locale')
    if(locale=='en'){
      this.$store.dispatch("updatedirection", 'ltr');
      this.$store.dispatch("updatelanguage",'en');
      this.$i18n.locale="en"
      document.querySelector('.v-overlay-container')?.setAttribute('dir','ltr')
     
    }else{
      this.$store.dispatch("updatedirection", 'rtl');
      this.$store.dispatch("updatelanguage",'ar');
      this.$i18n.locale="ar"
      document.querySelector('.v-overlay-container')?.setAttribute('dir','rtl')
    }
    
  },
  
  methods:{
    async getActiveCirculars() {
      this.axios(`${this.base_url}/api/summer/generalization/active-circulars/`).then((res) => {
        this.$store.commit('setCirculars',res.data)
      });
    },
    async getUserProfile() {
      await this.axios
        .get(`${this.base_url}api/user/profile`)
        .then((response) => {
          this.user_profile = response.data;
          if(this.user_profile?.role){
            this.$store.commit('setRole',this.user_profile.role)
          }
        });
    },
  },
  mounted(){
    if (this.$i18n.locale=='en'){
      document.title="Summer Centers System"
    }
    
  }
}
</script>
<style >
.v-data-table-footer__items-per-page >span{
  display: none;
}

</style>


