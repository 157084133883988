<template>
  <v-card>
    <v-card-title>
      <v-icon icon="mdi-cloud-upload" />
      <h3 class="d-inline" style="padding: 0 4px">{{ $t("backup.backup") }}</h3>
      <v-divider style="border-width: 1px; opacity: 0.5" class="my-1" />
    </v-card-title>
    <div class="px-2">
      <v-form ref="form" @keydown.enter="tab=='create'?CreateBackUp():RestoreBackUp()">
        <v-tabs v-model="tab"  slider-color="indigo" :color="update ? 'success' : 'indigo'">
          <v-tab v-for="item in items" :key="item.key" :value="item.key">
            <v-icon color="icon-color" class="me-2">{{ item.icon }}</v-icon>
            <span> {{ item.value }}</span>
          </v-tab>
        </v-tabs>
        <v-window v-model="tab" class="flex-fill">
          <v-window-item :value="items[0].key" >
            <v-card flat class="mt-4" >
              <v-card-text>
                <v-checkbox
                  v-model="default_choices"
                  :label="$t('globals.default-choices')"
                  color="indigo"
                  density="compact"
                  hide-details
                  @change="default_choices ? cleanCreateBackUpFrom() : true"
                >
                </v-checkbox>
                <!-- <legend></legend> -->
                <div class="mt-4">
                  <v-row>
                    <v-col cols="6" md="4">
                      <v-select
                        :disabled="default_choices ? true : false"
                        density="compact"
                        v-model="create_backup.extension"
                        :label="$t('backup.select')"
                        item-title="val"
                        item-value="id"
                        :items="[
                          { id: 'json', val: 'JSON' },
                          { id: 'sql', val: 'SQL' },
                          { id: 'psql', val: 'PSQL' },
                          { id: 'csv', val: 'CSV' },
                        ]"
                        prepend-inner-icon="mdi-file-cog"
                        :error-messages="
                          v$.create_backup.extension.$errors.map(
                            (e) => e.$message
                          )
                        "
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        :disabled="default_choices ? true : false"
                        v-model="create_backup.file_name"
                        density="compact"
                        :label="$t('backup.file-name')"
                        append-inner-icon="mdi-file-cloud"
                        clearable
                        :error-messages="
                          v$.create_backup.file_name.$errors.map(
                            (e) => e.$message
                          )
                        "
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </div>
              </v-card-text>
              <br />
              <v-card-actions>
                <v-btn
                  v-if="!update && CheckGetPermission('summer_center.add_teacher')"
                  @click="CreateBackUp"
                  class="bg-indigo w-100"
                  size="small"
                  :loading="loading"
                >
                  <span class="text-white">
                    {{ $t("globals.create") }}
                  </span>
                  <v-icon color="white" end>mdi-cloud-upload</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-window-item>
          <v-window-item :value="items[1].key">
            <v-card flat class="mt-4">
              <v-card-text>
                <v-alert
                  type="info"
                  :title="$t('alert.attention.restore-backup')"
                  variant="tonal"
                >
                </v-alert>

                <br /><br />
                <v-btn
                  @click="RestoreBackUp"
                  class="bg-indigo w-100 mb-2"
                  size="small"
                  :loading="loading"
                >
                  <span class="text-white">
                    {{ $t("globals.restore") }}
                  </span>
                  <v-icon color="white" end>mdi-cloud-download</v-icon>
                </v-btn>
              </v-card-text>
            </v-card>
          </v-window-item>
          <!-- <v-window-item :value="items[2].key">
            <v-card flat class="mt-4">
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="4" sm="6">
                    <v-select
                      density="compact"
                      :label="$t('backup.select')"
                      item-title="val"
                      item-value="id"
                      :items="[
                        { id: 'json', val: 'JSON' },
                        { id: 'sql', val: 'SQL' },
                        { id: 'psql', val: 'PSQL' },
                        { id: 'csv', val: 'CSV' },
                      ]"
                    >
                    </v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <v-select
                      density="compact"
                      :label="$t('backup.select')"
                      item-title="val"
                      item-value="id"
                      :items="[
                        { id: 'json', val: 'JSON' },
                        { id: 'sql', val: 'SQL' },
                        { id: 'psql', val: 'PSQL' },
                        { id: 'csv', val: 'CSV' },
                      ]"
                    >
                    </v-select>
                  </v-col>
                </v-row>
              </v-card-text>
              <br /><br />
              <v-card-actions class="text-center">
                <v-btn class="bg-indigo" size="small">
                  <span class="text-white">
                    {{ $t("globals.save") }}
                  </span>
                  <v-icon icon="mdi-content-save" color="white" end></v-icon>
                </v-btn>
                <v-btn @click="clean" class="mx-3 bg-ccc" size="small">
                  <span>
                    {{ $t("globals.clear") }}
                  </span>
                  <v-icon icon="mdi-broom" color="golden" end></v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-window-item> -->
        </v-window>
      </v-form>
    </div>
  </v-card>
  <div>
    <Alert
      v-model="showAlert"
      :iconColor="alert.color"
      :icon="alert.icon"
      :title="alert.title"
      :message="alert.message"
    >
    </Alert>
  </div>
</template>

<script>
import useValidate from "@vuelidate/core";
import { mapState, mapActions } from "vuex";
import {
  email,
  required,
  helpers,
  maxLength,
  minLength,
  url,
  alpha,
  alphaNum,
  numeric,
} from "@vuelidate/validators";
// const NameReg = helpers.regex(/^[^.\/\\\s]+$/);
const NameReg = helpers.regex(/^[\u0600-\u06ff\u0660-\u0669a-zA-Z0-9_-]+$/);
const arabicOnly = helpers.regex(
  /^[\u0600-\u06ff\u0750-\u077f-\u080a-\u08ff\ufb50-\ufdff\ufe70-\ufeff\s]*$/
);

export default {
  props: {
    id: {
      type: Number,
    },
  },

  async created() {
    await this.$store.commit("updateLoadingValue", true);
    this.$store.commit("updateLoadingValue", false);
  },
  data() {
    return {
      default_choices: true,
      loading: false,
      showAlert: false,
      backup_file: null,
      v$: useValidate(),
      create_backup: {
        extension: null,
        file_name: null,
      },
      alert: {
        color: null,
        icon: null,
        title: null,
        message: null,
      },
      tab: null,
    };
  },
  computed: {
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },

    items() {
      return [
        {
          key: "create",
          value: this.$t("backup.create"),
          icon: "mdi-cloud-upload",
        },
        {
          key: "restore",
          value: this.$t("backup.restore"),
          icon: "mdi-cloud-download",
        },
        // {
        //   key: "contact_information",
        //   value: this.$t("backup.settings"),
        //   icon: "mdi-cloud-refresh",
        // },
      ];
    },
  },

  validations() {
    return {
      create_backup: {
        extension: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
        file_name: {
          required: helpers.withMessage(this.$t("errors.required"), required),
          NameReg: helpers.withMessage(this.$t("errors.required"), NameReg),
        },
      },
    };
  },
  //   create_backup: {
  //     extension: {
  //       required: helpers.withMessage(this.$t("errors.required"), required),

  //     },
  //   },
  methods: {
    ...mapActions({}),
    selectFile(event) {
      this.backup_file = event.target.files[0].name;
    },
    cleanCreateBackUpFrom() {
      this.$refs.form.reset();
      this.v$.$reset();
    },
    CreateBackUp() {
      if (!this.default_choices) {
        this.v$.$validate();
      }
      if (!this.v$.$error) {
        this.loading = true;
        this.axios(
          `${this.base_url}api/export-database?file_name=${this.create_backup.file_name}&extension=${this.create_backup.extension}`
        )
          .then((res) => {
            this.loading = false;
            this.showAlert = true;
            this.alert.color = "success";
            this.alert.message = this.$t("alert.success.create-backup");
            this.alert.title = this.$t("alert.success.title");
            this.alert.icon = "check-circle";
            setTimeout(() => {
              this.showAlert = false;
            }, 1500);
          })
          .catch((error) => {
            this.loading = false;
            this.showAlert = true;
            this.alert.color = "error";
            this.alert.message = this.$t("alert.failure.create-backup");
            this.alert.title = this.$t("alert.failure.title");
            this.alert.icon = "alert-circle-outline";
            setTimeout(() => {
              this.showAlert = false;
            }, 1500);
          });
      }
    },
    RestoreBackUp() {
      this.loading = true;
      this.axios
        .get(`${this.base_url}api/import-database?path=${this.backup_file}`)
        .then((res) => {
          this.loading = false;
          this.showAlert = true;
          this.alert.color = "success";
          this.alert.message = this.$t("alert.success.restore-backup");
          this.alert.title = this.$t("alert.success.title");
          this.alert.icon = "check-circle";
          setTimeout(() => {
            this.showAlert = false;
          }, 1500);
        })
        .catch((err) => {
          this.loading = false;
          this.showAlert = true;
          this.alert.color = "error";
          this.alert.message = this.$t("alert.failure.restore-backup");
          this.alert.title = this.$t("alert.failure.title");
          this.alert.icon = "alert-circle-outline";
          setTimeout(() => {
            this.showAlert = false;
          }, 1500);
        });
    },
  },
};
</script>

