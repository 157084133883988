import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Globals/HomeView.vue";
import Login from "../views/Globals/Login.vue";
import SummerUsers from "./SummerUsers";
import Globals from "./GlobalsRoutes";
import example from "../views/Examples/Dashboard";
;
import NotFoundView from "../views/Globals/NotFoundView.vue";
import TestDatetimePicker from '../views/Examples/TestDatetimePicker.vue'
import i18n from "@/i18n";
import store from "@/store";
import Summer from "./Summer";
import SummerReport from "./SummerReport";
import PublicReport from "./PublicReport";
import {decryptString} from '@/utils/index.js'

import { api_url } from "@/main";
import jwt_decode from "jwt-decode";
i18n.global.locale = localStorage.getItem("user-locale");
// const sys_type=localStorage.getItem('sys_type')

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  
  {
    path: "/summer",
    name: "summer",
    component: Home,
    children: [
      ...Summer,
      {
        path: "user",
        children: [...SummerUsers],
      },
      {
        path: "report",
        children: [...SummerReport],
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    name: "notFound",
    component: NotFoundView,
  },  
  
  {
    path: "/public",
    name: "public",
    component: Home,
    children: [...PublicReport],
  },
  {
    path: "/test",
    component: TestDatetimePicker,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("token")
  var check = null
  try {
    check = decryptString(token)
  } catch (error) {
    check = token
  }
  const checkperm = localStorage.getItem("userinfo");
  const username = localStorage.getItem("username");
  if (!check || !username) {
    if (to.path !== "/login") {
      next("/login");
    } else {
      next();
    }
  } else if (check && to.path === "/login") {
    next("/");
  } else {
    const decode = jwt_decode(check);
    if (decode.exp < Date.now() / 1000) {
      // window.location.reload();
      store.dispatch("logout");
    }
    const mustChangePassword = localStorage.getItem('must_change_password')
    if(mustChangePassword&&to.name!='user_profile'){
      next({name:'user_profile'})
      store.dispatch("updateAuthenticated", check);
      store.dispatch("updatePermission", checkperm);
    }else{
      store.dispatch("updateAuthenticated", check);
      store.dispatch("updatePermission", checkperm);
      next();
    }
  }
});

export default router;
