<template>
  <VForm ref="form" >
      <v-expansion-panels>
        <v-expansion-panel   >
          <v-expansion-panel-title class="px-4">
              <span>{{$t('globals.select-criteria')}}</span>
          </v-expansion-panel-title>
          <v-expansion-panel-text >
            <v-row class="py-2" >
              <v-col cols="6" md="3" >
                <VAutocomplete
                  clearable
                  :items="levels"
                  v-model="studentAttendance.level"
                  item-title="level_with_phase_name"
                  item-value="id"
                  :label="$t('summer.level.level')"
                  persistent-hint
                  prepend-inner-icon="mdi-stairs-box"
                  density="compact"
                  hide-details="auto"
                  @update:model-value="
                    getDivisionByLevel(), (studentAttendance.division = null)
                  "
                ></VAutocomplete>
              </v-col>
              <v-col cols="6" md="3">
                <VAutocomplete
                  clearable
                  :items="divisions"
                  v-model="studentAttendance.division"
                  item-title="name"
                  item-value="fk_division"
                  :label="$t('division.select')"
                  persistent-hint
                  hide-details="auto"
                  prepend-inner-icon="mdi-shape-outline"
                  density="compact"
                ></VAutocomplete>
              </v-col>
              <v-col cols="6" md="4" class="mt-2 ">
                  <v-btn
                    @click="getStudentsDataToAttendance"
                    :loading="DataLoading"
                    class="bg-indigo w-50"
                    size="small"
                  >
                    <span class="text-white">
                      {{ $t("globals.show") }}
                    </span>
                  </v-btn>
              </v-col>
            </v-row>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    <!-- ######## Header Of Data Table  ####### -->
    <v-card class="mt-2 ">
      <v-card-text class="pb-0">
        <v-progress-linear 
          color="indigo" 
          :reverse="$i18n.locale=='ar'"
          height="3px" 
          indeterminate 
          v-if="DataLoading"
        >
        </v-progress-linear>
        <!-- Search box and eye menu  -->
        <v-row class="mb-2 mt-1" v-if="!DataLoading&&students.length > 0">
          <v-col cols="12" md="4">
            <v-text-field
                color="indigo"
                v-model="txt_search"
                hide-details
                prepend-inner-icon="mdi-magnify"
                single-line
                clearable
                :placeholder="$t('globals.search-here')"
                density="compact"
              >
            </v-text-field>
          </v-col>
          <VCol cols="6" md="4">
            <VAutocomplete
              :items="weeks"
              v-model="studentAttendance.fk_week"
              item-title="week_name"
              item-value="id"
              :label="$t('summer.calendar.select-week')"
              persistent-hint
              prepend-inner-icon="mdi-calendar-week"
              density="compact"
              clearable
              hide-details="auto"
              @update:model-value="
                studentAttendance.attendance_date = null;
                getDateList();
              "
            ></VAutocomplete>
          </VCol>
          <VCol  cols="6" md="4">
            <VAutocomplete
              :items="listWeeks"
              v-model="studentAttendance.attendance_date"
              density="compact"
              :placeholder="$t('summer.employee.date-attendance')"
              clearable
              prepend-inner-icon="mdi-calendar-clock-outline"
              :label="$t('summer.employee.date-attendance')"
              persistent-hint
              hide-details="auto"
              @update:modelValue="studentAttendance.attendance_date?getStudentsDataToAttendance():''"
            ></VAutocomplete>
          </VCol>
        </v-row>
        <!-- End search box and eye menu  -->
        <v-table 
          density="compact"
          class=" elevation-1 rounded"
          v-if="!DataLoading&&students.length > 0"
          :height="searched_students.length>10?'400':null"
          fixed-header
          >
          <thead>
            <tr>
              <th v-for="head in headers" :key="head">
                <span v-if="head.key=='is_attendance'">
                 <v-checkbox 
                  v-model="check_all"
                  hide-details
                  density="compact"
                  :label="$t('permission.is_attendance')"
                  @change="checkAllStudetns"
                >
                </v-checkbox>
                </span>
                <span v-else>{{head.title}}</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(student,index) in searched_students" :key="student">
              <td>{{index+1}}</td>
              <td>
                <v-lazy>
                  <span
                    :class="{
                      'mdi mdi-alert-circle text-red-accent-4 mdi-18px':student.count_absent>=7
                    }"
                  > {{ student.student_name }} 
                    <v-tooltip 
                      v-if="student.count_absent>=7"
                      activator="parent"
                      location="left"
                    >
                    {{$t('summer.extended-student')}} {{
                      student.count_absent>=10?Math.abs(15-student.count_absent):Math.abs(10-student.count_absent)
                    }} {{$t('globals.days-absent')}}
                    </v-tooltip>
                  </span>
                </v-lazy>
              </td>
              <td>
                <v-lazy>
                  <v-checkbox-btn
                    v-model="student.is_attendance"
                    color="indigo"
                    density="compact"
                    hide-details
                    :disabled="student.droped_out==true?true:false"
                    @change="student.is_attendance?(student.is_permission=false,student.droped_out=false):null"
                  >
                  </v-checkbox-btn>
                </v-lazy>
              </td>
              <td>
                <v-lazy>
                  <v-checkbox
                    v-model="student.is_permission"
                    color="indigo"
                    density="compact"
                    hide-details
                   :disabled="students.droped_out==true?true:false"
                   @change="student.is_permission?(student.is_attendance=false,student.droped_out=false):null"
                  >
                  </v-checkbox>
                </v-lazy>
              </td>
              <td>
                <v-lazy>
                  <v-checkbox
                    v-model="student.droped_out"
                    color="indigo"
                    density="compact"
                    hide-details
                    @change="setDropOutStudent(index,student.droped_out)"
                  >
                  </v-checkbox>
                </v-lazy>
              </td>
              <td>
                <v-lazy>
                  <span>{{student.attendance_date}}</span>
                </v-lazy>
              </td>
            </tr>
          </tbody>
        </v-table>
        <v-card
          v-else-if="!DataLoading&&students.length <1"
          class="pa-2 text-center mb-2"
        >
          <span >
            {{ $t("globals.not-found-items") }}
          </span>
        </v-card>
      </v-card-text>
      <VCardActions v-if="!DataLoading&&students.length > 0" class="mx-1">
        <VBtn
          class="bg-indigo"
          :loading="saveDataLoading"
          @click="saveStudentAttendance()"
          size="small"
          v-if="
          CheckGetPermission('summer_center.add_studentattendancesummer',['sys_admin','summer_admin','user']) ||
          CheckGetPermission('summer_center.change_studentattendancesummer',['sys_admin','summer_admin','user'])"
        >
          <span class="text-white">
            {{ $t("globals.save") }}
          </span>
          <VIcon icon="mdi-content-save" color="white" end></VIcon>
        </VBtn>
        <VBtn class="mx-3" size="small" @click="resetForm()">
          {{ $t("globals.clear") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
      </VCardActions>
    </v-card>
  </VForm>
  
  <v-dialog
    v-model="drop_out_dialog"
    width="400"
    :style="{direction:$i18n.locale==='ar'?'rtl':'ltr'}"
    persistent
    >
    <v-card > 
        <v-card-title class=" text-center">
            <h3 class="d-inline" style="padding:0 4px;" >{{$t('summer.students-drop-out')}}</h3>
            <v-divider style="border-width:1px; opacity: 0.5;" class="my-2"/>
        </v-card-title>
        <!-- :style="{direction:$i18n.locale==='ar'?'rtl':'ltr'}" -->
        <v-card-text >
            <v-form   @submit.prevent="savePermissionData">
                    <v-select
                      v-model="drop_out_reason"
                      density="compact"
                      item-title="name"
                      clearable
                      item-value="id"
                      :no-data-text="$t('globals.not-found')"
                      :items="drop_out_reasons"
                      :label="$t('summer.dropout-reason')"
                      :error-messages="v$.drop_out_reason.$errors.map((e) => e.$message)"
                    >
                    </v-select>
            </v-form>
        </v-card-text>
        <v-card-actions class="justify-center ">
            <v-btn class="bg-indigo"  size="small" @click="saveDropOutStudent"  ><span class="text-white">{{$t('globals.save')}}</span></v-btn>
            <v-btn @click="cancelDropOutStudent">
                <span class="text-indigo">
                    {{$t('globals.cancel')}}
                </span>
            </v-btn>
        </v-card-actions>
    </v-card>

</v-dialog>
<v-dialog
    v-model="expelled_dialog"
    width="40%"
    :style="{direction:$i18n.locale==='ar'?'rtl':'ltr'}"
    persistent
    >
    <v-card > 
        <v-card-title class=" text-center">
            <h3 class="d-inline" style="padding:0 4px;" >{{$t('summer.expelled-students')}}</h3>
            <v-divider style="border-width:1px; opacity: 0.5;" class="my-2"/>
        </v-card-title>
        <!-- :style="{direction:$i18n.locale==='ar'?'rtl':'ltr'}" -->
        <v-card-text >
            <table>
              <tbody>
                <tr v-for="item in expelled_list" :key="item" class="pa-1">
                  <td class="text-red">
                    <h3> 
                      {{item}}
                    </h3>
                  </td>
                </tr>
              </tbody>
            </table>
        </v-card-text>
        <v-card-actions class="justify-center ">
            <v-btn class="bg-indigo"  size="small" @click="expelled_dialog=false"  ><span class="text-white">{{$t('globals.ok')}}</span></v-btn>
        </v-card-actions>
    </v-card>

</v-dialog>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { required, helpers, maxLength, numeric } from "@vuelidate/validators";
import Datetime from "vue3-datetime-js";
import useValidate from "@vuelidate/core";
export default {
  components: {
    Datetime,
  },
  data() {
    return {
      listWeeks: [],
      txt_search:null,
      v$: useValidate(),
      expelled_dialog:false,
      drop_out_dialog:false,
      selected_student_index:null,
      drop_out_reason:null,
      check_all:false,
      saveDataLoading: false,
      DataLoading: false,
      checkAttendance: [],
      checkIsPermission: [],
      checkIsDropedOut: [],
      checkAttendnaceDate: [],
      divisions: [],
      students: [],
      studentAttendance: {},
      expelled_list:[],
      sortBy: [
        {
          key: "student_name",
          order: "asc",
        },
      ],
      rules: {
        fk_week: [(value) => !!value || this.$t("globals.required_field")],
        attendance_date: [(value) => !!value || this.$t("globals.required_field")],
      },
      dialogVisible: false,
      selectedHead: [
        "index",
        "student_name",
        "is_attendance",
        "is_permission",
        "attendance_date",
      ],
      start:0,
      timeout:null,
      rowHeight:24,
      perPage:25,
      currentPage:1,
      headers: [
        { title: this.$t("globals.number"), key: "index", sortable: false },
        { title: this.$t("school.student_name"), key: "student_name" },
        { title: this.$t("permission.is_attendance"),key: "is_attendance",sortable: false,},
        { title: this.$t("permission.is_permission"),key: "is_permission",sortable: false,},
        { title: this.$t("summer.droped-out"),key: "droped_out",sortable: false,},
        { title: this.$t("permission.attendance_date"),key: "attendance_date",sortable: false},
      ],
      txt_search: undefined,
    };
  },
  computed: {
    ...mapState({
      countries: (state) => state.school.countries,
      levels: (state) => state.summer.levels,
      weeks: (state) => state.summer.weeks,
      user: (state) => state.User,
      drop_out_reasons: (state) => state.summer_globals.drop_out_reason,

    }),
    searched_students(){
      if(this.txt_search==null||this.txt_search==undefined){
        return this.students
      }
      return this.students.filter(student=>student.student_name.includes(this.txt_search))
    },
    studentsLimited(){
      return this.students.slice(this.start,this.perPage,this.start)
    },
    startHeight(){
      return this.start*this.rowHeight-32;
    },
    endHeight(){
      return this.rowHeight*(this.students.length - this.start)
    },
    ...mapGetters({}),
    selectedHeaders() {
      return this.headers.filter((header) => this.selectedHead.includes(header.key));
    },
    totalPages(){
      let total_pages = Math.ceil(this.students.length/this.perPage)
      return total_pages==3?total_pages+1:total_pages
    },
    displayItems(){
      const start_index=(this.currentPage -1) * this.perPage
      const end_index= start_index+this.perPage
      return this.students.slice(start_index,end_index)
    },
    isCheckAll(){
      var check_all=this.students.filter(student=>!student.droped_out).every(student=>student.is_attendance)
      this.check_all=check_all
      return check_all
    },
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
    CheckGetPermission() {
      return (prem, role) => {
        return this.$store.getters.checkpermission(prem, role);
      };
    },
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getLevels();
      await this.getWeeks();
      await this.getStudentsDataToAttendance();
      await this.getDropOutReason();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    ...mapActions({
      getLevels: "summer/getLevels",
      getWeeks: "summer/getWeeks",
      getDropOutReason: "summer_globals/getDropOutReason",

    }),
    updateDisplayItems(){
      this.displayItems=this.students.slice(
        (this.currentPage-1)*this.perPage,
        this.currentPage*this.perPage
      )
    },
    async getDateList() {
      if (this.studentAttendance.fk_week)
        await this.axios
          .get(this.base_url + "api/summer/get-work-days", {
            params: {
              week_id: this.studentAttendance.fk_week,
            },
          })
          .then((response) => {
            this.listWeeks = response.data.date;
          })
          .catch((error) => {
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
    },
    async getDivisionByLevel() {
      if (this.studentAttendance.level) {
        let levelDivisions = await this.axios.get(this.base_url + `api/summer/get-level-division/${this.studentAttendance.level}`);
        this.divisions = levelDivisions.data.division;
      }
    },
    async getStudentsDataToAttendance() {
      this.DataLoading = true;
      await this.axios
        .get(this.base_url + `api/summer/add-attendance-to-student`, {
          params: {
            fk_week: this.studentAttendance.fk_week,
            attendance_date: this.studentAttendance.attendance_date,
            fk_level: this.studentAttendance.level,
            fk_division: this.studentAttendance.division,
          },
        })
        .then((response) => {
          this.DataLoading = false;
          this.students = response.data.results;
          this.studentAttendance.fk_week=response.data.week;
          this.studentAttendance.attendance_date=response.data.date;
          this.getDateList()
          if (this.students.length <= 0) {
            this.$emit("warningAlert", this.$t("summer.not-found-data"));
          }
        })
        .catch((error) => {
          this.DataLoading = false;
          if (error.response.data === "is_holiday") {
            this.$emit("warningAlert", this.$t("summer.is-holiday"));
          } else if (error.response.data === "out_of_range") {
            this.$emit("warningAlert", this.$t("summer.out-of-range"));
          } else {
            this.$emit("warningAlert", this.$t("summer.not-found-data"));
          }
        });
    },
    updateAttendance(index) {
      this.students[index].is_attendance = !this.students[index].is_attendance;
      if (this.students[index].is_attendance) this.students[index].is_permission = false;
    },
    updatePermission(index) {
      this.students[index].is_permission = !this.students[index].is_permission;
      if (this.students[index].is_permission) this.students[index].is_attendance = false;
    },
    getStudentCountAbsent(index) {
      if (this.students[index].count_absent >6) return 10;
      if (this.students[index].count_absent <=6) return 1;
    },
    resetForm() {
      this.$refs.form.resetValidation();
      this.students = [];
      this.studentAttendance = {};
      this.getStudentsDataToAttendance();
    },
    async saveStudentAttendance() {
      this.saveDataLoading = true;
      this.studentAttendance.data_entry = this.user;
      let newStudents = this.students.map((object) => ({
        id: object.id,
        fk_student: object.fk_student,
        fk_summer: object.fk_summer,
        is_attendance: object.is_attendance,
        is_permission: object.is_permission,
        attendance_date: object.attendance_date,
        fk_week: this.studentAttendance.fk_week,
        droped_out:object.droped_out,
        drop_out_reason:object.drop_out_reason
      }));
      let result = await this.axios
        .post(this.base_url + "api/summer/add-attendance-to-student", newStudents)
        .then((response) => {
          this.$emit("successAlert", this.$t("alert.success.save"));
          if(response.data && response.data.length > 0){
            this.expelled_list = response.data
            setTimeout(() => {
              this.expelled_dialog=true
            }, 2000);
          }
          this.saveDataLoading = false;
          this.getStudentsDataToAttendance()
        })
        .catch((error) => {
          if(error.response.data && error.response.data == "date_error")
            this.$emit("errorAlert", this.$t("globals.future-attendance"));
          else
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          this.saveDataLoading = false;
        });
    },
    checkAllStudetns(){
      this.students.forEach(student => {
        if(this.check_all){
          student.is_permission=false
        }
        if(!student.droped_out){
          student.is_attendance=this.check_all
        }
      });
    },
    setDropOutStudent(student_index,dialog_value){
      this.drop_out_dialog=dialog_value
      this.selected_student_index=student_index
    },
    saveDropOutStudent(){
      this.v$.$validate();
      if(!this.v$.$error){
        try {
          this.searched_students[this.selected_student_index].drop_out_reason=this.drop_out_reason
          this.searched_students[this.selected_student_index].is_attendance=false
          this.searched_students[this.selected_student_index].is_permission=false
          this.drop_out_dialog=false
          this.drop_out_reason=null
          this.v$.$reset()
        } catch (error) {
          this.drop_out_dialog=false
        }
      }
    },
    cancelDropOutStudent(){
      try {
        this.students[this.selected_student_index].drop_out_reason=null
        this.students[this.selected_student_index].droped_out=false
        this.drop_out_dialog=false
        this.drop_out_reason=null
      } catch (error) {
        this.drop_out_dialog=false
      }
    }
  },
 validations() {
    return {
      drop_out_reason: {
        required: helpers.withMessage(this.$t("errors.required"), required),
      },
      
    };
  },
  watch:{
    isCheckAll(){
      this.check_all=this.isCheckAll
    },
    
  }
 
};
</script>

<style>

</style>
