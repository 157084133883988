<template>
  <v-card
    class="justify-center"
    v-if="CheckGetPermission('summer_center.view_activitydifficulty',['sys_admin','summer_admin','user'])"
  >
    <v-card-text>
      <!-- ##########    data Table Server ########### -->
      <!-- ######## Header Of Data Table Server ####### -->
      <div
        class="d-flex justify-space-between mb-2"
        v-if="CheckGetPermission('summer_center.view_activitydifficulty',['sys_admin','summer_admin','user'])"
      >
        <div class="d-flex" style="flex: 10">
          <v-menu :close-on-content-click="false">
            <template v-slot:activator="{ props }">
              <v-btn v-bind="props" variant="outlined" color="#ccc" size="small">
                <v-icon style="color: #5e7e96" class="me-2"
                  >mdi-eye-settings-outline</v-icon
                >
                <v-icon style="color: #5e7e96">mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-text>
                <v-checkbox
                  v-for="header in headers"
                  :key="header.key"
                  :label="header.title"
                  :value="header.key"
                  color="indigo"
                  v-model="selectedHead"
                  density="compact"
                  hide-details
                >
                </v-checkbox>
              </v-card-text>
            </v-card>
          </v-menu>

          <v-text-field
            color="indigo"
            v-model="txt_search"
            style="
              border-radius: 30px;
              border: 1px solid #ccc;
              color: black;
              height: 30px;
              width: 90%;
            "
            class="search"
            hide-details
            prepend-inner-icon="mdi-magnify"
            single-line
            :placeholder="$t('globals.search-here')"
            @input="getsloutiondifcltiess((page = 1))"
            density="compact"
            variant="text"
          >
          </v-text-field>
        </div>
        <div class="d-flex justify-end" style="flex: 1">
          <VBtn
            v-if="CheckGetPermission('summer_center.add_activitydifficulty',['sys_admin','summer_admin','user'])"
            class="bg-success"
            @click="dicultsandslotions"
            size="small"
          >
            <span class="text-white">{{ $t("globals.assigin") }}</span>
            <VIcon icon="mdi-plus-thick" color="white" end></VIcon>
          </VBtn>
        </div>
      </div>
      <!-- ######## End Header Of Data Table Server ####### -->
      <div >
        <v-data-table-server
          :headers="selectedHeaders"
          :items="tableList"
          item-key="id"
          fixed-footer
          density="compact"
          class="elevation-1"
          id="vDataTable"
          v-model:sort-by="sortBy"
        >
          <template v-slot:column.checkbox="{ column }">
            <v-checkbox hide-details density="compact"> </v-checkbox>
          </template>
          <template v-slot:item="{ item }">
            <tr class="row-hover">
              <td v-for="(val, key) in item.columns" :key="key">
                <v-checkbox
                  v-if="key == 'checkbox'"
                  hide-details
                  density="compact"
                >
                </v-checkbox>
                <span v-else> {{ val }}</span>
                <span v-if="key === 'actions'">
                  <v-btn
                    variant="text"
                    v-if="
                      CheckGetPermission(
                        'summer_center.change_activitydifficulty',['sys_admin','summer_admin','user']
                      )
                    "
                    class="me-1 v-btn-icon"
                    size="small"
                    @click="editesloutiondifcltiess(item.raw)"
                  >
                    <v-icon color="warnning">mdi-pencil-outline</v-icon>
                  </v-btn>
                  <v-btn
                    variant="text"
                    v-if="
                      CheckGetPermission(
                        'summer_center.delete_activitydifficulty',['sys_admin','summer_admin','user']
                      )
                    "
                    size="small"
                    class="v-btn-icon"
                    @click="
                      (selected_lecture_attendance = item.raw.id), (dialog = true)
                    "
                  >
                    <v-icon color="error">mdi-trash-can-outline</v-icon>
                  </v-btn>
                </span>
              </td>
            </tr>
          </template>
          <template v-slot:bottom>
            <div class="d-flex" id="pagination-bar">
              <v-pagination
                v-model="page"
                :length="length"
                density="compact"
                show-first-last-page
              >
              </v-pagination>
              <div class="d-flex">
                <v-select
                  v-model="perPage"
                  class="pa-0"
                  :items="itemsPerPage"
                  item-value="value"
                  item-title="text"
                  density="compact"
                  hide-details
                  variant="text"
                >
                </v-select>
                <span class="mt-2 px-2"
                  >{{ $t("globals.per-page") }} {{ $t("globals.from") }} [
                  {{ totalItems }} ] {{ $t("globals.item") }}</span
                >
              </div>
            </div>
          </template>
        </v-data-table-server>
      </div>
    </v-card-text>
    <!-- ############## end Dialog Delete  ################### -->
  </v-card>
    <!-- ############### Dialog Delete  ################### -->
    <delete-dialog
      v-model="dialog"
      :loading="loading"
      @confirm-delete="deletesloutiondifcltiess(selected_lecture_attendance)"
    />
    <!-- ############### Dialog Delete  ################### -->
  <div>
    <Alert
      color="indigo"
      v-model="showAlert"
      :iconColor="alert.color"
      :icon="alert.icon"
      :title="alert.title"
      :message="alert.message"
    >
    </Alert>
  </div>
</template>

<script>
import useValidate from "@vuelidate/core";
import { mapState, mapActions, mapGetters } from "vuex";
import { required, helpers, maxLength, numeric } from "@vuelidate/validators";
export default {
  name: "LecturesList",
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.user = this.$store.getters.getuser;
      await this.getsloutiondifcltiess()
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  data() {
    return {
      v$: useValidate(),
      txt_search: null,
      dialog: false,
      addDialog: false,
      overlay: false,
      update: false,
      alert: false,
      showAlert: false,
      loading: false,
      user: null,
      sortBy: [{ key: "id", order: "asc" }],

      alert: {
        color: undefined,
        icon: undefined,
        title: undefined,
        message: undefined,
      },

      selectedHead: [
        "difficulty",
        "solution",
        "activity_name",
        "start_date",
        "note",
        "actions",
      ],
      selected_lecture_attendance: null,
      fk_level: null,
      fk_subject: null,
      fk_empoylee: null,
      fk_division: null,
      from_date: null,
      lectures_attendance: [],
      to_date: null,
      headers: [
        {
          title: this.$t("summer.activity.activity_name"),
          key: "activity_name",
        },
        { title: this.$t("summer.activity.difficultie"), key: "difficulty" },
        { title: this.$t("summer.activity.solutions"), key: "solution" },
        {
          title: this.$t("summer.activity.activtity_assigin_date"),
          key: "start_date",
        },
        { title: this.$t("globals.actions"), key: "actions", sortable: false },
      ],
      perPage: 10,
      length: 0,
      itemsPerPage: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 1000000, text: this.$t("globals.all") },
      ],
      page: 1,
      totalItems: null,
      pagination: {},
    };
  },
  computed: {
    ...mapGetters({}),
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
    tableList() {
      var list = JSON.parse(JSON.stringify(this.lectures_attendance));
      return list;
    },

    CheckGetPermission() {
      return (prem,role) => {
        return this.$store.getters.checkpermission(prem,role);
      };
    },
    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
  },

  validations() {
    return {
      lecture_attendance: {
        name: {
          required: helpers.withMessage(this.$t("errors.required"), required),
          maxLength: helpers.withMessage(
            this.$t("errors.max-entry"),
            maxLength(100)
          ),
        },
        lecture_date: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
        note: {
          maxLength: helpers.withMessage(
            this.$t("errors.max-entry"),
            maxLength(250)
          ),
        },
        fk_level: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
        fk_subject: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
        fk_empoylee: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
        data_entry: {},
        updated_by: {},
      },
    };
  },
  methods: {
    dicultsandslotions() {
      this.$router.push({ name: "dicultsandslotions_add" });
    },

    editesloutiondifcltiess(item) {
      this.$router.push(`${item.id}/edit/`);
    },

    async deletesloutiondifcltiess(id) {
      (this.loading = true),
        await this.axios
          .delete(`${this.base_url}api/summer/activity-difficulty/${id}`)
          .then((response) => {
            (this.loading = false),
            this.dialog=false;
              this.$emit("successAlert", this.$t("globals.delete"));

            setTimeout(() => {}, 1500);
            this.getsloutiondifcltiess();
          });
    },
    async getsloutiondifcltiess(page = 1) {
      this.loading = true;
      await this.axios(`${this.base_url}api/summer/activity-difficulty`, {
        params: {
          sort_by: this.order_data,
          search: this.txt_search,
          page: page,
          page_size: this.perPage,
        },
      }).then((response) => {
        this.lectures_attendance = response.data.results;
        this.pagination = response.data.pagination;
        this.length = response.data.pagination.num_pages;
        this.totalItems = response.data.pagination.count;
        this.loading = false;
      });
    },
  },
  watch: {
    perPage() {
      this.getsloutiondifcltiess();
    },
    page() {
      this.getsloutiondifcltiess(this.page);
    },
    order_data(){
      this.getsloutiondifcltiess(this.page);
    }
  },
};
</script>

<style scoped>
.row-hover:hover {
  background: #d4d3d3;
}
#vDataTable span {
  font-size: 11px;
}
</style>
