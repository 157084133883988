<template>

  <!-- filter data  -->
  <v-card  v-if="!exist">
    <v-card-text>

    <h3 class="text-grey-darken-2 ">{{ $t("globals.select-criteria") }}</h3>
    <v-row class="my-2 mt-6">
      <v-col cols="12" md="4" sm="6" class="mb-3">
        <v-select
          color="indigo"
          v-model="filter_data.fk_level"
          density="compact"
          item-title="level_with_phase_name"
          item-value="id"
          prepend-inner-icon="mdi-stairs-box"
          :items="levels"
          :label="$t('summer.level.name_ar')"
          hide-details
          clearable
          :error-messages="v$.filter_data.fk_level.$errors.map((e) => e.$message)"
          @update:modelValue="filter_data.fk_division = null"
        />
      </v-col>
      <v-col cols="12" md="4" sm="6" class="mb-3">
        <v-select
          color="indigo"
          v-model="filter_data.fk_division"
          density="compact"
          item-title="name"
          item-value="id"
          prepend-inner-icon="mdi-shape-outline"
          :items="class_divisions"
          :label="$t('division.name')"
          hide-details
          clearable
          :error-messages="v$.filter_data.fk_division.$errors.map((e) => e.$message)"
        />
      </v-col>

      <v-col cols="6" sm="2" class="mt-2">
        <v-btn
          density="comfortable"
          append-icon="mdi-presentation"
          class="w-100 text-white"
          color="indigo"
          :loading="loading"
          @click="checkFilterData"
        >
          <span>{{ $t("globals.show") }}</span>
        </v-btn>
      </v-col>
    </v-row>
    </v-card-text>

  </v-card>

  <v-card flat class="justify-center"  id="lectures-report" v-if="exist">
    <ReportHeader class="pb-0">
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          <div class="text-h5">
            {{ $t("report.student_by_level") }} ( {{ $t("summer.level.level") }}
            {{ getLevelName(this.filter_data.fk_level) }} - {{ $t("division.division") }}
            {{ getDivisionName(this.filter_data.fk_division) }} )
          </div>
        </div>
      </template>
    </ReportHeader>
    <v-card-text class="mt-4">
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <!-- search box  -->

        <div class="d-flex justify-end" style="flex: 1">
          <v-btn
            density="compact"
            class="pa-0 mx-1"
            style="min-width: 25px"
            @click="printTable"
          >
            <v-icon color="icon-color">mdi-printer</v-icon>
            <v-tooltip class="tooltip" activator="parent" location="top">
              <small>{{ $t("globals.print-file") }}</small>
            </v-tooltip>
          </v-btn>

          <v-btn
            density="compact"
            @click="(exist = false), v$.filter_data.$reset()"
          >
            <span class="text-indigo">{{ $t("globals.back") }}</span>
          </v-btn>
        </div>
      </div>
      <div id="myTable">
        <v-card v-if="items.length < 1" class="pa-2">
          <h3 class="text-center text-grey-darken-2">
            {{ $t("globals.not-found-items") }}
          </h3>
        </v-card>
        <v-card  :loading="loading" rounded="0" flat v-if="items.length > 0">
          <table class="report-t">
            <thead>
              <tr>
                <th style="width:50px"> {{ $t("globals.#") }}</th>
                <th >
                  {{ $t("globals.name") }}
                </th>
                <th >
                  {{ $t("driver.driver_age") }}
                </th>
                <th >
                  {{ $t("summer.activity.place") }}
                </th>
                <th >
                  {{ $t("year.name") }}
                </th>
                <th >
                  {{ $t("summer.receiving-curricula") }}
                </th>
                <th >
                  {{ $t("globals.phone") }}
                </th>
                <th >
                  {{ $t("user.date_joined") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item,index) in items" :key="item">
                <td>{{index+1}}</td>
                <td>
                  {{ item.student_name }}
                </td>
                <td class="text-center">
                  {{ item.student_age }}
                </td>
                <td>
                  {{ item.student_address }}
                </td>
                <td class="text-center">
                  {{ item.current_year }}
                </td>
                <td>
                  {{
                    item.is_recived_books == true ? $t("globals.yes") : $t("globals.no")
                  }}
                </td>
                <td>
                  {{ item.phone_number }}
                </td>
                <td class="text-center">
                  {{ item.registration_student_date }}
                </td>
              </tr>
            </tbody>
          </table>
        </v-card>
      </div>
    </v-card-text>
  </v-card>
  <Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
  >
  </Alert>
</template>

<script>
import ReportHeader from "@/components/SummerGlobals/ReportHeader.vue";
import { mapState, mapActions, mapGetters } from "vuex";
import useValidate from "@vuelidate/core";

import { required, helpers } from "@vuelidate/validators";
export default {
  components: { ReportHeader },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.$store.dispatch("updatePermission", localStorage.getItem("userinfo"));
      await this.getLevels();
      await this.getClassDivisions();
      await this.getDivisions();
      await this.getClassAssignHall();
      await this.getYears();
      this.current_year = this.getYearName(localStorage.getItem("current_year"));
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },

  data() {
    return {
      rules: {
        requird_filed: [(value) => !!value || this.$t("globals.required_field")],
      },
      theaders: [],

      current_year: "",
      fees_types: [],
      sub_list: [],
      drivers: [],
      parents: [],
      sub_list_sub: [],
      process_alert: false,
      icon_color: undefined,
      alert_title: undefined,
      alert_message: undefined,
      v$: useValidate(),
      exist: false,
      items: [],
      loading: false,
      txt_search: "",
      selectedHead: ["id", "name", "adjective", "entity", "phone_number"],
      headers: [
        { title: "#", key: "id" },
        { title: this.$t("globals.name"), key: "name" },
        { title: this.$t("globals.adjective"), key: "adjective" },
        { title: this.$t("summer.employee.place-of-work"), key: "entity" },
        { title: this.$t("globals.phone_number"), key: "phone_number" },
      ],

      perPage: 25,
      length: 0,
      totalItems: 0,
      itemsPerPage: [
        { value: 5, text: "5" },
        { value: 10, text: "10" },
        { value: 15, text: "15" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 75, text: "75" },
        { value: 100, text: "100" },
        { value: "", text: this.$t("globals.all") },
      ],
      page: 1,
      pagination: {},
      filter_data: {},
      class_divisions_hall: [],
    };
  },
  computed: {
    ...mapState({
      class_assign_hall: (state) => state.summer.class_assign_hall,
      user: (state) => state.User,
      divisions: (state) => state.summer.divisions,
      levels: (state) => state.summer.levels,
      weeks: (state) => state.summer.weeks,
    }),

    ...mapGetters({
      getClassDivisions: "summer/getClassDivisions",
    }),
    class_divisions() {
      if (this.filter_data.fk_level) {
        return this.getClassDivisions(this.filter_data.fk_level);
      }
    },
    selectedHeaders() {
      return this.headers.filter((header) => this.selectedHead.includes(header.key));
    },
  },
  methods: {
    ...mapActions({
      getLevels: "summer/getLevels",
      getDivisions: "summer/getDivisions",
      getClassAssignHall: "summer/getClassAssignHall",
    }),
    getYears() {
      this.year_data = localStorage.getItem("current_year_name_h");
      this.filter_data.year = localStorage.getItem("current_year");
      return this.year_data;
    },
    getLevelName(driver_id) {
      if (driver_id) {
        const committees = this.levels.find((committees) => committees.id === driver_id);
        return committees.level_with_phase_name;
      }
      return "===========";
    },
    getDivisionName(driver_id) {
      if (driver_id) {
        const committees = this.divisions.find(
          (committees) => committees.id === driver_id
        );
        return committees.name;
      }
      return "===========";
    },
    checkFilterData() {
      this.v$.filter_data.$validate();
      if (!this.v$.filter_data.$error) {
        this.getData();
      }
    },
    async printTable() {
      window.print();
    },
    async getData() {
      this.loading = true;
      await this.axios
        .get(`${this.base_url}api/summer/report/student-info-by-level-and-division`, {
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            level: this.filter_data.fk_level,
            division: this.filter_data.fk_division,
          },
        })
        .then((response) => {
          this.exist = true;
          if (response.data) {
            this.items = response.data;

            this.loading = false;
          }
        })
        .catch((error) => {
          this.loading=false
          this.icon = "cancel";
          this.icon_color = "error";
          if (error.message.includes("Network Error")) {
            this.alert_title = this.$t("alert.failure.title");
            this.alert_message = this.$t("alert.failure.connect");
          } else {
            this.alert_title = this.$t("alert.not-found.title");
            this.alert_message = this.$t("alert.not-found.message");
          }
          this.process_alert = true;
          setTimeout(() => {
            this.process_alert = false;
          }, 2000);
        });
    },
  },
  validations() {
    return {
      filter_data: {
        fk_level: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
        fk_division: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
      },
    };
  },
  watch: {
    perPage() {
      this.getData();
    },
    page() {
      this.getData(this.page);
    },
  },
};
</script>

