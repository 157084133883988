<template>
  <!-- filter data  -->
  <v-card class="pa-2" v-if="!exist">
    <h3 class="text-grey-darken-2 px-2">{{ $t("globals.select-criteria") }}</h3>
    <v-row class="my-2 mt-6">
   
      <v-col cols="4"   class="mb-3">
        <v-select
          multiple
          color="indigo"
          v-model="filter_data.fk_level"
          density="compact"
          item-title="level_with_phase_name"
          item-value="id"
          prepend-inner-icon="mdi-stairs-box"
          :items="levels"
          :label="$t('summer.level.name_ar')"
          hide-details
          clearable
          chips
          closable-chips
        >
          <template v-slot:chip="{props , item}">
            <v-chip 
              class="mx-1"
              style="font-size:0.9rem"
              v-bind="props" :text="item.raw.name">
            </v-chip>
          </template>
        </v-select>
      </v-col>

      <v-col cols="2"  class=" mt-2">
        <v-btn
          density="comfortable"
          append-icon="mdi-presentation"
          class="w-100 text-white"
          color="indigo"
          :loading="loading"
          @click="checkFilterData"
        >
          <span >{{ $t("globals.show") }}</span>
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
  <v-card flat class="justify-center" id="lectures-report" v-if="exist">
    <ReportHeader class="pb-0">
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          <div class="text-h5">{{ $t("report.approch-statistic") }}</div>
        </div>
      </template>
    </ReportHeader>

    <v-card-text class="mt-4">
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        
        <!-- search box  -->
        <div class="d-flex justify-end" style="flex: 1">
          <v-btn
                v-bind="props"
                density="compact"
                class="pa-0 mx-2"
                style="min-width: 25px"
                @click="printTable"
              >
                <v-icon color="icon-color">mdi-printer</v-icon>
                <v-tooltip class="tooltip" activator="parent" location="top">
                  <small>{{ $t("globals.print-file") }}</small>
                </v-tooltip>
              </v-btn>
          <v-btn
            density="compact"
            
            @click="(exist = false), v$.filter_data.$reset()"
          >
            <span class="text-indigo">{{ $t("globals.back") }}</span>
          </v-btn>
        </div>
      </div>

      <v-row
        v-if="items.length > 0"
        class="mt-4 mb-5 text-grey-darken-2 borders pt-2 pb-2 mr-1 ml-1"
      >
        <v-col cols="3">
          <h3 class="fontsize text-grey-darken-2 mb-2">
            {{ $t("school.committee_name") }} :
            {{ getCommitName(this.filter_data.commitee) }}
          </h3>
        </v-col>
      </v-row>

      <div id="myTable">
        <v-card v-if="items.length < 1" class="pa-2">
          <h3 class="text-center text-grey-darken-2">
            {{ $t("globals.not-found-items") }}
          </h3>
        </v-card>
        <v-card class="pt-2" :loading="loading" rounded="0" flat>
          <table   class="report-t" >
            <thead >
              <tr>
                <th>
                  {{ $t("summer.Info") }}
                </th>

                <th
                  v-for="header in items.levels"
                  :colspan="header.count"
                  :key="header.key"
                  
                >
                  <span v-if="$i18n.locale === 'ar'">
                    {{ header.level }}
                  </span>

                  <span v-if="$i18n.locale === 'en'">
                    {{ header.level_en }}
                  </span>
                </th>
              </tr>
             
            </thead>
            <tbody>
              <tr>
                <td v-if="$i18n.locale === 'ar'">العدد الذي تم استلامة</td>
                <td v-if="$i18n.locale === 'en'">Approach</td>
                <td
                  v-for="item in items.approach"
                  :key="item"
                  class="text-center"
                  
                >
                  {{ item.number }}
                </td>
              </tr>

              <tr>
                <td v-if="$i18n.locale === 'ar'">المنصرف للطلاب</td>
                <td v-if="$i18n.locale === 'en'">Students Approach</td>

                <td
                  v-for="approach in items.students_approach"
                  :key="approach"
                  class="text-center"

                  
                >
                  {{ approach.number }}
                </td>
              </tr>

              <tr>
                <td v-if="$i18n.locale === 'ar'">المنصرف للموظفين</td>
                <td v-if="$i18n.locale === 'en'">Employee Approach</td>

                <td
                  v-for="approach in items.employee_approach"
                  :key="approach"
                  class="text-center"
                  
                >
                  {{ approach.number }}
                </td>
              </tr>

              <tr>
                <td v-if="$i18n.locale === 'ar'">العجز</td>
                <td v-if="$i18n.locale === 'en'">Shortage</td>

                <td
                  v-for="approach in items.shortage"
                  :key="approach"
                  class="text-center"
                  
                >
                  {{ approach.number }}
                </td>
              </tr>

              <tr>
                <td v-if="$i18n.locale === 'ar'">الزيادة</td>
                <td v-if="$i18n.locale === 'en'">Overload</td>

                <td
                  v-for="approach in items.overload"
                  :key="approach"
                  class="text-center"
                  
                >
                  {{ approach.number }}
                </td>
              </tr>
            </tbody>
          </table>
        </v-card>
      </div>
    </v-card-text>
  </v-card>
  <Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
  >
  </Alert>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import ReportHeader from "@/components/SummerGlobals/ReportHeader.vue";
import useValidate from "@vuelidate/core";
export default {
  components: { ReportHeader },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.$store.dispatch("updatePermission", localStorage.getItem("userinfo"));

      await this.getCommittee();
      await this.getLevels();
      await this.getweeks();
      await this.getYears();

      this.current_year = this.getYearName(localStorage.getItem("current_year"));
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },

  data() {
    return {
      rules: {
        requird_filed: [(value) => !!value || this.$t("globals.required_field")],
      },
      theaders: [],

      school: [],
      current_year: "",
      fees_types: [],
      sub_list: [],
      drivers: [],
      parents: [],
      sub_list_sub: [],
      process_alert: false,
      icon_color: undefined,
      alert_title: undefined,
      alert_message: undefined,
      v$: useValidate(),
      exist: false,
      items: [],
      loading: false,
      txt_search: "",
      selectedHead: ["id", "name", "adjective", "entity", "phone_number"],
      headers: [
        { title: "#", key: "id" },
        { title: this.$t("globals.name"), key: "name" },
        { title: this.$t("globals.adjective"), key: "adjective" },
        { title: this.$t("summer.employee.place-of-work"), key: "entity" },
        { title: this.$t("globals.phone_number"), key: "phone_number" },
      ],

      perPage: 25,
      length: 0,
      totalItems: 0,
      itemsPerPage: [
        { value: 5, text: "5" },
        { value: 10, text: "10" },
        { value: 15, text: "15" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 75, text: "75" },
        { value: 100, text: "100" },
        { value: "", text: this.$t("globals.all") },
      ],
      page: 1,
      pagination: {},
      filter_data: {},
    };
  },
  computed: {
    ...mapState({
      classes: (state) => state.school.classes,
      class_assign_hall: (state) => state.school.class_assign_hall,
      semesters: (state) => state.school.semesters,
      subjects: (state) => state.school.subjects,
      user: (state) => state.User,
      committee: (state) => state.summer.committee,
      levels: (state) => state.summer.levels,
      weeks: (state) => state.summer.weeks,
    }),

    ...mapGetters({}),

    selectedHeaders() {
      return this.headers.filter((header) => this.selectedHead.includes(header.key));
    },
  },
  methods: {
    ...mapActions({
      getCommittee: "summer/getCommittee",
      getLevels: "summer/getLevels",
      getweeks: "summer/getweeks",
    }),
    getYears() {
      this.year_data = localStorage.getItem("current_year_name_h");
      this.filter_data.year = localStorage.getItem("current_year");
      return this.year_data;
    },

    getCommitName(driver_id) {
      if (driver_id) {
        const committees = this.committee.find(
          (committees) => committees.id === committees.id
        );
        return committees.name;
      }
      return "===========";
    },
    checkFilterData() {
      this.v$.filter_data.$validate();
      if (!this.v$.filter_data.$error) {
        this.getData();
      }
    },
    async printTable() {
      window.print();
    },
    async getData() {
      this.loading = true;

      await this.axios
        .get(`${this.base_url}api/summer/report/approach-statistics`, {
          headers: {
            "Content-Type": "application/json",
          },

          params: {
            fk_level: this.filter_data.fk_level,
          },
        })
        .then((response) => {
          this.exist = true;
          if (response.data) {
            this.items = response.data;

            this.loading = false;
          }
        })
        .catch((error) => {
          this.icon = "cancel";
          this.icon_color = "error";
          if (error.message.includes("Network Error")) {
            this.alert_title = this.$t("alert.failure.title");
            this.alert_message = this.$t("alert.failure.connect");
          } else {
            this.alert_title = this.$t("alert.not-found.title");
            this.alert_message = this.$t("alert.not-found.message");
          }
          this.process_alert = true;
          setTimeout(() => {
            this.process_alert = false;
          }, 2000);
        });
    },
  },
  validations() {
    return {
      filter_data: {
        commitee: {
          // required: helpers.withMessage(this.$t("errors.required"), required),
        },
      },
    };
  },
  watch: {
    perPage() {
      this.getData();
    },
    page() {
      this.getData(this.page);
    },
  },
};
</script>

<style scoped>

</style>
